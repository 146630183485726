/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Dropdown, Layout, Menu } from 'antd';
import {
  MenuOutlined,
  DownOutlined,
  PoweroffOutlined,
  UserOutlined,
  BellOutlined,
} from '@ant-design/icons';

import './styles.scss';
import { useHistory } from 'react-router-dom';
import notificationLogo from '../../assets/notification.svg';
import avatar from '../../assets/man.png';
import { useAuth } from '../../hooks/auth';
import { useApi } from '../../hooks/api';
import INotificationUser from '../../interfaces/notification';

const { Header } = Layout;

interface IProps {
  collapse(): void;
}

const AppHeader: React.FC<IProps> = ({ collapse }) => {
  const { signOut, user } = useAuth();
  const [notifications, setNotifications] = useState<INotificationUser[]>([]);
  const [newsNotifications, setNewsNotifications] = useState(0);
  const { api } = useApi();
  const history = useHistory();

  const navigate = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const menu = (
    <Menu>
      <Menu.Item
        icon={<UserOutlined />}
        onClick={() => {
          navigate('/profile');
        }}
      >
        Perfil
      </Menu.Item>
    </Menu>
  );

  const notificationMenu = (
    <Menu>
      {notifications.map(notification => (
        <Menu.Item key={notification.id}>
          <div className="notification-container">
            <img src={notificationLogo} alt="Notification" />
            <div className="notification-container-info">
              <strong>{notification.notification.title}</strong>
              <p>{notification.notification.description}</p>
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const logout = useCallback(() => {
    signOut();
    navigate('/');
  }, [signOut, navigate]);

  const loadNotifications = useCallback(async () => {
    const response = await api.get('notifications/me');
    setNotifications(response.data);
    setNewsNotifications(response.data.length);
  }, [api]);

  const readNotifications = useCallback(
    async (visible: boolean) => {
      if (visible && newsNotifications > 0) {
        const notificationsRead = notifications.map(notification => ({
          notification_user_id: notification.id,
        }));

        const data = {
          notifications: notificationsRead,
        };
        await api.patch(`notifications/read`, data);
        setNewsNotifications(0);
      }
    },
    [api, notifications, newsNotifications],
  );

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return (
    <Header className="header-wd">
      <MenuOutlined className="menu-collapse-button" onClick={collapse} />
      <div className="header-actions">
        <Dropdown
          trigger={['click']}
          onOpenChange={readNotifications}
          className="notifications-item"
          overlay={notificationMenu}
          placement="bottomRight"
          arrow
        >
          <Badge count={newsNotifications}>
            <BellOutlined />
          </Badge>
        </Dropdown>
        <Dropdown trigger={['click']} overlay={menu}>
          <div className="user-action">
            <img src={avatar} alt="Avatar" />
            <span>{user.name}</span>
            <DownOutlined />
          </div>
        </Dropdown>
        <PoweroffOutlined onClick={logout} />
      </div>
    </Header>
  );
};

export default AppHeader;
