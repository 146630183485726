import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'antd';
import { v4 } from 'uuid';
import { FaFilter, FaPlus, FaSyncAlt, FaTrash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { IActionHeaderConfig, ITableColumn } from '../../interfaces/table';
import { IReportScheduleList } from '../../interfaces/reportsSchedule';
import Table from './Table';
import { useApi } from '../../hooks/api';
import { IPageable, DEFAULT_PAGEABLE } from '../../interfaces/pageable';
import ReportScheduleFilters from '../../components/ReportScheduleFilters';
import IClientsList from '../../interfaces/clients';
import { notificationDCS } from '../../utils/notificationDcs';

const ReportSchedule: React.FC = () => {
  const { push } = useHistory();
  const { api } = useApi();
  const [loading, setLoading] = useState(false);
  const [reportsSchedules, setRepostsSchedules] = useState<
    IReportScheduleList[]
  >([]);
  const [clients, setClients] = useState<IClientsList[]>([]);
  const [filters, setFilters] = useState({
    client_id: '',
    media_name: '',
  });
  const [showFilters, setShowFilters] = useState(false);
  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);

  const loadReports = useCallback(
    async (
      pageNumber = 1,
      client_id = filters.client_id,
      media_name = filters.media_name,
    ) => {
      setLoading(true);
      try {
        const response = await api.get('/reports/schedules/paginated', {
          params: { page: pageNumber - 1, client_id, media_name },
        });

        const schedules = response.data.data.map((s: any) => ({
          ...s,
          contacts: s.contacts.split(','),
        }));

        setRepostsSchedules(schedules);
        setPageable(response.data); // response.data ~= IPageable

        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [api, filters.client_id, filters.media_name],
  );

  const loadClients = useCallback(async () => {
    const response = await api.get('/clients');
    setClients(response.data);
  }, [api]);

  const deleteSchedule = async (id: string) => {
    try {
      await api.delete(`/reports/schedules/${id}`);
      setRepostsSchedules(
        reportsSchedules.filter(schedule => schedule.id !== id),
      );
      notificationDCS({
        type: 'success',
        message: 'Sucesso',
        description: 'Deletado com sucesso!',
        secondsDuration: 2,
      });
    } catch (error) {
      notificationDCS({
        type: 'error',
        message: 'Erro ao deletar',
        description: 'Ocorreu um erro ao deletar, tente novamente mais tarde!',
        secondsDuration: 2,
      });
    }
  };

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Nome',
      dataIndex: 'name',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Data',
      dataIndex: 'date',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Contatos',
      dataIndex: 'contacts',
      responsive: false,
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
    {
      titleAction: 'Novo Agendamento',
      icon: <FaPlus />,
      action: () => {
        push('/reports-config/schedules/create');
      },
    },
  ];

  useEffect(() => {
    loadReports();
    loadClients();
  }, [loadReports, loadClients]);

  const onSearch = useCallback(
    (client_id: string, media_name: string) => {
      setFilters({
        client_id,
        media_name,
      });

      loadReports(
        1,
        client_id,
        media_name,
        // true,
      );
      setShowFilters(false);
    },
    [loadReports],
  );

  return (
    <Card>
      <Table
        title="Agendamento de Relatórios"
        actionsConfig={headerActions}
        data={reportsSchedules}
        keyIndex="id"
        tableColumns={tableColumns}
        tableActions={[
          {
            name: 'Editar',
            icon: <EditOutlined />,
            action: (data: IReportScheduleList) => {
              push(`/reports-config/schedules/create/${data.id}`);
            },
            disabled: () => {
              return false;
            },
          },
          {
            name: 'Excluir',
            icon: <FaTrash />,
            action: (data: IReportScheduleList) => {
              deleteSchedule(`${data.id}`);
            },
            disabled: () => {
              return false;
            },
          },
        ]}
        isPaginated
        tablePagination={{
          current: pageable.page + 1,
          defaultPageSize: pageable.elementsPerPage,
          totalItems: pageable.totalElements,
        }}
        onChangePage={loadReports}
        loading={loading}
      />
      <ReportScheduleFilters
        clients={clients}
        closeFilters={() => setShowFilters(false)}
        showFilters={showFilters}
        search={onSearch}
      />
    </Card>
  );
};

export default ReportSchedule;
