/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Select } from 'antd';
import InputMask from 'react-input-mask';
import { useForm } from 'antd/lib/form/Form';

interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  search(
    name: string,
    email: string,
    status: string,
    phone: string,
    client_type: string,
    partner_id: string,
    token: string,
  ): void;
}

const { Option } = Select;

const ClientsFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search } = props;
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    status: '',
    phone: '',
    client_type: '',
    partner_id: '',
    token: '',
  });

  const status = [
    { value: 'ENABLED', name: 'ATIVO' },
    { value: 'DISABLED', name: 'INATIVO' },
    { value: '', name: 'Todos' },
  ];
  const clientType = [
    { value: 'DIRECT', name: 'Direto' },
    { value: 'PARTNER', name: 'Parceiro' },
    { value: 'INDIRECT', name: 'Indireto' },
  ];

  const [form] = useForm();

  const onSearch = useCallback(() => {
    const { name, email, status, phone, client_type, partner_id, token } =
      form.getFieldsValue();

    search(
      name === undefined ? '' : name,
      email === undefined ? '' : email,
      status === undefined ? '' : status,
      phone === undefined ? '' : phone,
      client_type === undefined ? '' : client_type,
      partner_id === undefined ? '' : partner_id,
      token === undefined ? '' : token,
    );
  }, [search, form]);

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      open={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Col span={24}>
          <Form.Item label="Nome:" name="name">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Nome"
              value={filters.name}
              onChange={e => setFilters({ ...filters, name: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="E-mail:" name="email">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: email@exemplo.com"
              value={filters.email}
              onChange={e => setFilters({ ...filters, email: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Tipo de Cliente:" name="client_type">
            <Select
              allowClear
              showSearch
              placeholder="Tipo de Cliente"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {clientType.map(client => {
                return (
                  <Option key={client.value} value={client.value}>
                    {client.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Status:" name="status">
            <Select
              allowClear
              showSearch
              placeholder="Status"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {status.map(status => {
                return (
                  <Option key={status.value} value={status.value}>
                    {status.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Telefone:" name="phone">
            <InputMask
              mask="(99) 99999-9999"
              maskChar="_"
              placeholder="ex.: (99) 99999-9999"
              value={filters.phone}
              // eslint-disable-next-line
              onChange={(e) => setFilters({ ...filters, phone: e.target.value })}
            >
              {
                // @ts-ignore
                (inputProps: React.InputHTMLAttributes<HTMLInputElement>) => (
                  // @ts-ignore
                  <Input
                    className="dcs-input"
                    allowClear
                    type="text"
                    // eslint-disable-next-line
                    {...inputProps}
                  />
                )
              }
            </InputMask>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Token:" name="token">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: token"
              value={filters.token}
              onChange={e => setFilters({ ...filters, token: e.target.value })}
            />
          </Form.Item>
        </Col>

        <Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export { ClientsFilters };
