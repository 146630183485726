import React from 'react';
import { Card, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IContact } from '../../interfaces/contact';

import './styles.scss';

import ContactCardItem from '../ContactCardItem';

interface IProps {
  contacts: IContact[];
  removeContactList: (contact: IContact) => void;
}

const ContactReportScheduleList: React.FC<IProps> = props => {
  const { contacts, removeContactList } = props;
  return (
    <Card className="contact-report-schedule-list-card">
      <div className="card-list-header">
        <span>
          <span className="title">Lista de contatos</span>
          &nbsp;
          <Tooltip title="Os e-mails desta lista receberam o relatório gerado deste agendamento.">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
        <span className="contact-length">{contacts.length}</span>
      </div>
      <div className="card-list-body">
        <ul>
          {contacts.map(contact => (
            <ContactCardItem
              key={contact.id !== '' ? contact.id : contact.email}
              contact={contact}
              removeContact={removeContactList}
            />
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default ContactReportScheduleList;
