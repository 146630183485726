/* eslint-disable no-param-reassign */
import React, { createContext, useContext, useMemo, useState } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { notificationDCS } from '../utils/notificationDcs';
import { useApplication } from './application';
import { translateDescriptionNotification } from '../helpers/notificationAlertStatus';

interface IApiContextState {
  api: AxiosInstance;
}

interface ApiProviderProps {
  children: React.ReactNode;
}

const ApiContext = createContext<IApiContextState>({} as IApiContextState);

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const { logout } = useApplication();
  const [api] = useState<AxiosInstance>(() => {
    const baseURL = process.env.REACT_APP_API_URL;

    const rest = axios.create({
      baseURL,
    });

    rest.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.headers.Authorization === undefined) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          '@DCSAdmin:token',
        )}`;
      }
      return config;
    });

    rest.interceptors.response.use(
      response => response,
      error => {
        if (error.response !== undefined) {
          switch (error.response.status) {
            case 401:
              notificationDCS({
                type: 'warning',
                message: 'Alerta',
                description: 'Credenciais inválidas!',
                secondsDuration: 2,
              });
              break;

            case 403:
              // mandar para login
              notificationDCS({
                type: 'warning',
                message: 'Alerta',
                description: error.response.data.message,
                secondsDuration: 2,
              });
              logout();
              break;

            case 400:
              notificationDCS({
                type: 'warning',
                message: 'Alerta',
                description: translateDescriptionNotification(
                  error.response.data.message,
                ),
                secondsDuration: 3,
              });

              break;

            case 404:
              notificationDCS({
                type: 'warning',
                message: 'Alerta',
                description:
                  error.response.data.validation?.body?.keys[0] === 'email'
                    ? 'Formato de e-mail inválido!'
                    : error.response.data.message,
                secondsDuration: 2,
              });
              break;

            default:
              break;
          }
        } else {
          notificationDCS({
            type: 'error',
            message: 'Erro',
            description: 'Falha no servidor!',
            secondsDuration: 2,
          });
        }
        return Promise.reject(error);
      },
    );

    return rest;
  });

  const apiContextValue = useMemo(() => ({ api }), [api]);

  return (
    <ApiContext.Provider value={apiContextValue}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = (): IApiContextState => {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }

  return context;
};
