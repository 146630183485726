import React, { useCallback, useState } from 'react';
import { Button, Card, Form, Input } from 'antd';

import './styles.scss';
import { useForm } from 'antd/lib/form/Form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { notificationDCS } from '../../utils/notificationDcs';
import { useApi } from '../../hooks/api';

interface IParams {
  token: string;
}

const ForgotPassword: React.FC = () => {
  const [form] = useForm();
  const { api } = useApi();
  const history = useHistory();
  const { token } = useParams<IParams>();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const data = {
        token,
        password: form.getFieldValue('password'),
        confirm_password: form.getFieldValue('confirm_password'),
      };

      await api.post('/password/forgot', data);
      notificationDCS({
        type: 'success',
        message: 'Sucesso',
        description: 'Senha alterada com sucesso.',
        secondsDuration: 2,
        onClose: () => {
          history.push('/forgot-password');
        },
      });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [form, history, token, api]);

  return (
    <div className="forgot-password-page">
      <Card>
        <h1>Alterar Senha</h1>
        <Form
          layout="vertical"
          className="form-forgot-password"
          form={form}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Senha:"
            name="password"
            rules={[{ required: true, message: 'Preencha sua senha!' }]}
          >
            <Input.Password className="dcs-input dcs-password" size="large" />
          </Form.Item>
          <Form.Item
            label="Confirmar Senha:"
            name="confirm_password"
            rules={[
              { required: true, message: 'Preencha a confirmação de senha!' },
            ]}
          >
            <Input.Password className="dcs-input dcs-password" size="large" />
          </Form.Item>
          <Form.Item>
            <Link to="/forgot-password">Voltar para login</Link>
          </Form.Item>
          <Form.Item className="forgot-password-actions">
            <Button
              type="primary"
              size="large"
              shape="round"
              htmlType="submit"
              loading={loading}
            >
              Alterar Senha
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
