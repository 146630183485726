import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { Button, Card, Space, Tabs } from 'antd';
import { FaReply } from 'react-icons/fa';
import { FileSyncOutlined, HistoryOutlined } from '@ant-design/icons';
import IHsmHistory, { IHsmDetail } from '../../../interfaces/hsm';
import { useApi } from '../../../hooks/api';

import './styles.scss';
import TagDcs from '../../../components/TagDcs';
import HsmHistoryDetail from '../../../components/HsmHistoryDetail';
import HsmDetailInfo from '../../../components/HsmDetailInfo';

interface IParams {
  id: string;
}
const { TabPane } = Tabs;

const HsmDetail: React.FC = () => {
  const { goBack } = useHistory();
  const { id } = useParams<IParams>();
  const { api } = useApi();
  const [hsm, setHsm] = useState<IHsmDetail>();
  const [hsmHistory, setHsmHistory] = useState<IHsmHistory[]>([]);
  const [currentTab, setCurrentTab] = useState('1');

  const [loading, setLoading] = useState<boolean>();

  const loadHsm = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(`hsm/${id}/show`);
      setHsm(response.data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [api, id]);

  const formatDate = useCallback((date: Date | undefined) => {
    const dateIso = parseISO(
      format(new Date(date || Date.now()), 'yyyy-MM-dd HH:mm:ss'),
    );
    return format(dateIso, 'dd/MM/yyyy HH:mm:ss');
  }, []);

  const loadHsmHistory = useCallback(async () => {
    try {
      const response = await api.get(`hsm-histories/${id}`);
      setHsmHistory(response.data);
    } catch {
      setLoading(false);
    }
  }, [api, id]);

  const renderStatus = useCallback(() => {
    switch (hsm?.status) {
      case 'PENDING':
        return <TagDcs title="Pendente" type="pending" />;
      case 'IN_ANALYSIS':
        return <TagDcs title="Em Análise" type="primary" />;
      case 'APPROVED':
        return <TagDcs title="Aprovado" type="success" />;
      case 'REJECTED':
        return <TagDcs title="Rejeitado" type="danger" />;
      case 'DISABLED':
        return <TagDcs title="Desabilitado" type="default" />;
      default:
        return <TagDcs title={hsm?.status || ''} type="default" />;
    }
  }, [hsm]);

  useEffect(() => {
    loadHsm();
    loadHsmHistory();
  }, [loadHsm, loadHsmHistory]);

  return (
    <Card className="card-hsm-detail">
      <div className="page-header">
        <h2>HSM</h2>
        <Space>
          {renderStatus()}
          <Button
            type="primary"
            shape="round"
            icon={<FaReply />}
            onClick={goBack}
          >
            Voltar
          </Button>
        </Space>
      </div>
      <Tabs
        defaultActiveKey={currentTab}
        activeKey={currentTab}
        onChange={key => setCurrentTab(key)}
      >
        <TabPane
          tab={
            <span>
              <FileSyncOutlined />
              Dados
            </span>
          }
          key="1"
        >
          <HsmDetailInfo hsm={hsm} loading={loading} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <HistoryOutlined />
              Histórico
            </span>
          }
          key="2"
        >
          <HsmHistoryDetail hsmHistory={hsmHistory} formatDate={formatDate} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default HsmDetail;
