import IInternationalization from '../interfaces/internacionalization';
import wordPt from './locales/pt_BR';

interface IInternacionalizationProps {
  pt: IInternationalization;
}

const words: IInternacionalizationProps = {
  pt: wordPt,
};

export default words;
