/* eslint-disable react/jsx-curly-newline */
import { QuestionCircleOutlined } from '@ant-design/icons';
/* eslint-disable react/jsx-wrap-multilines */
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getDaysInMonth } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FaPlus, FaReply, FaSave, FaTimes } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import ContactReportScheduleList from '../../../components/ContactReportScheduleList';
import { useApi } from '../../../hooks/api';
import IClientsList from '../../../interfaces/clients';
import { IIntegrationBilling } from '../../../interfaces/integrationBilling';
import IIntegrationClient from '../../../interfaces/integrationsClient';
import { notificationDCS } from '../../../utils/notificationDcs';

import './styles.scss';

const { Option } = Select;

interface IParams {
  id: string;
}

interface IContact {
  id: string;
  name: string;
  email: string;
}

interface IClienteSelected {
  id: string;
  name: string;
  client_type: string;
}

const ReportScheduleForm: React.FC = () => {
  const { api } = useApi();
  const { id } = useParams<IParams>();
  const { goBack } = useHistory();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [days] = useState(Array.from({ length: 31 }, (_, index) => index + 1));
  const [hours] = useState(Array.from({ length: 24 }, (_, index) => index));
  const [reportType, setReportType] = useState(
    id === undefined ? 'PERIOD' : '',
  );
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [clients, setClients] = useState<IClientsList[]>([]);
  const [integrations, setIntegrations] = useState<IIntegrationClient[]>([]);
  const [, setBillings] = useState<IIntegrationBilling[]>([]);
  const [contact, setContact] = useState<IContact>({
    name: '',
    email: '',
    id: '',
  });
  const [emailError, setEmailError] = useState('');
  const [media, setMedia] = useState('');
  const [clientSelected, setClientSelected] = useState<IClienteSelected>();

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();
      if (id === undefined) {
        data.contacts = contacts.map(contact => {
          return { email: contact.email, name: contact.name };
        });

        await api.post('/reports/schedules', data);
        setLoading(false);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Agendamento cadastrado com sucesso!',
        });
      } else {
        data.contacts = contacts;
        await api.put(`/reports/schedules/${id}`, data);
        setLoading(false);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Agendamento atualizado com sucesso!',
        });
      }

      goBack();
    } catch {
      setLoading(false);
    }
  }, [form, api, goBack, contacts, id]);

  const formatNumber = useCallback((number: number) => {
    return String(number).padStart(2, '0');
  }, []);

  const formatHour = useCallback((number: number) => {
    const hour = String(number).padStart(2, '0');
    return `${hour}:00`;
  }, []);

  const loadClients = useCallback(
    async (media: string) => {
      const response = await api.get(`clients/${media}/filter`);
      setClients(response.data);
    },
    [api, setClients],
  );

  const handleChangeClients = useCallback(
    (value: string) => {
      const client = clients.find(client => client.id === value);
      setClientSelected(client);
    },
    [clients],
  );

  const loadReportSchedule = useCallback(async () => {
    const response = await api.get(`/reports/schedules/${id}/show`);
    setContacts(response.data.contacts);
    setReportType(response.data.type);
    setMedia(response.data.media);
    setClientSelected(response.data.client);
    delete response.data.contacts;

    form.setFieldsValue({
      ...response.data,
      integrations,
    });
  }, [api, id, form, loadClients]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setContact({ ...contact, email: e.target.value });
  };

  const isEmailValid = (email: string) => {
    if (email.includes(' ')) {
      return false;
    }
    return /\S+@\S+\.\S+/.test(email);
  };

  const addNewContactToList = useCallback(() => {
    if (isEmailValid(contact.email)) {
      setContacts([...contacts, contact]);
      setContact({ name: '', email: '', id: '' });
    } else {
      setEmailError('E-mail inválido');
    }
  }, [contact]);

  const removeContactToList = useCallback((contactToRemove: IContact) => {
    setContacts(contacts =>
      contacts.filter(contact => contact.id !== contactToRemove.id),
    );
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      loadReportSchedule();
    }
  }, [id, loadReportSchedule, form]);

  useEffect(() => {
    if (media) {
      loadClients(media);
    }
  }, [media]);

  const handleMediaChange = (value: string) => {
    setMedia(value);
  };

  return (
    <Card className="card-report-schedule-form">
      <div className="page-header">
        {id === undefined ? (
          <h2>Novo Agendamento de Relatório</h2>
        ) : (
          <h2>Editar Agendamento de Relatório</h2>
        )}

        <Button
          type="primary"
          shape="round"
          icon={<FaReply />}
          onClick={goBack}
        >
          Voltar
        </Button>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        initialValues={{ type: reportType }}
      >
        <Row>
          <Col
            xl={12}
            md={10}
            sm={22}
            xs={22}
            style={{ position: 'relative' }}
            // style={{ display: 'flex', flex: 1 }}
          >
            <Form.Item
              label={
                <span>
                  Nome:&nbsp;
                  <Tooltip title="Nome de identificador do Agendamento.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="name"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input
                className="dcs-input"
                placeholder="Agendamento Mensal"
                maxLength={150}
                showCount
              />
            </Form.Item>
          </Col>
          <Col xl={6} md={12} sm={24} xs={24}>
            <Form.Item
              label={
                <span>
                  Horário:&nbsp;
                  <Tooltip title="Horário do dia escolhido em que o relatório será gerado.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="hour"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                placeholder="Selecione um Horário"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
              >
                {hours.map(hour => (
                  <Option key={hour} value={hour}>
                    {formatHour(hour)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} md={12} sm={24} xs={24}>
            <Form.Item
              label={
                <span>
                  Dia do Mês:&nbsp;
                  <Tooltip title="Dia do mês em que o relatório será gerado.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="day"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                placeholder="Selecione um Dia"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
              >
                {days.map(day => (
                  <Option key={day} value={day}>
                    {formatNumber(day)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={6} md={16} sm={24} xs={24}>
            <Form.Item
              label="Canal:"
              name="media"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Canal"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
                onChange={handleMediaChange}
                // onChange={value => loadBrokers(String(value))}
                disabled={id !== undefined}
              >
                <Option value="whatsapp">WhatsApp</Option>
                <Option value="sms">SMS</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={6} md={16} sm={24} xs={24}>
            <Form.Item
              label={
                <span>
                  Cliente:&nbsp;
                  <Tooltip title="Deixando este campo vazio, será gerado o relatório para todos os clientes.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="client_id"
              rules={[
                {
                  required: reportType === 'BILLING',
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Selecione um Cliente"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
                showSearch
                onChange={e => handleChangeClients(e)}
                // onChange={value => loadIntegrations(String(value))}
              >
                {clients.map(client => (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xxl={3} xl={6} lg={6} md={6} xs={24} sm={24}>
            <Form.Item
              style={{ minWidth: '153px' }}
              label={
                <span>
                  Dia inicial:&nbsp;
                  <Tooltip title="Dia inicial em que o filtro de datas será aplicado no relatório.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="start_day_filter"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                placeholder="Selecione o Dia inicial"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
              >
                {days.map(day => (
                  <Option key={day} value={day}>
                    {formatNumber(day)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xxl={3} xl={6} lg={6} md={6} xs={24} sm={24}>
            <Form.Item
              style={{ minWidth: '153px' }}
              label={
                <span>
                  Dia final:&nbsp;
                  <Tooltip title="Dia final em que o filtro de datas será aplicado no relatório.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="end_day_filter"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                placeholder="Selecione o Dia final"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
              >
                {days.map(day => (
                  <Option key={day} value={day}>
                    {formatNumber(day)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {clientSelected?.client_type === 'PARTNER' && (
            <Alert
              message="O cliente escolhido é do tipo parceiro e poderá ter clientes associados a ele. Para ter acesso ao consumo dos clientes associados, gere um relatório selecionando os mesmos"
              // description="This is a warning notice about copywriting."
              type="warning"
              showIcon
              closable
            />
          )}
        </Row>
        <Divider orientation="left">Contatos</Divider>
        <Row>
          <Col xl={12} xs={24}>
            <Row className="contact-form">
              <Col xl={12} xs={24}>
                <Form.Item label="Nome">
                  <Input
                    className="dcs-input"
                    placeholder="ex.: Nome do contato"
                    onChange={e =>
                      setContact({
                        email: contact.email,
                        id: '',
                        name: e.target.value,
                      })
                    }
                    maxLength={150}
                    showCount
                    value={contact.name}
                  />
                </Form.Item>
              </Col>
              <Col xl={12} xs={24}>
                <Form.Item label="E-mail">
                  <Input
                    className="dcs-input"
                    placeholder="ex.: contato@email.com"
                    type="email"
                    onChange={handleEmailChange}
                    value={contact.email}
                  />
                  {emailError && (
                    <div className="email-error">{emailError}</div>
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="dashed"
                    icon={<FaPlus />}
                    onClick={addNewContactToList}
                    disabled={contact.name === '' || contact.email === ''}
                  >
                    Adicionar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xl={12} xs={24}>
            <ContactReportScheduleList
              contacts={contacts}
              removeContactList={removeContactToList}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xl={24}>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  shape="round"
                  icon={<FaSave />}
                  htmlType="submit"
                  loading={loading}
                >
                  Salvar
                </Button>
                <Button
                  shape="round"
                  icon={<FaTimes />}
                  htmlType="button"
                  onClick={goBack}
                >
                  Cancelar
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ReportScheduleForm;
