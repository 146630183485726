import { Card, Table, Tag } from 'antd';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import IClientsList from '../../interfaces/clients';
import { useAuth } from '../../hooks/auth';

import './styles.scss';

interface IProps {
  clients: IClientsList[];
}

const ClientDetailIndirectsItem: React.FC<IProps> = props => {
  const { clients } = props;
  const { user } = useAuth();
  const isNormalUser = user.user_profile === 'USER';

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      align: 'center',
      render: (data: IClientsList) => (
        <Link
          style={{
            display: 'inline-block',
            alignItems: 'center',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}
          to={`/${isNormalUser ? 'accounts' : 'clients'}/detail/${data.id}`}
        >
          {data.name}
        </Link>
      ),
    },
    {
      title: 'Status',
      align: 'center',
      render: (data: IClientsList) => {
        return (
          <Tag color={data.enabled ? 'success' : 'error'} style={{ margin: 0 }}>
            {data.enabled ? 'ATIVO' : 'INATIVO'}
          </Tag>
        );
      },
    },
  ];

  return (
    <Card>
      <Table
        dataSource={clients}
        className="associated-indirect"
        columns={columns}
        pagination={false}
        bordered
        style={{ width: '100%' }}
      />
    </Card>
  );
};

export default memo(ClientDetailIndirectsItem);
