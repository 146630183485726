import React, { useCallback, useEffect, useState } from 'react';
import { Card, Tag, Modal } from 'antd';
import { AlignLeftOutlined } from '@ant-design/icons';
import { FaSyncAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { format, parseISO } from 'date-fns';
import Table from '../../components/Table';
import { HsmStatus } from '../../enums/enums';
import { useApi } from '../../hooks/api';
import { IHsmLog } from '../../interfaces/hsm';
import { IPageable, DEFAULT_PAGEABLE } from '../../interfaces/pageable';
import { IActionHeaderConfig, ITableColumn } from '../../interfaces/table';

const HsmLogInteraction: React.FC = () => {
  const { api } = useApi();
  const [showBrokerDescription, setShowBrokerDescription] = useState(false);
  const [hsmLogInteraction, setHsmLogInteraction] = useState<IHsmLog[]>([]);
  const [hsmLogSelected, setHsmLogSelected] = useState<IHsmLog>();
  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);
  const [loading, setLoading] = useState(false);

  const loadHsmLogs = useCallback(
    async (pageNumber = 1) => {
      try {
        setLoading(true);
        const response = await api.get('/hsm-logs', {
          params: { page: pageNumber - 1 },
        });
        setHsmLogInteraction(response.data.data);
        setPageable(response.data); // response.data ~= IPageable
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [api],
  );

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Hsm',
      dataIndex: 'hsm',
      responsive: true,
      renderColumn: ({ hsm }: IHsmLog): any => {
        return <Link to={`/hsm/detail/${hsm.id}`}>{hsm.name}</Link>;
      },
    },
    {
      id: v4(),
      name: 'Usuário',
      dataIndex: 'user',
      responsive: false,
      renderColumn: ({ user }: IHsmLog) => {
        return <p>{user.name}</p>;
      },
    },
    {
      id: v4(),
      name: 'Status',
      dataIndex: 'status',
      responsive: false,
      renderColumn: (data: IHsmLog) => {
        const status = data.status as HsmStatus;

        switch (status) {
          case HsmStatus.PENDING:
            return <Tag color="cyan">Pendente</Tag>;
          case HsmStatus.IN_ANALYSIS:
            return <Tag color="processing">Em Análise</Tag>;
          case HsmStatus.APPROVED:
            return <Tag color="success">Aprovado</Tag>;
          case HsmStatus.REJECTED:
            return <Tag color="error">Rejeitado</Tag>;
          default:
            return <Tag color="default">{data.status}</Tag>;
        }
      },
    },
    {
      id: v4(),
      name: 'Data da resposta',
      dataIndex: 'response_date',
      responsive: false,
      renderColumn: ({ response_date }: IHsmLog) => {
        const dateIso = parseISO(
          format(new Date(response_date), 'yyyy-MM-dd HH:mm'),
        );
        return format(dateIso, 'dd/MM/yyyy HH:mm');
      },
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        loadHsmLogs();
      },
    },
  ];

  useEffect(() => {
    loadHsmLogs();
  }, [loadHsmLogs]);

  return (
    <Card>
      <Table
        title="Logs dos HSMs"
        actionsConfig={headerActions}
        data={hsmLogInteraction}
        keyIndex="id"
        tableColumns={tableColumns}
        tableActions={[
          {
            name: 'Resposta da solicitação',
            icon: <AlignLeftOutlined />,
            action: (data: IHsmLog) => {
              setHsmLogSelected(data);
              setShowBrokerDescription(true);
            },
            disabled: () => false,
          },
        ]}
        tablePagination={{
          current: pageable.page + 1,
          defaultPageSize: pageable.elementsPerPage,
          totalItems: pageable.totalElements,
        }}
        onChangePage={loadHsmLogs}
        loading={loading}
      />
      <Modal
        title="Resposta da solicitação"
        visible={showBrokerDescription}
        onOk={() => setShowBrokerDescription(false)}
        onCancel={() => setShowBrokerDescription(false)}
      >
        <h3>
          Hsm:&nbsp;
          <span>{hsmLogSelected?.hsm.name}</span>
        </h3>
        <p>{hsmLogSelected?.broker_description}</p>
      </Modal>
    </Card>
  );
};

export default HsmLogInteraction;
