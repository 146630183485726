import { Descriptions, Divider } from 'antd';
import React from 'react';
import { IWabaTemplate } from '../../interfaces/waba';

interface IProps {
  template?: IWabaTemplate;
}
const WabaDetailTemplates: React.FC<IProps> = props => {
  const { template } = props;

  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label="ID" span={1}>
          {template?.id}
        </Descriptions.Item>
        <Descriptions.Item label="Nome" span={2}>
          {template?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Categoria" span={1}>
          {template?.category}
        </Descriptions.Item>
        <Descriptions.Item label="Language" span={1}>
          {template?.language}
        </Descriptions.Item>
        <Descriptions.Item label="Status" span={1}>
          {template?.status}
        </Descriptions.Item>
        <Descriptions.Item span={3}>
          {
            template?.components?.filter(c => {
              return c.type === 'BODY';
            })[0].text
          }
        </Descriptions.Item>
      </Descriptions>
      <Divider />
    </>
  );
};

export default WabaDetailTemplates;
