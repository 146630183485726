import { Card } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaPlus, FaSyncAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import Table from '../../components/Table';
import { IActionHeaderConfig, ITableColumn } from '../../interfaces/table';

import { IUserList } from '../../interfaces/user';
import { useAuth } from '../../hooks/auth';
import IIntegrationsConfigList from '../../interfaces/integrationConfig';
import { useApi } from '../../hooks/api';

const IntegrationsConfig: React.FC = () => {
  const { user } = useAuth();
  const { api } = useApi();
  const history = useHistory();
  const [integrationsConfig, setIntegrationsConfig] = useState<
    IIntegrationsConfigList[]
  >([]);
  const [loading, setLoading] = useState(false);

  const loadIntegrationsConfig = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/integrations-config');
      setIntegrationsConfig(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [api]);

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Canal',
      dataIndex: 'media_name',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Broker',
      dataIndex: 'broker',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Configuração',
      dataIndex: 'config',
      responsive: false,
      renderColumn: (data: IIntegrationsConfigList) => {
        const config = JSON.parse(data.config);
        const response = config.join(', ');
        return response;
      },
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        loadIntegrationsConfig();
      },
    },
    {
      titleAction: 'Nova Configuração',
      icon: <FaPlus />,
      action: () => {
        history.push('/integrations/config/create');
      },
    },
  ];

  useEffect(() => {
    loadIntegrationsConfig();
  }, [loadIntegrationsConfig]);

  return (
    <Card>
      <Table
        title="Configuração de Integrações"
        actionsConfig={headerActions}
        data={integrationsConfig}
        keyIndex="id"
        tableColumns={tableColumns}
        tableActions={[
          {
            name: 'Editar',
            icon: <EditOutlined />,
            action: (data: IUserList) => {
              history.push(`/integrations/config/create/${data.id}`);
            },
            disabled: () => {
              return !user.root;
            },
          },
        ]}
        isPaginated={false}
        loading={loading}
      />
    </Card>
  );
};

export default IntegrationsConfig;
