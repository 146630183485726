import React, { useCallback } from 'react';
import {
  RouteProps as ReactDOMRoutProps,
  Route as ReactDOMRoute,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';
import { useAuth } from '../hooks/auth';
import AllowedProfilesType from '../types/allowedProfile';

const SentryRoute = withSentryRouting(ReactDOMRoute);

interface RouteProps extends ReactDOMRoutProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  allowedProfiles?: AllowedProfilesType[];
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  allowedProfiles = ['ALL'],
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  const renderComponenetPermission = useCallback(
    ({ location }: RouteComponentProps) => {
      if (
        isPrivate === !!user &&
        (user.root ||
          allowedProfiles.includes(user.user_profile as AllowedProfilesType))
      ) {
        return <Component />;
      }
      return (
        <Redirect
          to={{
            pathname: isPrivate ? '/' : '/dashboard',
            state: { from: location },
          }}
        />
      );
    },
    [user, allowedProfiles, isPrivate],
  );

  return (
    <SentryRoute
      path={rest.path}
      exact={rest.exact}
      // Outras propriedades aqui...
      render={props => {
        return isPrivate === !!user && allowedProfiles.includes('ALL') ? (
          <Component />
        ) : (
          renderComponenetPermission(props)
        );
      }}
    />
  );
};

export default Route;
