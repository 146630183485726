import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useApi } from './api';
import { useApplication } from './application';
import { initFacebookSdk } from '../utils/facebookInit';
import IClientsList from '../interfaces/clients';

interface AuthProviderProps {
  children: React.ReactNode;
}

interface User {
  id: string;
  name: string;
  email: string;
  blocked: boolean;
  root: boolean;
  user_profile: string;
  reset_password: boolean;
  client?: IClientsList;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextState {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const STORAGE_KEYS = {
  token: '@DCSAdmin:token',
  user: '@DCSAdmin:user',
};

const getStoredAuthState = (): AuthState => {
  const token = localStorage.getItem(STORAGE_KEYS.token);
  const user = localStorage.getItem(STORAGE_KEYS.user);

  if (token && user) {
    initFacebookSdk(); // apenas para garantir que o objeto window.FB sera criado
    return {
      token,
      user: JSON.parse(user),
    } as AuthState;
  }

  return {} as AuthState;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { api } = useApi();
  const { setLogged } = useApplication();
  const [data, setData] = useState<AuthState>(getStoredAuthState);

  const signIn = useCallback(
    async (credentials: SignInCredentials) => {
      const response = await api.post('/auth', credentials);
      const { token, user } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      try {
        await initFacebookSdk();
      } catch (err) {
        console.log(err);
      }

      localStorage.setItem('@DCSAdmin:token', token);
      localStorage.setItem('@DCSAdmin:user', JSON.stringify(user));

      setData({
        token,
        user,
      });
      setLogged();
    },
    [setLogged, api],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@DCSAdmin:token');
    localStorage.removeItem('@DCSAdmin:user');

    setData({} as AuthState);
  }, []);

  const authContextValue = useMemo(
    () => ({
      user: data.user,
      signIn,
      signOut,
    }),
    [data.user, signIn, signOut],
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextState => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
