import React, { useCallback } from 'react';
import {
  Button,
  Menu,
  Dropdown,
  Pagination,
  Spin,
  Space,
  Typography,
} from 'antd';
import { FaEllipsisV, FaFileContract } from 'react-icons/fa';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import { v4 } from 'uuid';
import TableItem from '../TableItem';

import './styles.scss';
import {
  IActionHeaderConfig,
  ITableColumn,
  TableActions,
  TablePaginationConfig,
} from '../../interfaces/table';

interface IProps {
  title?: string;
  actionsConfig?: IActionHeaderConfig[];
  tableColumns: ITableColumn[];
  tableActions?: TableActions[];
  isPaginated?: boolean;
  tablePagination?: TablePaginationConfig;
  data: any[];
  keyIndex: any;
  loading?: boolean;
  report?: boolean;
  action?: boolean;
  sort?: string;
  onChangePage?(page: number): Promise<void>;
  handleSorter?: () => void;
}

const { Text } = Typography;

const Table: React.FC<IProps> = props => {
  const {
    title,
    actionsConfig = [],
    tableColumns = [],
    data,
    keyIndex,
    tableActions,
    isPaginated = true,
    tablePagination,
    loading = false,
    onChangePage,
    report,
    action = true,
    sort,
    handleSorter,
  } = props;
  // const { titleAction, action, icon } = actionConfig;

  const renderActions = useCallback(() => {
    return (
      actionsConfig.length > 0 && (
        <Space>
          {actionsConfig.map(actionConfig => (
            <Button
              key={actionConfig.titleAction}
              type="primary"
              shape="round"
              icon={actionConfig.icon}
              onClick={actionConfig.action}
            >
              {actionConfig.titleAction}
            </Button>
          ))}
        </Space>
      )
    );
  }, [actionsConfig]);

  const tableMenu = useCallback(
    (object: any) => {
      return (
        <Menu>
          {tableActions !== undefined &&
            tableActions.map(tableAction => (
              <Menu.Item
                key={tableAction.name}
                icon={tableAction.icon}
                disabled={tableAction.disabled(object)}
                onClick={() => tableAction.action(object)}
              >
                {tableAction.name}
              </Menu.Item>
            ))}
        </Menu>
      );
    },
    [tableActions],
  );

  return (
    <div className="table-dcs">
      {title ? (
        <header>
          <h2>{title}</h2>
          {renderActions()}
        </header>
      ) : undefined}
      <Spin size="large" spinning={loading}>
        <table>
          <thead>
            <tr>
              {tableColumns.map(column => (
                <th align="center" key={`th_${column.name}`}>
                  {column.iconSorter ? (
                    <div
                      className="column-iconSort"
                      style={{ textAlign: 'center' }}
                    >
                      {column.name}

                      {column.iconSorter &&
                        (sort === 'ascend' ? (
                          <ArrowDownOutlined onClick={handleSorter} />
                        ) : (
                          <ArrowUpOutlined onClick={handleSorter} />
                        ))}
                    </div>
                  ) : (
                    column.name
                  )}
                </th>
              ))}
              {action &&
                (report ? (
                  <th className="table-column responsive">Ação</th>
                ) : (
                  <th className="table-column responsive">Controle</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <Text type="secondary">Nenhum registro encontrado.</Text>
            )}
            {data.map(object => {
              return (
                <tr key={`tr_${v4()}`}>
                  {tableColumns.map((columnData, index) =>
                    tableColumns[index].renderColumn !== undefined ? (
                      <TableItem
                        key={`${object[keyIndex]}_${index}`}
                        responsive={columnData.responsive}
                        dataRender={tableColumns[index].renderColumn?.(object)}
                      />
                    ) : (
                      <TableItem
                        key={`${object[keyIndex]}_${index}`}
                        responsive={columnData.responsive}
                        // wrap={columnData.wrap}
                        // limit={object.amount > object.total}
                        dataRender={object[tableColumns[index].dataIndex]}
                      />
                    ),
                  )}
                  {action && (
                    <td className="table-column">
                      <Dropdown
                        overlay={() => tableMenu(object)}
                        trigger={['click']}
                      >
                        {report ? (
                          <Button shape="circle" type="primary" size="large">
                            <FaFileContract />
                          </Button>
                        ) : (
                          <Button shape="circle" size="large" type="text">
                            <FaEllipsisV />
                          </Button>
                        )}
                      </Dropdown>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isPaginated && (
          <div className="table-actions">
            <Pagination
              current={tablePagination?.current}
              total={tablePagination?.totalItems}
              defaultPageSize={tablePagination?.defaultPageSize}
              showSizeChanger={false}
              onChange={(pageNumber: number) => {
                if (
                  pageNumber !== undefined &&
                  typeof onChangePage === 'function'
                ) {
                  onChangePage(pageNumber);
                }
              }}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default Table;
