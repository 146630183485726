export enum UserTypes {
  ADMINISTRATOR = 'ADMINISTRATOR',
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
}

export enum LogInteractionStatusEnum {
  QUEUE = 'QUEUE',
  WAITING_RESPONSE = 'WAITING_RESPONSE',
  FINISHED = 'FINISHED',
}

export enum MediaNameEnum {
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  FBMESSENGER = 'FBMESSENGER',
  INSTAGRAM = 'INSTAGRAM',
  FBCOMMENTS = 'FBCOMMENTS',
}

export enum HsmStatus {
  PENDING = 'PENDING',
  IN_ANALYSIS = 'IN_ANALYSIS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DISABLED = 'DISABLED',
}
