const formatCurrency = (value: number | string | undefined): string => {
  const numberValue =
    typeof value === 'string' ? Number(value.replace(',', '.')) : value;

  if (numberValue || numberValue === 0) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(numberValue);
  }

  return '';
};

const formatValueToWithoutChars = (value: string): number => {
  if (value === null) return 0;
  value.toString();
  const replace = value.match(/\d+/g);
  return replace ? parseInt(replace.join(''), 10) : 0;
};

const formatValueToNumber = (value: number): number => {
  const valueString = String(value);

  let decimal1 = valueString.charAt(valueString.length - 2);
  const decimal2 = valueString.charAt(valueString.length - 1);
  if (decimal1 === '') decimal1 = '0';
  const valueFormated = `${valueString.slice(
    0,
    valueString.length - 2,
  )}.${decimal1}${decimal2}`;

  return parseFloat(valueFormated);
};

const formatValue = (value: string): number => {
  return Number(value.replace(',', '.'));
};

export {
  formatValueToNumber,
  formatValueToWithoutChars,
  formatCurrency,
  formatValue,
};
