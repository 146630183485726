import React, { memo, useCallback } from 'react';
import { FaEnvelope, FaTrash, FaUser } from 'react-icons/fa';
import { IContact } from '../../interfaces/contact';

import './styles.scss';

interface IProps {
  contact: IContact;
  removeContact: (e: IContact) => void;
}

const ContactCardItem: React.FC<IProps> = props => {
  const { contact, removeContact } = props;

  const formatAvatarName = useCallback((name: string) => {
    const nameSplited = name.split(' ');

    if (nameSplited.length === 2) {
      return `${nameSplited[0].charAt(0).toUpperCase()}${nameSplited[1]
        .charAt(0)
        .toUpperCase()}`;
    }

    return `${nameSplited[0].charAt(0).toUpperCase()}`;
  }, []);

  return (
    <li className="contact-item">
      <div className="contact-user">
        <span className="contact-avatar">{formatAvatarName(contact.name)}</span>
        <div className="contact-info">
          <div className="contact-info-name">
            <FaUser />
            <span>{contact.name}</span>
          </div>
          <div className="contact-info-email">
            <FaEnvelope />
            <span>{contact.email}</span>
          </div>
        </div>
      </div>
      <div className="contact-actions">
        <FaTrash
          className="button-remove"
          onClick={() => {
            removeContact(contact);
          }}
        />
      </div>
    </li>
  );
};

export default memo(ContactCardItem);
