/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import { FaFileExcel, FaFilePdf, FaFile, FaFilter } from 'react-icons/fa';

import { v4 } from 'uuid';
import { useApi } from '../../hooks/api';

import './styles.scss';
import Table from '../../components/Table';
import { IActionHeaderConfig, ITableColumn } from '../../interfaces/table';
import { useAuth } from '../../hooks/auth';
import { notificationDCS } from '../../utils/notificationDcs';
import BillingReportFilters from '../../components/BillingReportFilters';
import { IPageable, DEFAULT_PAGEABLE } from '../../interfaces/pageable';
import {
  IClientsForListBillingsReport,
  IIntegrationForListBillingsReport,
  IIntegrationPropsRequestBilling,
} from '../../interfaces/integrationBilling';

interface IBillingsTable {
  id: string;
  client_id: string;
  client_name: string;
  integration_id?: string;
  integration_name?: string;
  // license_id: string;
  license_name: string;
  channel: string;
  ends_in: string;
  billing_date: string;
  starts_in: string;
  total: number;
}

const BillingReport: React.FC = () => {
  const { user } = useAuth();
  const { api } = useApi();
  const [clients, setClients] = useState<IClientsForListBillingsReport[]>([]);
  const [integrations, setIntegrations] = useState<
    IIntegrationForListBillingsReport[]
  >([]);
  const [billings, setBillings] = useState<IIntegrationPropsRequestBilling[]>(
    [],
  );
  const [billingsTable, setBillingsTable] = useState<IBillingsTable[]>([]);
  const [loadingBillings, setLoadingBillings] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);
  const [filters, setFilters] = useState({
    client_id: '',
    media_name: '',
    billing_date: '',
  });

  const formatDate = useCallback((date: string) => {
    const parse = date.split('T');
    const date_split = parse[0].split('-');
    return `${date_split[2]}/${date_split[1]}/${date_split[0]}`;
  }, []);

  const handleBillingTable = (billings: IIntegrationPropsRequestBilling[]) => {
    const tableItens: IBillingsTable[] = [];
    for (const obj of billings) {
      const item: IBillingsTable = {
        id: obj.id,
        client_id: obj.client.id,
        client_name: obj.client.name,
        channel: obj.license?.media,
        // license_id: obj.license.id,
        license_name: obj.license?.name,
        starts_in: obj.start_period
          ? formatDate(obj.start_period)
          : '',
        ends_in: obj.end_period
          ? formatDate(obj.end_period)
          : '',
        billing_date: formatDate(obj.created_at),
        total: obj.invoice_amount,
      };

      tableItens.push(item);
    }
    setBillingsTable(tableItens);
  };

  const loadBillings = useCallback(
    async (
      pageNumber = 1,
      client_id = filters.client_id,
      billing_date = filters.billing_date,
      media_name = filters.media_name,
    ) => {
      try {
        setLoadingBillings(true);
        const { data } = await api.get(`/billings/paginated`, {
          params: {
            page: pageNumber - 1,
            client_id,
            media_name,
            billing_date,
          },
        });
        const responseData = data.data;
        const closedInvoices = responseData.filter(
          (invoice: IIntegrationPropsRequestBilling) =>
            invoice.end_period !== null,
        );

        setPageable(data);
        setBillings(closedInvoices);
        handleBillingTable(responseData);
        setLoadingBillings(false);
      } catch (err) {
        setLoadingBillings(false);
        console.error(err);
      }
    },
    [api, filters.client_id, filters.billing_date, filters.media_name],
  );

  const loadClients = useCallback(async () => {
    const { data } = await api.get(`/clients`);

    setClients(data);
  }, [api]);

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Cliente',
      dataIndex: 'client_name',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Canal',
      dataIndex: 'channel',
      responsive: true,
      renderColumn(item: IBillingsTable) {
        return <span>{item.channel?.toUpperCase()}</span>;
      },
    },
    {
      id: v4(),
      name: 'Licença',
      dataIndex: 'License',
      responsive: true,
      renderColumn(item: IBillingsTable) {
        return <span>{item.license_name}</span>;
      },
    },
    {
      id: v4(),
      name: 'Período',
      dataIndex: 'period',
      responsive: false,
      renderColumn(item: IBillingsTable) {
        return (
          <span>
            {item.starts_in} - {item.ends_in}
          </span>
        );
      },
    },
    {
      id: v4(),
      name: 'Data de faturamento',
      dataIndex: 'billing_date',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Total',
      dataIndex: 'total',
      responsive: false,
      /* renderColumn(object) {
        return (
          <span>R$ 1000,00</span>
          // <span>
          //   {object.total.toLocaleString('pt-BR', {
          //     style: 'currency',
          //     currency: 'BRL',
          //   })}
          // </span>
        );
      }, */
    },
  ];

  const onSearch = useCallback(
    (client_id: string, media_name: string, billing_date: string) => {
      setFilters({
        client_id,
        billing_date,
        media_name,
      });
      setShowFilters(false);
      loadBillings(1, client_id, billing_date, media_name);
    },
    [loadBillings],
  );

  const generateReport = useCallback(
    async (type: 'excel' | 'pdf' | 'html', billingId?: string) => {
      const reportData = {
        billing_id: billingId,
      };
      const response = await api.post(`/reports/${type}/billing`, reportData);
      if (response.status === 400) {
        notificationDCS({
          type: 'warning',
          message: 'Atenção',
          description:
            'Não foi encontrado fatura referente a esse cliente/licença/período!',
        });
      } else if (response.status === 204) {
        notificationDCS({
          type: 'warning',
          message: 'Atenção',
          description: 'Relatório indisponivel no momento!',
        });
      } else {
        window.open(response.data.report_url, '_blank');
      }
    },
    [api],
  );

  useEffect(() => {
    try {
      setLoadingBillings(true);
      loadBillings();
      loadClients();
      setLoadingBillings(false);
    } catch (err) {
      setLoadingBillings(false);
    }
  }, [loadBillings, loadClients]);

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
  ];

  return (
    <Card className="card-reports">
      <Spin spinning={loadingBillings} size="large">
        <Table
          title="Relatório Sintético"
          data={billingsTable}
          keyIndex="id"
          tableColumns={tableColumns}
          loading={loadingBillings}
          actionsConfig={headerActions}
          tableActions={[
            {
              name: 'Gerar XLSX',
              icon: <FaFileExcel />,
              action: (data: IBillingsTable) =>
                generateReport('excel', data.id),
              disabled: () => {
                return !['ADMINISTRATOR', 'ROOT'].includes(user.user_profile);
              },
            },
            {
              name: 'Gerar PDF',
              icon: <FaFilePdf />,
              action: (data: IBillingsTable) => generateReport('pdf', data.id),
              disabled: () => {
                return !['ADMINISTRATOR', 'ROOT'].includes(user.user_profile);
              },
            },
            {
              name: 'Gerar HTML',
              icon: <FaFile />,
              action: (data: IBillingsTable) => generateReport('html', data.id),
              disabled: () => {
                return !['ADMINISTRATOR', 'ROOT'].includes(user.user_profile);
              },
            },
          ]}
          report
          tablePagination={{
            current: pageable.page + 1,
            defaultPageSize: pageable.elementsPerPage,
            totalItems: pageable.totalElements,
          }}
          onChangePage={loadBillings}
        />
        <BillingReportFilters
          closeFilters={() => setShowFilters(false)}
          showFilters={showFilters}
          search={onSearch}
          integrations={integrations}
          clients={clients}
        />
      </Spin>
    </Card>
  );
};

export default BillingReport;
