import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useApi } from '../../hooks/api';
import ILicenseForm from '../../interfaces/licenseForm';
import { formatValue } from '../../utils/formatCurrency';
import CategoryForm from '../CategoryForm';
import './styles.scss';
import CurrencyInputField from '../CurrencyInput';
import { notificationDCS } from '../../utils/notificationDcs';

const { Option } = Select;

interface IProps {
  licenseId: string;
  clientId: string;
  saveLicense(data: ILicenseForm): Promise<void>;
  setShowLicenseForm: (value: boolean) => void;
}

const LicenseForm: React.FC<IProps> = props => {
  const { saveLicense, licenseId, clientId, setShowLicenseForm } = props;
  const { api } = useApi();
  const [loading, setLoading] = useState(false);
  const [mediaName, setMediaName] = useState('');
  const [showOldVersion, setShowOldVersion] = useState(false);
  const [form] = useForm();
  const [formLicenseId, setFormLicenseId] = useState('');
  const [inputValues, setInputValues] = useState({
    package_value: 0,
    extra_message_value: 0,
    additional_message_value_notification: 0,
    additional_message_value_received: 0,
    additional_message_value_sent: 0,
    available_quantity: 0,
    package_value_detail: 0,
    package_value_notification: 0,
  });
  const [licenseDisabled, setLicenseDisabled] = useState(false);

  const chargeType = 'GENERAL';

  const cancelLicenseEdit = () => {
    form.resetFields();
    setShowLicenseForm(false);
  };

  const loadCategories = useCallback(
    async (responseData: Array<any>) => {
      const categoriesData = responseData.map((item: any) => ({
        type: item.type,
        available_quantity: item.available_quantity,
        package_value_notification: item.package_value_notification,
        additional_message_value_notification:
          item.additional_message_value_notification,
      }));

      categoriesData.forEach((category: any) => {
        const categoryFieldName = category.type.replace(/\s/g, '_');
        const availableQuantityFieldName = `available_quantity_${categoryFieldName}`;
        const packageValueNotificationFieldName = `package_value_notification_${categoryFieldName}`;
        const additionalMessageValueNotificationFieldName = `additional_message_value_notification_${categoryFieldName}`;

        form.setFieldsValue({
          [availableQuantityFieldName]: category.available_quantity,
          [packageValueNotificationFieldName]:
            category.package_value_notification,
          [additionalMessageValueNotificationFieldName]:
            category.additional_message_value_notification,
        });
      });

      if (licenseId && categoriesData.length === 0) {
        setShowOldVersion(true);
      }
    },
    [api, form, licenseId],
  );

  const loadOneLicense = useCallback(
    async (licenseId: string) => {
      const response = await api.get(`/licenses/${licenseId}`);
      setMediaName(response.data.media);
      setFormLicenseId(response.data.id);
      if (response.data.media === 'whatsapp') {
        loadCategories(response.data.categories);
      }
      setLicenseDisabled(response.data.enabled === false);

      // formatResponseValues(response);

      form.setFieldsValue(response.data);
      setInputValues(response.data);
    },
    [api, licenseId],
  );

  useEffect(() => {
    if (licenseId) {
      loadOneLicense(licenseId);
    }
  }, [licenseId, loadOneLicense]);

  const onSave = useCallback(
    async (values: any) => {
      try {
        if (licenseDisabled === true && formLicenseId) {
          notificationDCS({
            type: 'error',
            message: 'Erro',
            description: 'Não é possível editar uma licença desabilitada',
            secondsDuration: 2,
          });
          return;
        }
        setLoading(true);
        let license = values;
        license = { ...license, ...inputValues };

        license.id = formLicenseId || undefined;

        const data: ILicenseForm = {
          ...license,
          client_id: clientId,
        };

        const categories = [
          {
            type: 'Utilidades',
            available_quantity: `${values.available_quantity_Utilidades}`,
            package_value_notification: `${values.package_value_notification_Utilidades}`,
            additional_message_value_notification: `${values.additional_message_value_notification_Utilidades}`,
          },
          {
            type: 'Marketing',
            available_quantity: `${values.available_quantity_Marketing}`,
            package_value_notification: `${values.package_value_notification_Marketing}`,
            additional_message_value_notification: `${values.additional_message_value_notification_Marketing}`,
          },
          {
            type: 'Autenticação',
            available_quantity: `${values.available_quantity_Autenticação}`,
            package_value_notification: `${values.package_value_notification_Autenticação}`,
            additional_message_value_notification: `${values.additional_message_value_notification_Autenticação}`,
          },
          {
            type: 'Serviço',
            available_quantity: `${values.available_quantity_Serviço}`,
            package_value_notification: `${values.package_value_notification_Serviço}`,
            additional_message_value_notification: `${values.additional_message_value_notification_Serviço}`,
          },
        ];

        const formatCategories = (categories: any[]) => {
          return categories?.map(item => {
            return {
              type: item.type,
              available_quantity: Number(item.available_quantity),
              package_value_notification: formatValue(
                item.package_value_notification,
              ),
              additional_message_value_notification: formatValue(
                item.additional_message_value_notification,
              ),
            };
          });
        };

        if (mediaName === 'whatsapp' && !showOldVersion) {
          const whatsappForm: ILicenseForm = {
            client_id: data.client_id,
            media: 'whatsapp',
            categories: formatCategories(categories),
          }
          await saveLicense(whatsappForm);
        } else {
          const smsForm: ILicenseForm = {
            client_id: data.client_id,
            media: 'sms',
            available_quantity: data.available_quantity,
            package_value: data.package_value,
            extra_message_value: data.extra_message_value
          }
          await saveLicense(smsForm);
        }
        setLoading(false);
        setShowLicenseForm(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [
      clientId,
      formLicenseId,
      inputValues,
      mediaName,
      saveLicense,
      setShowLicenseForm,
      showOldVersion,
    ],
  );

  const handleSwitchChange = (event: any) => {
    setShowOldVersion(event);
  };

  const handleChangePackageValue = useCallback(
    (name: string, value: any): void => {
      const valueWithDot = !value
        ? 0
        : Number(value.toString().replace(',', '.'));
      setInputValues({
        ...inputValues,
        [name]: valueWithDot,
      });
    },
    [inputValues],
  );

  const handleChangeValue = useCallback(
    (name: string, value: any): void => {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    },
    [inputValues],
  );

  const handleMediaType = (type: string) => {
    setMediaName(type);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSave}
      className="license-form"
      initialValues={{ charge_type: chargeType }}
    >
      <Row>
        <Col span={8}>
          <Form.Item
            label="Canal:"
            name="media"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Canal"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
              onChange={handleMediaType}
              disabled={!!licenseId}
            >
              <Option value="whatsapp">WhatsApp</Option>
              <Option value="sms">SMS</Option>
            </Select>
          </Form.Item>
        </Col>
        <Divider />
        <Col span={mediaName === 'whatsapp' ? 24 : 8}>
          {licenseId && (
            <Alert
              message="Mudança de valores serão programadas para o próximo período de faturamento."
              type="warning"
              className="ant-alert-message"
              style={{ marginBottom: 10 }}
            />
          )}
          {mediaName === 'whatsapp' && (
            <Form.Item name="toggle_form">
              <div className="version-wrapper">
                <Row align="middle">
                  <Col flex="none">
                    <Switch
                      checked={showOldVersion}
                      onChange={handleSwitchChange}
                    />
                  </Col>
                  <Col flex="auto">
                    <span>Versão antiga</span>
                  </Col>
                </Row>
              </div>
            </Form.Item>
          )}
          {formLicenseId && (
            <Col span={24}>
              <Form.Item
                label="Nome da licença:"
                name="name"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  disabled
                  className="dcs-input"
                  placeholder="ex.: Id da Licença"
                />
              </Form.Item>
            </Col>
          )}

          {mediaName === 'whatsapp' && !showOldVersion && (
            <>
              <Row>
                <Col>
                  <h3>WhatsApp Ativo</h3>
                </Col>
              </Row>
              <CategoryForm category="Marketing" />
              <div className='custom-required-item'>
              <CategoryForm category="Utilidades" />
              <CategoryForm category="Autenticação" />
              </div>
              <Row>
                <Col>
                  <h3>WhatsApp Receptivo</h3>
                </Col>
              </Row>
              <CategoryForm category="Serviço" />
            </>
          )}
          {mediaName === 'whatsapp' && showOldVersion && (
            <>
              <Form.Item
                label="Quantidade de mensagens de notificação (HSM):"
                name="quantity_notification_messages"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <InputNumber
                  className="dcs-input"
                  placeholder="Ex.: 1000"
                  onChange={e =>
                    handleChangeValue('quantity_notification_messages', e)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Valor do Pacote (HSM):"
                name="package_value_notification"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <CurrencyInputField
                  // value={packageValue.toString()}
                  onChange={e =>
                    handleChangePackageValue('package_value_notification', e)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Valor por Mensagem Adicional:"
                name="additional_message_value_notification"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <CurrencyInputField
                  onChange={e =>
                    handleChangePackageValue(
                      'additional_message_value_notification',
                      e,
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                label="Quantidade de mensagens disponíveis:"
                name="available_quantity"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <InputNumber className="dcs-input" placeholder="Ex.: 1000" onChange={e =>
                  handleChangeValue('available_quantity', e)
                  } />
              </Form.Item>

              <Form.Item
                label="Valor do Pacote:"
                name="package_value"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <CurrencyInputField
                  onChange={e => handleChangePackageValue('package_value', e)}
                />
              </Form.Item>

              <Form.Item
                label="Valor da Mensagem Extra:"
                name="extra_message_value"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <CurrencyInputField
                  onChange={e =>
                    handleChangePackageValue('extra_message_value', e)
                  }
                />
              </Form.Item>
            </>
          )}

          {mediaName === 'sms' && (
            <>
              <Form.Item
                label="Quantidade de mensagens disponíveis:"
                name="available_quantity"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <InputNumber
                  className="dcs-input"
                  placeholder="1000"
                  onChange={e =>
                    handleChangePackageValue('available_quantity', e)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Valor do Pacote:"
                name="package_value"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <CurrencyInputField
                  onChange={e => handleChangePackageValue('package_value', e)}
                />
              </Form.Item>
              <Form.Item
                label="Valor da Mensagem Extra:"
                name="extra_message_value"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <CurrencyInputField
                  onChange={e =>
                    handleChangePackageValue('extra_message_value', e)
                  }
                />
              </Form.Item>
            </>
          )}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          {licenseDisabled ? (
            <Tooltip
              placement="top"
              title="Não é possível editar uma licença desabilitada"
              arrow
            >
              <Button
                type="primary"
                shape="round"
                icon={<FaSave />}
                htmlType="submit"
                loading={loading}
                disabled={licenseDisabled}
              >
                {formLicenseId ? 'Atualizar licença' : 'Salvar'}
              </Button>
            </Tooltip>
          ) : (
            <Button
              type="primary"
              shape="round"
              icon={<FaSave />}
              htmlType="submit"
              loading={loading}
              disabled={licenseDisabled}
            >
              {formLicenseId ? 'Atualizar licença' : 'Salvar'}
            </Button>
          )}
        </Col>
        <Col>
          <Button shape="round" icon={<FaTimes />} onClick={cancelLicenseEdit}>
            Cancelar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default LicenseForm;
