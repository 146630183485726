import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Tag, Typography, Modal } from 'antd';
import { v4 } from 'uuid';
import { FaPlus } from 'react-icons/fa';
import { ExclamationCircleFilled, PoweroffOutlined } from '@ant-design/icons';
import Table from '../../../components/Table';
import { useApi } from '../../../hooks/api';
import ILicenseList from '../../../interfaces/license';
import { ITableColumn, TableActions } from '../../../interfaces/table';
import { formatDate } from '../../../utils/dateUtil';

interface IProps {
  clientId: string;
  setLicenseSelected: (licenseId?: string) => void;
}

const LicensesClient: React.FC<IProps> = ({ setLicenseSelected, clientId }) => {
  const [licenses, setLicenses] = useState<ILicenseList[]>([]);
  const { api } = useApi();

  const { confirm } = Modal;

  const { Text } = Typography;

  const loadLicenses = useCallback(async () => {
    const response = await api.get(`licenses/client/${clientId}`);
    setLicenses(response.data);
  }, [api]);

  useEffect(() => {
    if (clientId) loadLicenses();
  }, [loadLicenses]);

  const columns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Nome',
      dataIndex: 'name',
      responsive: true,
      renderColumn: (data: ILicenseList) => {
        return (
          <Text
            style={{
              display: 'inline-block',
              alignItems: 'center',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={evt => setLicenseSelected(data.id)}
          >
            {data.name}
          </Text>
        );
      },
    },
    {
      id: v4(),
      name: 'Canal',
      dataIndex: 'media',
      responsive: true,
      renderColumn: (data: ILicenseList) => (
        <Text>{data?.media?.toUpperCase()}</Text>
      ),
    },
    {
      id: v4(),
      name: 'Status',
      dataIndex: 'enabled',
      responsive: false,
      renderColumn: (data: ILicenseList) => {
        return (
          <Tag color={data.enabled ? 'success' : 'error'} style={{ margin: 0 }}>
            {data.enabled ? 'ATIVO' : 'INATIVO'}
          </Tag>
        );
      },
    },
    {
      id: v4(),
      name: 'Ultima Atualização',
      dataIndex: 'updated_at',
      responsive: false,
      renderColumn(data: ILicenseList) {
        return <Text>{formatDate(data.updated_at)}</Text>;
      },
    },
  ];

  const disabledLicense = useCallback(
    async (licenseId: string, enabled: boolean) => {
      const modal = confirm({
        title: `Tem certeza que deseja ${
          enabled ? 'desativar' : 'ativar'
        } a licença?`,
        icon: <ExclamationCircleFilled />,
        async onOk() {
          modal.destroy();
          await api.patch(`licenses/${licenseId}`, { enabled: !enabled });
          loadLicenses();
        },
        onCancel() {},
      });
    },
    [],
  );

  const tableActions: TableActions[] = [
    {
      name: 'Desativar/Ativar',
      icon: <PoweroffOutlined />,
      action: (data: ILicenseList) => {
        disabledLicense(data.id, data.enabled);
      },
      disabled: () => {
        return false;
      },
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        type="primary"
        shape="round"
        style={{
          float: 'right',
          marginTop: 10,
          flex: 1,
          width: 'fit-content',
          marginBottom: '10px',
          alignSelf: 'flex-end',
        }}
        icon={<FaPlus />}
        onClick={() => setLicenseSelected()}
      >
        Nova licença
      </Button>

      <Card style={{ display: 'flex', flexDirection: 'column' }}>
        <Table
          // title="Licenças cadastradas"
          data={licenses}
          tableColumns={columns}
          keyIndex="id"
          isPaginated={false}
          action
          tableActions={tableActions}
        />
      </Card>
    </div>
  );
};

export { LicensesClient };
