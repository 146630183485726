import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import IntlTelInput from 'react-phone-number-input';
import './styles.scss';

interface PhoneInputProps {
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

// function formatPhoneNumber(value: string) {
//   if (value) {
//     const phoneNumber = value.replace(/\D/g, ''); // Remova caracteres não numéricos
//     const formatted = `+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 4)}) ${phoneNumber.slice(4, 9)}-${phoneNumber.slice(9)}`;
//     return formatted;
//   }
//   return value;
// }

const PhoneInput = ({ onChange, disabled = false, value }: PhoneInputProps) => {
  // const [value, setValue] = useState();

  return (
    <IntlTelInput
      defaultCountry="BR"
      value={value}
      disabled={disabled}
      className="dcs-input phone-input"
      onChange={onChange}
      limitMaxLength
      // format={formatPhoneNumber}
    />
  );
};

export default PhoneInput;
