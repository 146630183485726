import { Descriptions, Divider, Tag } from 'antd';
import { format } from 'date-fns';
import React, { memo } from 'react';
import IIntegrationsList from '../../interfaces/integrationsList';

interface IProps {
  integration: IIntegrationsList;
}

const ClientDetailIntegrationItem: React.FC<IProps> = props => {
  const { integration } = props;

  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label="Nome">
          {integration.name}
        </Descriptions.Item>
        <Descriptions.Item label="Canal">
          {integration.media_name.toUpperCase()}
        </Descriptions.Item>
        <Descriptions.Item label="Broker">
          {integration.broker.toUpperCase()}
        </Descriptions.Item>
        <Descriptions.Item label="Credenciais" span={3}>
          {integration.credentials}
        </Descriptions.Item>
        <Descriptions.Item label="Integration ID" span={1}>
          {integration.id}
        </Descriptions.Item>
        <Descriptions.Item label="Credentials Key">
          {integration.credentials_key}
        </Descriptions.Item>
        {integration.media_name.toUpperCase() !== 'SMS' && (
          <Descriptions.Item label="Conta">
            {integration.account}
          </Descriptions.Item>
        )}

        <Descriptions.Item label="Status da Integração">
          <Tag
            color={
              integration.status === 'ENABLED' ? 'green' : 'warning'
            }
          >
            {integration.status === 'ENABLED'
              ? 'Ativo'
              : 'Inativo'}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Data de Cadastro">
          {format(new Date(integration.createdAt), 'd/MM/yyyy HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label="Última Atualização">
          {format(new Date(integration.updatedAt), 'd/MM/yyyy HH:mm')}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
    </>
  );
};

export default memo(ClientDetailIntegrationItem);
