import React, { useCallback } from 'react';
import { Spin, Descriptions, Divider } from 'antd';
import { parseISO, format } from 'date-fns';
import { pt } from 'date-fns/locale';
import TagDcs from '../TagDcs';

import { IHsmDetail } from '../../interfaces/hsm';

interface IProps {
  hsm: IHsmDetail | undefined;
  // formatDate(date: Date): void;
  loading: boolean | undefined;
}

const HsmDetailInfo: React.FC<IProps> = ({ hsm, loading }) => {
  const formatDate = useCallback((date: Date | undefined) => {
    const dateIso = parseISO(
      format(new Date(date || Date.now()), 'yyyy-MM-dd HH:mm'),
    );
    return format(dateIso, 'dd/MM/yyyy HH:mm');
  }, []);

  const renderWebhookNotify = useCallback(() => {
    return hsm?.webhook_notify === true ? (
      <TagDcs title="Sim" type="success" />
    ) : (
      <TagDcs title="Não" type="primary" />
    );
  }, [hsm]);

  return (
    <Spin spinning={loading} size="large">
      <Descriptions bordered>
        <Descriptions.Item
          label="Nome"
          style={{ wordWrap: 'break-word', maxWidth: '150px' }}
        >
          {hsm?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Cliente">
          {hsm?.client.name}
        </Descriptions.Item>
        <Descriptions.Item label="Waba ID">
          {hsm?.integration.waba_id}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Dados do Template</Divider>

      <Descriptions bordered>
        <Descriptions.Item label="Categoria">{hsm?.category}</Descriptions.Item>
        <Descriptions.Item label="Idioma">{hsm?.language}</Descriptions.Item>
        <Descriptions.Item label="Notificação Webhook do Cliente">
          {renderWebhookNotify()}
        </Descriptions.Item>
        <Descriptions.Item label="Corpo" span={5}>
          {hsm?.message}
        </Descriptions.Item>
        {/* <Descriptions.Item label="Cabeçalho" span={5}>
          {' '}
        </Descriptions.Item>
        <Descriptions.Item label="Corpo" span={5}>
          {' '}
        </Descriptions.Item>
        <Descriptions.Item label="Rodapé" span={5}>
          {' '}
        </Descriptions.Item>
        <Descriptions.Item label="Botões" span={5}>
          {' '}
        </Descriptions.Item> */}
      </Descriptions>
      <br />
      {hsm !== undefined && hsm.status === 'APPROVED' && (
        <>
          <Divider orientation="left">HSM Aprovado</Divider>
          <Descriptions bordered>
            <Descriptions.Item label="Namespace" span={1.5}>
              {hsm?.namespace}
            </Descriptions.Item>
            <Descriptions.Item label="Template" span={1.5}>
              {hsm?.template_name}
            </Descriptions.Item>
            <Descriptions.Item label="Data de Resposta do Broker" span={1.5}>
              {formatDate(hsm?.response_date || new Date(Date.now()))}
            </Descriptions.Item>
            <Descriptions.Item label="Data de Aprovação" span={1.5}>
              {formatDate(hsm?.approval_date || Date.now())}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
      {hsm !== undefined && hsm.status === 'REJECTED' && (
        <>
          <Divider orientation="left">HSM Rejeitado</Divider>
          <Descriptions bordered>
            <Descriptions.Item label="Motivo" span={3}>
              {hsm?.broker_description}
            </Descriptions.Item>

            <Descriptions.Item label="Data de Rejeição">
              {formatDate(hsm?.reject_date || Date.now())}
            </Descriptions.Item>
            <Descriptions.Item label="Data de Resposta do Broker" span={2}>
              {formatDate(hsm?.response_date)}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </Spin>
  );
};

export default HsmDetailInfo;
