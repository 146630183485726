import React, { useCallback, useEffect, useState } from 'react';
import { Card, Drawer, Tag, Radio } from 'antd';
import { FaFilter, FaPlus, FaSyncAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import {
  EditOutlined,
  IdcardOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';
import Table from '../../components/Table';
import { IActionHeaderConfig, ITableColumn } from '../../interfaces/table';

import { IUserList } from '../../interfaces/user';
import { useAuth } from '../../hooks/auth';
import { useApi } from '../../hooks/api';

import './styles.scss';
import AllowedProfilesType from '../../types/allowedProfile';
import { IPageable, DEFAULT_PAGEABLE } from '../../interfaces/pageable';
import UsersFilters from '../../components/UsersFilters';

const User: React.FC = () => {
  const { user } = useAuth();
  const { api } = useApi();
  const history = useHistory();
  const [users, setUsers] = useState<IUserList[]>([]);
  const [loading, setLoading] = useState(false);
  const [showChangeRole, setShowChangeRole] = useState(false);
  const [userSelected, setUserSelected] = useState<IUserList>();
  const [roleSelected, setRoleSelected] = useState<string>('');
  const [roles, setRoles] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState(false);

  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);

  const loadUsers = useCallback(
    async (pageNumber = 1, name = '', email = '', status = '', role = '') => {
      try {
        setLoading(true);
        const response = await api.get('/users/paginated', {
          params: {
            page: pageNumber - 1,
            name,
            email,
            status,
            user_profile: role,
          },
        });

        setUsers(response.data.data);
        setPageable(response.data); // response.data ~= IPageable
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [api],
  );

  const blockUser = useCallback(
    async (id: string) => {
      try {
        await api.patch(`/users/${id}/block`);

        loadUsers();
      } catch (err) {
        setLoading(false);
      }
    },
    [loadUsers, api],
  );

  const onSearch = useCallback(
    (name: string, email: string, status: string, role: string) => {
      loadUsers(1, name, email, status, role);
      setShowFilters(false);
    },
    [loadUsers],
  );

  const changeRole = useCallback(
    async (e: RadioChangeEvent) => {
      setRoleSelected(e.target.value);
      try {
        await api.patch(`/profiles/${userSelected?.id}/change-profile`, {
          user_profile: e.target.value,
        });
        setShowChangeRole(false);
        loadUsers();
      } catch (err) {
        setLoading(false);
      }
    },
    [userSelected, api, loadUsers],
  );

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Nome',
      dataIndex: 'name',
      responsive: true,
    },
    {
      id: v4(),
      name: 'E-mail',
      dataIndex: 'email',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Status',
      dataIndex: 'blocked',
      responsive: false,
      renderColumn: (data: IUserList) => {
        return (
          <Tag color={data.blocked ? 'error' : 'success'}>
            {data.blocked ? 'INATIVO' : 'ATIVO'}
          </Tag>
        );
      },
    },
    {
      id: v4(),
      name: 'Perfil',
      dataIndex: 'user_profile',
      responsive: false,
      renderColumn: (data: IUserList) => {
        const profile = data.user_profile as AllowedProfilesType;
        switch (profile) {
          case 'ROOT':
            return <Tag color="#000">Root</Tag>;
          case 'ADMINISTRATOR':
            return <Tag color="cyan">Administrator</Tag>;
          case 'REPORTER':
            return <Tag color="magenta">Reporter</Tag>;
          case 'SUPPORT':
            return <Tag color="lime">Support</Tag>;
          case 'USER':
            return <Tag color="blue">User</Tag>;
          default:
            return <Tag color="default">{data.user_profile}</Tag>;
        }
        // return <Tag color={data.root ? '#000' : 'blue'}>{data.profile}</Tag>;
      },
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        loadUsers();
      },
    },
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
    {
      titleAction: 'Novo Usuário',
      icon: <FaPlus />,
      action: () => {
        history.push('/administration/users/create');
      },
    },
  ];

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <>
      <Card>
        <Table
          title="Usuários"
          actionsConfig={headerActions}
          data={users}
          keyIndex="id"
          tableColumns={tableColumns}
          tableActions={[
            {
              name: 'Alterar Perfil',
              icon: <IdcardOutlined />,
              action: (data: IUserList) => {
                setUserSelected(data);
                setRoleSelected(data.user_profile);
                // loadAllRoles();
                setShowChangeRole(true);
              },
              disabled: ({ user_profile }: IUserList) => {
                return user_profile === 'ROOT';
              },
            },
            {
              name: 'Editar',
              icon: <EditOutlined />,
              action: (data: IUserList) => {
                history.push(`/administration/users/create/${data.id}`);
              },
              disabled: () => {
                return false;
              },
            },
            {
              name: 'Bloquear/Desbloquear',
              icon: <PoweroffOutlined />,
              action: (data: IUserList) => {
                blockUser(data.id);
              },
              disabled: () => {
                return !user.root;
              },
            },
          ]}
          tablePagination={{
            current: pageable.page + 1,
            defaultPageSize: pageable.elementsPerPage,
            totalItems: pageable.totalElements,
          }}
          onChangePage={loadUsers}
          loading={loading}
        />
      </Card>
      <UsersFilters
        closeFilters={() => setShowFilters(false)}
        showFilters={showFilters}
        search={onSearch}
        roles={roles}
      />
      <Drawer
        title="Alterar Role"
        placement="right"
        closable={false}
        width={400}
        onClose={() => setShowChangeRole(false)}
        visible={showChangeRole}
      >
        <h3>{`Selecione a Role do usuário ${userSelected?.name}`}</h3>
        <Radio.Group value={roleSelected} onChange={changeRole}>
          {roles.map(role => (
            <Radio className="radio-role" value={role}>
              {role}
            </Radio>
          ))}
        </Radio.Group>
      </Drawer>
    </>
  );
};

export default User;
