/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';

interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  search(
    id: string,
    status: string,
    response_received_at: string,
    callback_status: string,
  ): void;
}

const { Option } = Select;

const LogIntegrationResponseFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search } = props;

  const statusList = [
    { value: 'WAITING_WEBHOOK', name: 'Aguardando Webhook' },
    { value: 'WAITING_STATUS', name: 'Aguardando Status' },
    { value: 'FINISHED', name: 'Finalizado' },
    { value: 'UNRECOGNIZED', name: 'Não reconhecido' },
  ];

  const callbackStatusList = [
    { value: 'QUEUED', name: 'Na Fila' },
    { value: 'FAILED', name: 'Falha' },
    { value: 'SENT', name: 'Enviada' },
    { value: 'DELIVERED', name: 'Entregue' },
    { value: 'ANSWERED', name: 'Respondida' },
    { value: 'UNRECOGNIZED', name: 'Não reconhecido' },
  ];

  const [form] = useForm();

  const onSearch = useCallback(() => {
    const { id, status, response_received_at, callback_status } =
      form.getFieldsValue();

    search(
      id === undefined ? '' : id,
      status === undefined ? '' : status,
      response_received_at === undefined ? '' : response_received_at,
      callback_status === undefined ? '' : callback_status,
    );
  }, [search, form]);

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      visible={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Col span={24}>
          <Form.Item label="Id da Requisição:" name="id">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="Id"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Status:" name="status">
            <Select
              allowClear
              showSearch
              placeholder="Status"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {statusList.map(status => {
                return (
                  <Option key={status.value} value={status.value}>
                    {status.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Data:" name="response_received_at">
            <Input
              className="dcs-input"
              allowClear
              type="date"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Status do Callback:" name="callback_status">
            <Select
              allowClear
              showSearch
              placeholder="Status do Callback"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {callbackStatusList.map(status => {
                return (
                  <Option key={status.value} value={status.value}>
                    {status.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default LogIntegrationResponseFilters;
