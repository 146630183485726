import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Space,
} from 'antd';
import { FaReply, FaSave, FaTimes } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { notificationDCS } from '../../../utils/notificationDcs';
import { useApi } from '../../../hooks/api';

import './styles.scss';

interface IParams {
  id: string;
}

const HsmApprovedForm: React.FC = () => {
  const history = useHistory();
  const { api } = useApi();
  const { id } = useParams<IParams>();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();
      await api.put(`/hsm-process/${id}/approved`, data);
      notificationDCS({
        type: 'success',
        message: 'Sucesso',
        description: 'HSM aprovado com sucesso!',
        secondsDuration: 2,
        onClose: back,
      });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [back, form, id, api]);

  const loadHsm = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(`hsm/${id}/show`);
      delete response.data.response_date;
      form.setFieldsValue(response.data);

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [api, form, id]);

  useEffect(() => {
    loadHsm();
  }, [loadHsm]);
  return (
    <Card className="card-hsm-approved-form">
      <div className="page-header">
        <h2>Aprovar HSM</h2>
        <Button type="primary" shape="round" icon={<FaReply />} onClick={back}>
          Voltar
        </Button>
      </div>
      <Form layout="vertical" form={form} onFinish={onSave}>
        <Row>
          <Col xl={10} md={24} sm={24} xs={24}>
            <Form.Item
              label="Namespace:"
              name="namespace"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input className="dcs-input" placeholder="ex.: 12376812" />
            </Form.Item>
          </Col>
          <Col xl={10} md={24} sm={24} xs={24}>
            <Form.Item
              label="Nome do Template:"
              name="template_name"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input className="dcs-input" placeholder="ex.: template_teste" />
            </Form.Item>
          </Col>
          <Col xl={4} md={24} sm={24} xs={24}>
            <Form.Item
              label="Data de Resposta do Broker:"
              name="response_date"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <DatePicker className="dcs-input" locale={locale} />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24}>
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={<FaSave />}
                htmlType="submit"
                loading={loading}
              >
                Salvar
              </Button>
              <Button shape="round" icon={<FaTimes />} onClick={back}>
                Cancelar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default HsmApprovedForm;
