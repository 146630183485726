import { Descriptions, Tag } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import IClientsList from '../../interfaces/clients';

interface IProps {
  client?: IClientsList;
}

const ClientDetailInfo: React.FC<IProps> = props => {
  const { client } = props;

  const formatCNPJ = (cnpj: string): string => {
    if (cnpj && cnpj.length === 14) {
      return cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5',
      );
    }
    return cnpj;
  };
  return (
    <Descriptions bordered>
      <Descriptions.Item label="Nome" span={1}>
        {client?.name}
      </Descriptions.Item>
      <Descriptions.Item label="E-mail" span={2}>
        {client?.email}
      </Descriptions.Item>
      <Descriptions.Item label="CNPJ">
        {formatCNPJ(client?.cnpj || '')}
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        <Tag color={client?.enabled ? 'green' : 'error'}>
          {client?.enabled ? 'ATIVO' : 'INATIVO'}
        </Tag>
      </Descriptions.Item>
      <Descriptions.Item label="Telefone">{client?.phone}</Descriptions.Item>
      <Descriptions.Item label="Token">{client?.token}</Descriptions.Item>
      <Descriptions.Item label="Url Callback Padrão" span={1}>
        {client?.url_callback}
      </Descriptions.Item>
      <Descriptions.Item label="Url Callback para Status do HSM" span={2}>
        {client?.url_callback_hsm_status}
      </Descriptions.Item>
      <Descriptions.Item label="Data de Cadastro">
        {format(new Date(client?.createdAt || Date.now()), 'd/MM/yyyy HH:mm')}
      </Descriptions.Item>
      <Descriptions.Item label="Última Atualização">
        {format(new Date(client?.updatedAt || Date.now()), 'd/MM/yyyy HH:mm')}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ClientDetailInfo;
