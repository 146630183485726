import React from 'react';
import { notification } from 'antd';
import { CloseOutlined, CheckOutlined, InfoOutlined } from '@ant-design/icons';

declare type notificationDCSType =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

interface INotifcationDCS {
  type?: notificationDCSType;
  message: string;
  description?: string | React.ReactNode;
  secondsDuration?: number;
  onClose?: () => void;
}

const renderIcon = (type: notificationDCSType) => {
  switch (type) {
    case 'primary':
    case 'success':
      return <CheckOutlined />;
    case 'error':
      return <CloseOutlined />;
    default:
      return <InfoOutlined />;
  }
};

export const notificationDCS = (config: INotifcationDCS): void => {
  const {
    type = 'primary',
    message,
    description,
    secondsDuration = 3,
    onClose,
  } = config;
  notification.info({
    className: `dcs-notification dcs-notification-${type}`,
    message,
    description,
    duration: secondsDuration,
    icon: renderIcon(type),
    onClose,
  });
};
