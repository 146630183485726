/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState } from 'react';
import { Button, Col, Drawer, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { IWaba } from '../../interfaces/waba';

interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  search(
    id: string,
    client: string,
    business_id: string,
    business_name: string,
    waba_id: string,
    waba_name: string,
  ): void;
}

const WabasFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search } = props;
  const [allWabas] = useState<IWaba[]>([]);
  const [filters, setFilters] = useState({
    id: '',
    business_id: '',
    business_name: '',
    waba_id: '',
    waba_name: '',
    client_id: '',
    client: '',
  });

  // const loadWabas = useCallback(async () => {
  //   const response = await api.get('/wabas');
  //   setAllWabas(response.data);
  // }, [api]);

  // useEffect(() => {
  //   loadWabas();
  // }, [loadWabas]);

  const [form] = useForm();

  const onSearch = useCallback(() => {
    const { id, client, business_id, business_name, waba_id, waba_name } =
      form.getFieldsValue();

    search(
      id === undefined ? '' : id,
      client === undefined ? '' : client,
      business_id === undefined ? '' : business_id,
      business_name === undefined ? '' : business_name,
      waba_id === undefined ? '' : waba_id,
      waba_name === undefined ? '' : waba_name,
    );
  }, [form, search]);

  const idArray: { value: string; name: string }[] = [];
  const businessIdArray: { value: string; name: string }[] = [];
  const businessNameArray: { value: string; name: string }[] = [];
  const wabaIdArray: { value: string; name: string }[] = [];
  const wabaNameArray: { value: string; name: string }[] = [];
  // const clientArray: { value: string; name: string }[] = [];

  allWabas.forEach((item: IWaba) => {
    idArray.push({ value: item.id, name: item.id });
    businessIdArray.push({ value: item.business_id, name: item.business_id });
    businessNameArray.push({
      value: item.business_name,
      name: item.business_name,
    });
    wabaIdArray.push({ value: item.waba_id, name: item.waba_id });
    wabaNameArray.push({ value: item.waba_name, name: item.waba_name });
    // clientArray.push({ value: item.client_id, name: item.client.name });
  });

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      visible={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Col span={24}>
          <Form.Item label="Id:" name="id">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Id"
              value={filters.id}
              onChange={e => setFilters({ ...filters, id: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Cliente" name="client">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Nome"
              value={filters.client}
              onChange={e => setFilters({ ...filters, client: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Business - ID" name="business_id">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Id"
              value={filters.business_id}
              onChange={e =>
                setFilters({ ...filters, business_id: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Business - Nome" name="business_name">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Nome do Negócio"
              value={filters.business_name}
              onChange={e =>
                setFilters({ ...filters, business_name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Waba - ID" name="waba_id">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Id"
              value={filters.waba_id}
              onChange={e =>
                setFilters({ ...filters, waba_id: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Waba - Nome" name="waba_name">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Nome"
              value={filters.waba_id}
              onChange={e =>
                setFilters({ ...filters, waba_name: e.target.value })
              }
            />
          </Form.Item>
        </Col>

        <Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default WabasFilters;
