import {
  Button,
  Descriptions,
  Divider,
  Spin,
  Tooltip,
  Modal,
  Input,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { AxiosError } from 'axios';
import { IWaba, IWabaPhone } from '../../interfaces/waba';
import { useApi } from '../../hooks/api';
import { useAuth } from '../../hooks/auth';
import { notificationDCS } from '../../utils/notificationDcs';

interface IProps {
  waba?: IWaba;
  phone?: IWabaPhone;
}

function isAnAxiosError(obj: any): obj is AxiosError {
  return obj.isAxiosError;
}

function isAnFacebookRemoteError(obj: any) {
  return obj?.response?.data?.error?.fbtrace_id;
}

function parseFacebookRemoteErroMessage(obj: any) {
  const fbErrorObj = obj.response.data.error;

  if (fbErrorObj.error_data) {
    return fbErrorObj.error_data.details;
  }
  if (fbErrorObj.error_user_title && fbErrorObj.error_user_msg) {
    return `${fbErrorObj.error_user_title}: ${fbErrorObj.error_user_msg}`;
  }

  return fbErrorObj.message;
}

const WabaDetailPhones: React.FC<IProps> = props => {
  const { user } = useAuth();
  const { waba, phone } = props;
  const { api } = useApi();
  const [modalVisible, setModalVisible] = useState(false);
  const [aboutData, setAboutData] = useState<any>(null);
  const [aboutText, setAboutText] = useState('');
  const [registeringPhone, doRegister] = useState(false);
  const [deregisteringPhone, doDeregister] = useState(false);
  const [requestingVerificationCode, doRequestVerificationCode] =
    useState(false);
  const [verifyingCode, doVerifyCode] = useState(false);
  const isAdmin =
    user.user_profile === 'ADMINISTRATOR' || user.user_profile === 'ROOT';

  const registerPhone = useCallback(async () => {
    doRegister(true);
    try {
      if (waba && phone) {
        const response = await api.get(
          `/wabas/${waba.id}/phones/${phone.id}/register`,
        );
        if (response.data.success) {
          notificationDCS({
            type: 'success',
            message: 'Sucesso',
            description: 'Telefone conectado com sucesso!',
            secondsDuration: 2,
            onClose: () => {
              window.location.reload();
            },
          });
        } else {
          notificationDCS({
            type: 'warning',
            message: 'Falha',
            description: 'Não foi possível conectar o telefone',
            secondsDuration: 5,
          });
        }
      }
    } catch (err) {
      let message;
      if (isAnAxiosError(err) && isAnFacebookRemoteError(err)) {
        message = parseFacebookRemoteErroMessage(err);
      }
      notificationDCS({
        type: 'error',
        message: 'Falha',
        description: message || JSON.stringify(err),
        secondsDuration: 5,
      });
    } finally {
      doRegister(false);
    }
  }, [waba, phone, api]);

  const deregisterPhone = useCallback(async () => {
    if (!window.confirm('Tem certeza que deseja desconectar este telefone?')) {
      return;
    }

    doDeregister(true);
    try {
      if (waba && phone) {
        const response = await api.get(
          `/wabas/${waba.id}/phones/${phone.id}/deregister`,
        );
        if (response.data.success) {
          notificationDCS({
            type: 'success',
            message: 'Sucesso',
            description: 'Telefone excluído com sucesso!',
            secondsDuration: 2,
            onClose: () => {
              window.location.reload();
            },
          });
        } else {
          notificationDCS({
            type: 'warning',
            message: 'Falha',
            description: 'Não foi possível excluír o telefone',
            secondsDuration: 5,
          });
        }
      }
    } catch (err) {
      let message;
      if (isAnAxiosError(err) && isAnFacebookRemoteError(err)) {
        message = parseFacebookRemoteErroMessage(err);
      }
      notificationDCS({
        type: 'error',
        message: 'Falha',
        description: message || JSON.stringify(err),
        secondsDuration: 5,
      });
    } finally {
      doDeregister(false);
    }
  }, [waba, phone, api]);

  const requestVerificationCode = useCallback(
    async (code_method: string) => {
      doRequestVerificationCode(true);
      try {
        if (waba && phone) {
          const response = await api.post(
            `/wabas/${waba.id}/phones/${phone.id}/requestVerificationCode`,
            { phone_id: phone.id, code_method },
          );
          if (response.data.success) {
            notificationDCS({
              type: 'success',
              message: 'Sucesso',
              description: 'Código enviado com sucesso!',
              secondsDuration: 2,
              onClose: () => {
                window.location.reload();
              },
            });
          } else {
            notificationDCS({
              type: 'warning',
              message: 'Falha',
              description: 'Não foi possível enviar o código',
              secondsDuration: 5,
            });
          }
        }
      } catch (err) {
        let message;
        if (isAnAxiosError(err) && isAnFacebookRemoteError(err)) {
          message = parseFacebookRemoteErroMessage(err);
        }
        notificationDCS({
          type: 'error',
          message: 'Falha',
          description: message || JSON.stringify(err),
          secondsDuration: 5,
        });
      } finally {
        doRequestVerificationCode(false);
      }
    },
    [waba, phone, api],
  );

  const verifyCode = useCallback(
    async (code: string) => {
      doVerifyCode(true);
      try {
        if (waba && phone) {
          const response = await api.post(
            `/wabas/${waba.id}/phones/${phone.id}/verifyCode`,
            { phone_id: phone.id, code },
          );
          if (response.data.success) {
            notificationDCS({
              type: 'success',
              message: 'Sucesso',
              description: 'Código validado com sucesso!',
              secondsDuration: 2,
              onClose: () => {
                window.location.reload();
              },
            });
          } else {
            notificationDCS({
              type: 'warning',
              message: 'Falha',
              description: 'Não foi possível validar o código',
              secondsDuration: 5,
            });
          }
        }
      } catch (err) {
        let message;
        if (isAnAxiosError(err) && isAnFacebookRemoteError(err)) {
          message = parseFacebookRemoteErroMessage(err);
        }
        notificationDCS({
          type: 'error',
          message: 'Falha',
          description: message || JSON.stringify(err),
          secondsDuration: 5,
        });
      } finally {
        doVerifyCode(false);
      }
    },
    [waba, phone, api],
  );

  const validateCode = () => {
    const code = window.prompt('Código?');
    if (code) {
      verifyCode(code);
    }
  };

  const changeWhatsAppAbout = useCallback(
    async (about: string) => {
      if (waba && phone) {
        const response = await api.patch(
          `/wabas/${phone.id}/updateWhatsappDescription`,
          { phone_id: phone.id, about },
        ).catch(() => {
          notificationDCS({
            type: 'warning',
            message: 'Falha',
            description: 'Não foi possível modificar a mensagem de recado',
            secondsDuration: 5,
          });
        });

        if (response && response.data.success) {
          notificationDCS({
            type: 'success',
            message: 'Sucesso',
            description: 'Mensagem de recado modificado com sucesso!',
            secondsDuration: 3,
            onClose: () => {
              window.location.reload();
            },
          });
        }
      }
    },
    [waba, phone, api],
  );


  const getWhatsAppAbout = useCallback(async () => {
    if (waba && phone?.status === 'CONNECTED') {
      const response = await api.get(`/wabas/${phone.id}/about`);
      setAboutData(response.data);
    }
  }, [waba, phone, api]);

  useEffect(() => {
    getWhatsAppAbout();
  }, [getWhatsAppAbout]);

  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label="ID" span={1}>
          {phone?.id}
        </Descriptions.Item>
        <Descriptions.Item label="Nome" span={1}>
          {phone?.verified_name}
        </Descriptions.Item>
        <Descriptions.Item label="Telefone" span={1}>
          {phone?.display_phone_number}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              Status de verificação &nbsp;
              {phone?.code_verification_status === 'NOT_VERIFIED' ? (
                <Tooltip title="Número não verificado. Clique no botão de verificar ao lado para iniciar verificação de posse.">
                  <ExclamationCircleTwoTone twoToneColor="red" />
                </Tooltip>
              ) : null}
            </>
          }
          span={1}
        >
          {phone?.code_verification_status}
          <br />
          {isAdmin && phone?.code_verification_status === 'NOT_VERIFIED' ? (
            <Spin
              spinning={requestingVerificationCode || verifyingCode}
              size="large"
            >
              <Button
                type="primary"
                onClick={() => requestVerificationCode('SMS')}
              >
                Verificar por SMS
              </Button>
              <Button
                type="primary"
                onClick={() => requestVerificationCode('VOICE')}
              >
                Verificar por VOZ
              </Button>
              <Button type="primary" onClick={validateCode}>
                Validar Código
              </Button>
            </Spin>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              Status do telefone &nbsp;
              {phone?.status === 'PENDING' ? (
                <Tooltip title="Número desconectado. Clique no botão de registrar ao lado para conectar o número ao Whatsapp Cloud.">
                  <ExclamationCircleTwoTone />
                </Tooltip>
              ) : null}
              {isAdmin && phone?.status === 'CONNECTED' ? (
                <Tooltip title="Número conectado. Clique no botão de desconectar ao lado se desejar removê-lo do Whatsapp Cloud.">
                  <ExclamationCircleTwoTone />
                </Tooltip>
              ) : null}
            </>
          }
          span={1}
        >
          {phone?.status}
          <br />
          {phone?.code_verification_status === 'VERIFIED' &&
          phone?.status === 'PENDING' ? (
            <Spin spinning={registeringPhone} size="large">
              <Button type="primary" onClick={registerPhone}>
                Registrar
              </Button>
            </Spin>
            ) : null}
          {isAdmin && phone?.status === 'CONNECTED' ? (
            <Spin spinning={deregisteringPhone} size="large">
              <Button type="primary" onClick={deregisterPhone}>
                Desconectar
              </Button>
            </Spin>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label={ <>
          Status do nome de exibição &nbsp;
          <Tooltip title="Status do nome de exibição atual">
                  <ExclamationCircleTwoTone />
                </Tooltip>
        </>} span={1}>
          {phone?.name_status}
        </Descriptions.Item>
        {isAdmin && phone?.new_name_status !== 'NONE' ? 
        <Descriptions.Item label={ <>
          Status do Novo nome de exibição &nbsp;
          <Tooltip title="Ao ser aprovada a alteração do nome, clique no botão para registrá-lo.">
                  <ExclamationCircleTwoTone />
                </Tooltip>
        </>} span={1}>
        {phone?.new_name_status}
        {phone?.new_name_status === 'APPROVED' ? (
            <Spin spinning={registeringPhone} size="large">
              <Button type="primary" onClick={registerPhone}>
                Registrar
              </Button>
            </Spin>
        ) : null}
        </Descriptions.Item> : null}
        <Descriptions.Item label="Classificação" span={1}>
          {phone?.quality_rating}
        </Descriptions.Item>
        <Descriptions.Item label={
          <>
          Mensagem de recado &nbsp;
          <Tooltip title="Mensagem não pode ser vazia e o número máximo de caractreres são de 139 letras.">
          <ExclamationCircleTwoTone />
      </Tooltip>
      </>
        } span={1}>
          {aboutData}
          {isAdmin && phone?.status === 'CONNECTED' ? (
            <>
              <Modal
                title="Adicionar recado"
                open={modalVisible}
                onOk={() => {
                  changeWhatsAppAbout(aboutText);
                  setModalVisible(false);
                  setAboutText('');
                }}
                onCancel={() => {
                  setModalVisible(false);
                  setAboutText('');
                }}
              >
                <Input
                  placeholder="Novo recado"
                  value={aboutText}
                  onChange={e => setAboutText(e.target.value)}
                />
              </Modal>
              <Button
                type="primary"
                onClick={() => setModalVisible(true)}
                disabled={phone?.status !== 'CONNECTED'}
              >
                Alterar recado
              </Button>
            </>
          ) : null}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
    </>
  );
};

export default WabaDetailPhones;
