import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Row,
  Space,
  Tag,
  Form,
  Input,
  InputRef,
} from 'antd';

import './styles.scss';
import {
  SaveOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { useLocation } from 'react-router-dom';
import man from '../../assets/man.png';
import { useAuth } from '../../hooks/auth';
import { notificationDCS } from '../../utils/notificationDcs';
import { useApi } from '../../hooks/api';

// interface InputRef {
//   input: HTMLInputElement | null;
// }

const Profile: React.FC = () => {
  const { user } = useAuth();
  const { api } = useApi();
  const [form] = useForm();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [validations, setValidations] = useState({
    minLength: false,
    hasNumber: false,
    hasUppercase: false,
    hasLowercase: false,
    hasSpecialChar: false,
  });
  const [confirmPassword, setConfirmPassword] = useState('');

  const location = useLocation();
  // const passwordRef = useRef<InputRef | null>(null);
  const passwordRef = useRef<InputRef>(null);

  // const passwordRef: Ref<InputRef> | undefined = useRef<HTMLInputElement | null>(null);

  const updatePassword = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();
      await api.put('/password/change', data);
      notificationDCS({
        type: 'success',
        message: 'Sucesso',
        description: 'Senha alterada com sucesso!',
        secondsDuration: 2,
      });
      setLoading(false);
      form.resetFields();
      setShowChangePassword(false);
    } catch {
      setLoading(false);
    }
  }, [form, api]);

  useEffect(() => {
    if (location.search === '?reset_password=true') {
      setShowChangePassword(true);
      passwordRef.current?.focus();
    }
  }, [location]);

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    // Validações
    const validationsUpdate = {
      minLength: newPassword.length >= 8,
      hasNumber: /\d/.test(newPassword),
      hasUppercase: /[A-Z]/.test(newPassword),
      hasLowercase: /[a-z]/.test(newPassword),
      hasSpecialChar: /[!@#$%^&*]/.test(newPassword),
    };

    setValidations(validationsUpdate);
  };

  const validationMessages = {
    minLength: 'Mínimo de 8 caracteres',
    hasNumber: 'Incluir ao menos um número',
    hasUppercase: 'Incluir ao menos uma letra maiúscula',
    hasLowercase: 'Incluir ao menos uma letra minúscula',
    hasSpecialChar: 'Incluir ao menos um caractere especial',
  };

  const areAllValidationsPassing = () => {
    const isMinLengthValid = password.length >= 8;
    const hasNumberValid = /[0-9]/.test(password);
    const hasUppercaseValid = /[A-Z]/.test(password);
    const confirmPasswordValid = password === confirmPassword;

    return (
      isMinLengthValid &&
      hasNumberValid &&
      hasUppercaseValid &&
      confirmPasswordValid
    );
  };

  return (
    <>
      <Row>
        <Col lg={6} md={6} sm={0} xs={0} />
        <Col lg={12} md={12} sm={24} xs={24}>
          <Card className="card-user-profile">
            <div className="avatar-container">
              <img src={man} alt="User" />
              <span className="user-name">{user.name}</span>
            </div>
            <Divider orientation="left">Dados</Divider>
            <div>
              <div className="profile-item">
                <strong>E-mail: </strong>
                <span>{user.email}</span>
              </div>
              <div className="profile-item">
                <strong>Role: </strong>
                <Tag color={user.root ? '#000' : 'blue'} className="user-role">
                  {user.user_profile}
                </Tag>
              </div>
            </div>
            <Divider />
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={() => setShowChangePassword(true)}
            >
              Alterar Senha
            </Button>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={0} xs={0} />
      </Row>
      <Drawer
        title="Alterar Senha"
        placement="right"
        closable={false}
        width={400}
        onClose={() => setShowChangePassword(false)}
        open={showChangePassword}
      >
        <Form layout="vertical" form={form} onFinish={updatePassword}>
          <Form.Item
            label="Senha atual"
            name="oldPassword"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input.Password
              ref={passwordRef}
              className="dcs-input dcs-password"
            />
          </Form.Item>

          <Form.Item
            label="Nova senha"
            name="password"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input.Password
              className="dcs-input dcs-password"
              onChange={handlePasswordChange}
            />
          </Form.Item>
          <div style={{ marginBottom: '20px' }}>
            {password.length > 0 &&
              (Object.keys(validations) as Array<keyof typeof validations>).map(
                key => (
                  <div key={key}>
                    {validations[key] ? (
                      <span style={{ color: 'green', marginLeft: '20px' }}>
                        <CheckCircleOutlined
                          style={{ color: 'green', marginRight: '5px' }}
                        />
                        {validationMessages[key]}
                      </span>
                    ) : (
                      <span style={{ color: 'red', marginLeft: '20px' }}>
                        <CloseCircleOutlined
                          style={{ color: 'red', marginRight: '5px' }}
                        />
                        {validationMessages[key]}
                      </span>
                    )}
                  </div>
                ),
              )}
          </div>

          <Form.Item
            label="Confirmar nova senha"
            name="confirmPassword"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input.Password
              className="dcs-input dcs-password"
              onChange={(e: any) => setConfirmPassword(e.target.value)}
            />
          </Form.Item>
          {password !== confirmPassword && confirmPassword.length > 0 && (
            <span style={{ color: 'red', marginLeft: '20px' }}>
              <CloseCircleOutlined
                style={{ color: 'red', marginRight: '5px' }}
              />
              As senhas não são iguais
            </span>
          )}
          <Divider />
          <Space>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<SaveOutlined />}
              htmlType="submit"
              loading={loading}
              disabled={!areAllValidationsPassing()}
            >
              Alterar
            </Button>
            <Button
              shape="round"
              size="large"
              icon={<CloseOutlined />}
              onClick={() => setShowChangePassword(false)}
            >
              Cancelar
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
};

export default Profile;
