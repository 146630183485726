import React, { useCallback, useEffect, useState } from 'react';
import { Col, Flex, Row, Spin } from 'antd';
import {
  FaCogs,
  FaCommentDollar,
  FaUsers,
  FaUsersCog,
  FaWhatsapp,
  FaRocketchat,
} from 'react-icons/fa';
import { PiFlowArrowBold } from 'react-icons/pi';

import { useHistory } from 'react-router-dom';
import CardStatistics from '../../components/CardStatistics';
import { useApi } from '../../hooks/api';
import { useAuth } from '../../hooks/auth';
import IStatisticsDashboard from '../../interfaces/statisticsDashboard';

const Dashboard: React.FC = () => {
  const { api } = useApi();
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [statistics, setStatistics] = useState<IStatisticsDashboard>({
    clients: {
      active: 0,
      inactive: 0,
      total: 0,
    },
    brokers: {
      active: 0,
      inactive: 0,
      total: 0,
    },
    medias: {
      active: 0,
      inactive: 0,
      total: 0,
    },
    integrations: {
      active: 0,
      inactive: 0,
      total: 0,
    },
    licenses: {
      active: 0,
      inactive: 0,
      total: 0,
    },
    users: {
      active: 0,
      inactive: 0,
      total: 0,
    },
    wabas: 0,
  });

  const loadStatistics = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/statistics/dashboard');

    setStatistics(response.data);
    setLoading(false);
  }, [api]);

  useEffect(() => {
    if (user !== undefined) {
      if (user.reset_password) {
        history.push(`/profile?reset_password=true`);
      }
    }

    loadStatistics();
  }, [loadStatistics, user, history]);

  const cardData = [
    {
      icon: FaUsersCog,
      name: 'Usuários',
      statistic: statistics.users,
      link: '/administration/users',
    },
    {
      icon: FaUsers,
      name: 'Clientes',
      statistic: statistics.clients,
      link: '/clients',
    },
    {
      icon: PiFlowArrowBold,
      name: 'Integrações',
      statistic: statistics.integrations,
      link: '/integrations',
    },
    {
      icon: FaCogs,
      name: 'Brokers',
      statistic: statistics.brokers,
      link: '/integrations/config',
    },
    {
      icon: FaRocketchat,
      name: 'Canais',
      statistic: statistics.medias,
      link: '/integrations/config',
    },
    {
      icon: FaCommentDollar,
      name: 'Licenças',
      statistic: statistics.licenses,
    },
    {
      icon: FaWhatsapp,
      name: 'Wabas',
      statistic: statistics.wabas,
      link: '/wabas',
    },
  ];

  return (
    <div>
      <Row>
        {loading ? (
          <Flex
            justify="center"
            align="center"
            flex={1}
            style={{ minHeight: '50vh' }}
          >
            <Spin />
          </Flex>
        ) : (
          cardData.map((card, index) => (
            <Col
              key={index}
              xl={6}
              md={12}
              sm={24}
              xs={24}
              style={{ alignItems: 'flex-start' }}
            >
              <CardStatistics
                icon={card.icon}
                name={card.name}
                statistc={card.statistic}
                link={card?.link}
              />
            </Col>
          ))
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
