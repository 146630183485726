export const translateDescriptionNotification = (value: string): string => {
  if (value === 'Credentials already in use!') {
    return 'As credenciais já estão sendo utilizadas!';
  }
  if (
    value ===
    'Integration media from indirect client must be equal partner integration media!'
  ) {
    return 'O canal do indireto deve ser igual ao canal da integração do parceiro!';
  }

  return value;
};
