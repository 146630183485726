import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Select,
} from 'antd';
import { FaReply, FaSave, FaTimes } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import './styles.scss';
import { useForm } from 'antd/lib/form/Form';
import { notificationDCS } from '../../../utils/notificationDcs';
import { useApi } from '../../../hooks/api';

interface IParams {
  id: string;
}

interface ClientOption {
  name: string;
  clientId: string;
}

const { Option } = Select;

const WabaForm: React.FC = () => {
  const history = useHistory();
  const [form] = useForm();
  const { api } = useApi();
  const { id } = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [names, setNames] = useState<ClientOption[]>([]);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      notificationDCS({
        type: 'warning',
        message: 'Alerta',
        description: 'Por favor, preencha todos os campos obrigatórios!',
        secondsDuration: 2,
      });
    }
  }

  const loadAllNames = useCallback(async () => {
    const clientResponse = await api.get('/clients');
    const clients = clientResponse.data;

    const names = clients.map((client: { id: string; name: string }) => {
      return { name: client.name, clientId: client.id };
    });

    setNames(names);
  }, [api]);

  const loadWabaDetail = useCallback(async () => {
    const response = await api.get(`/wabas/${id}`);
    form.setFieldsValue(response.data);
  }, [id, form, api]);

  useEffect(() => {
    setLoadingProfile(true);
    loadAllNames();
    setLoadingProfile(false);
  }, [loadAllNames]);
  useEffect(() => {
    if (id !== undefined) {
      loadWabaDetail();
    }
  }, [id, loadWabaDetail]);

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();
      setLoading(false);
      if (id) {
        await api.put(`/wabas/${id}`, data);
      } else {
        await api.post('/wabas', data);
      }
      setLoading(false);
      notificationDCS({
        type: 'success',
        message: 'Sucesso',
        description: 'Waba cadastrado com sucesso!',
        secondsDuration: 2,
        onClose: back,
      });
      setDisableButton(true);
    } catch {
      notificationDCS({
        type: 'error',
        message: 'Erro ao salvar',
        description: 'Aconteceu algo inesperado, tente novamente mais tarde!',
        secondsDuration: 2,
        onClose: back,
      });
      setLoading(false);
    }
  }, [back, form, api]);

  return loadingProfile ? (
    <div className="dcs-spin">
      <Spin />
    </div>
  ) : (
    <Card className="card-user-form">
      <div className="page-header">
        <h2>{id !== undefined ? 'Atualizando Waba' : 'Novo WABA'}</h2>
        <Button type="primary" shape="round" icon={<FaReply />} onClick={back}>
          Voltar
        </Button>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSave}
        initialValues={{
          reset_password: true,
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Cliente:"
              name="client_id"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Cliente ID"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
              >
                {names.map(name => (
                  <Option key={name.clientId} value={name.clientId}>
                    {name.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Business - ID:"
              name="business_id"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input
                className="dcs-input"
                type="business_id"
                placeholder="ex.: 99911254879"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Business - Nome:"
              name="business_name"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input
                className="dcs-input"
                type="business_name"
                placeholder="ex.: Joao Silva Ltda"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Waba - ID:"
              name="waba_id"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input
                className="dcs-input"
                type="waba_id"
                placeholder="ex.: 1130341751041442"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Waba - Nome:"
              name="waba_name"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input
                className="dcs-input"
                type="waba_name"
                placeholder="ex.: Joao Silva - Comercial"
              />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24}>
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={<FaSave />}
                htmlType="submit"
                loading={loading}
                onClick={validateForm}
                disabled={disableButton}
              >
                Salvar
              </Button>
              <Button shape="round" icon={<FaTimes />} onClick={back}>
                Cancelar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default WabaForm;
