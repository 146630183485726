import React, { useCallback, useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  TeamOutlined,
  AppstoreAddOutlined,
  DashboardOutlined,
  ConsoleSqlOutlined,
  SettingOutlined,
  WhatsAppOutlined,
  BankOutlined,
  ApiOutlined,
  RiseOutlined,
  FallOutlined,
  ScheduleOutlined,
  FileExcelOutlined,
  AlignCenterOutlined,
  LineChartOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import { MdOutlineFiberNew, MdNewReleases } from 'react-icons/md';

import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/logo.png';
import logoMini from '../../assets/logo-mini.png';
import AppHeader from '../../components/Header';
import Routes from '../../routes';
import { useAuth } from '../../hooks/auth';
import { ISidebarMenuItem } from '../../interfaces/sidebarMenuItem';
import AllowedProfilesType from '../../types/allowedProfile';
import packageJson from '../../../package.json';
import LineForEnv from '../../helpers/LineForEnv';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const AppContainer: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [collapse, setCollapse] = useState(false);

  const navigate = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const environment = process.env.REACT_APP_NODE_ENV || '';
  const environmentLabels: { [key: string]: string } = {
    qa: 'QA',
    staging: 'HML',
  };

  const envLabel = environmentLabels[environment] || '';
  const [menuItems] = useState<ISidebarMenuItem[]>([
    {
      type: 'menu',
      menuItem: {
        action: () => navigate('/dashboard'),
        icon: <DashboardOutlined />,
        key: 'dashboard',
        title: 'Dashboard',
        allowedProfiles: ['ALL'],
      },
    },
    {
      type: 'menu',
      menuItem: {
        action: () => navigate('/accounts'),
        icon: <TeamOutlined />,
        key: 'accounts',
        title: 'Minhas Contas',
        allowedProfiles: ['USER'],
        specificProfileOnly: true,
      },
    },
    {
      type: 'menu',
      menuItem: {
        action: () => navigate('/wabas'),
        icon: <WhatsAppOutlined />,
        key: 'wabas',
        title: 'WhatsApp Business Account',
        allowedProfiles: ['ADMINISTRATOR', 'SUPPORT', 'USER'],
      },
    },
    {
      type: 'submenu',
      subMenu: {
        icon: <BankOutlined />,
        key: 'administration',
        title: 'Administração',
        allowedProfiles: ['ADMINISTRATOR'],
        items: [
          {
            title: 'Configurações Gerais',
            action: () => navigate('/administration/general-settings'),
            key: 'administration/general-settings',
            icon: <SettingOutlined />,
            allowedProfiles: ['ADMINISTRATOR'],
          },
          {
            action: () => navigate('/administration/users'),
            icon: <TeamOutlined />,
            key: 'users',
            title: 'Usuários',
            allowedProfiles: ['ROOT', 'ADMINISTRATOR'],
          },
        ],
      },
    },
    {
      type: 'menu',
      menuItem: {
        action: () => navigate('/clients'),
        icon: <TeamOutlined />,
        key: 'clients',
        title: 'Clientes',
        allowedProfiles: ['ADMINISTRATOR'],
      },
    },
    {
      type: 'submenu',
      subMenu: {
        icon: <AppstoreAddOutlined />,
        key: 'subMenuItegration',
        title: 'Integração',
        allowedProfiles: ['ADMINISTRATOR'],
        items: [
          {
            title: 'Configuração',
            action: () => navigate('/integrations/config'),
            key: 'integrations/config',
            icon: <SettingOutlined />,
            allowedProfiles: ['ALL'],
          },
          {
            title: 'Integrações',
            action: () => navigate('/integrations'),
            key: 'integrations',
            icon: <ApiOutlined />,
            allowedProfiles: ['ALL'],
          },
        ],
      },
    },
    {
      type: 'submenu',
      subMenu: {
        icon: <ConsoleSqlOutlined />,
        key: 'subMenuLogs',
        title: 'Logs',
        allowedProfiles: ['ADMINISTRATOR'],
        items: [
          {
            title: 'Interações das Requisições',
            action: () => navigate('/logs/interaction-request'),
            key: 'logs/interaction-request',
            icon: <RiseOutlined />,
            allowedProfiles: ['ALL'],
          },
          {
            title: 'Interações das Respostas',
            action: () => navigate('/logs/interaction-response'),
            key: 'logs/interaction-response',
            icon: <FallOutlined />,
            allowedProfiles: ['ALL'],
          },
        ],
      },
    },
    {
      type: 'submenu',
      subMenu: {
        icon: <SettingOutlined />,
        key: 'subMenuReports',
        title: 'Relatórios',
        allowedProfiles: ['ADMINISTRATOR', 'REPORTER'],
        items: [
          {
            title: 'Agendamentos',
            action: () => navigate('/reports-config/schedules'),
            key: 'reports-config/schedules',
            icon: <ScheduleOutlined />,
            allowedProfiles: ['ALL'],
          },
          {
            title: 'Relatório sintético',
            action: () => navigate('/billing-reports'),
            key: 'billing-reports',
            icon: <FileExcelOutlined />,
            allowedProfiles: ['ALL'],
          },
          {
            title: 'Relatório analítico',
            action: () => navigate('/analitics-report'),
            key: 'analitic-reports',
            icon: <LineChartOutlined />,
            allowedProfiles: ['ALL'],
          },
          {
            title: 'Gestão de consumo',
            action: () => navigate('/consumption-report'),
            key: 'consumption-reports',
            icon: <BarChartOutlined />,
            allowedProfiles: ['ALL'],
          },
        ],
      },
    },
    {
      type: 'submenu',
      subMenu: {
        title: 'Gerenciamento de HSM',
        icon: <WhatsAppOutlined />,
        key: 'hsm-manager',
        allowedProfiles: ['ADMINISTRATOR', 'SUPPORT'],
        items: [
          {
            title: 'Templates',
            action: () => navigate('/hsm'),
            key: 'hsm',
            icon: <AlignCenterOutlined />,
            allowedProfiles: ['ALL'],
          },
          // {
          //   title: 'Logs',
          //   action: () => navigate('/hsm-logs'),
          //   key: 'hsm-logs',
          //   icon: <ConsoleSqlOutlined />,
          //   allowedProfiles: ['ALL'],
          // },
        ],
      },
    },
  ]);

  return (
    <Layout className="app-layout">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapse}
        className="sidebar-wd"
        width={330}
        breakpoint="lg"
        onCollapse={() => {
          setCollapse(!collapse);
        }}
        style={{ backgroundColor: '#fff' }}
      >
        <LineForEnv />
        <div className="logo">
          <img src={collapse ? logoMini : logo} alt="logo" />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={[location.pathname.replace('/', '')]}
        >
          {menuItems.map(item => {
            if (item.type === 'menu') {
              if (
                item.menuItem?.allowedProfiles?.includes('ALL') ||
                item.menuItem?.allowedProfiles?.includes(
                  user.user_profile as AllowedProfilesType,
                ) ||
                (user.root && !item.menuItem?.specificProfileOnly)
              ) {
                return (
                  <Menu.Item
                    key={item.menuItem?.key}
                    icon={item.menuItem?.icon}
                    onClick={item.menuItem?.action}
                  >
                    {item.menuItem?.title}
                  </Menu.Item>
                );
              }
            } else if (item.type === 'submenu') {
              if (
                item.subMenu?.allowedProfiles?.includes('ALL') ||
                item.subMenu?.allowedProfiles?.includes(
                  user.user_profile as AllowedProfilesType,
                ) ||
                user.root
              ) {
                return (
                  <SubMenu
                    key={item.subMenu?.key}
                    icon={item.subMenu?.icon}
                    title={item.subMenu?.title}
                  >
                    {item.subMenu?.items.map(subMenu => {
                      if (
                        subMenu.allowedProfiles?.includes('ALL') ||
                        subMenu.allowedProfiles?.includes(
                          user.user_profile as AllowedProfilesType,
                        ) ||
                        (user.root && !subMenu?.specificProfileOnly)
                      ) {
                        return (
                          <Menu.Item
                            key={subMenu.key}
                            onClick={subMenu.action}
                            icon={subMenu.icon}
                          >
                            {subMenu.title}
                          </Menu.Item>
                        );
                      }
                      return null;
                    })}
                  </SubMenu>
                );
              }
            }
            return null;
          })}
        </Menu>
        <div className="sidebar-footer">
          {!collapse ? (
            <p>
              {envLabel
                ? `DCS-${envLabel}-${packageJson.version}`
                : `DCS-${packageJson.version}`}
            </p>
          ) : (
            <small>
              {envLabel
                ? `${envLabel}-${packageJson.version}`
                : `${packageJson.version}`}
            </small>
          )}
        </div>
      </Sider>
      <Layout className="site-layout">
        <AppHeader collapse={() => setCollapse(!collapse)} />

        <Content>
          <div className="app-content">
            <Routes />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppContainer;
