import { Card, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaFilter, FaPlus, FaSyncAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { EditOutlined, PoweroffOutlined } from '@ant-design/icons';
import Table from '../../components/Table';
import { IActionHeaderConfig, ITableColumn } from '../../interfaces/table';

import { useAuth } from '../../hooks/auth';
import IIntegrationsList from '../../interfaces/integrationsList';
import { IPageable, DEFAULT_PAGEABLE } from '../../interfaces/pageable';
import { useApi } from '../../hooks/api';
import IntegrationFilters from '../../components/IntegrationFilters';

const Integrations: React.FC = () => {
  const { user } = useAuth();
  const { api } = useApi();
  const history = useHistory();
  const [integrations, setIntegrations] = useState<IIntegrationsList[]>([]);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    client_id: '',
    integration_id: '',
    integration_name: '',
    media_name: '',
    broker: '',
    account: '',
    status: '',
  });

  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);

  const loadIntegrations = useCallback(
    async (
      pageNumber = 1,
      id = filters.integration_id,
      status = filters.status || 'ENABLED',
      name = filters.integration_name,
      media_name = filters.media_name,
      client_id = filters.client_id,
      broker = filters.broker,
      account = filters.account,
    ) => {
      try {
        setLoading(true);
        const response = await api.get('/integrations/paginated', {
          params: {
            page: pageNumber - 1,
            id,
            client_id,
            name,
            media_name,
            status,
            broker,
            account,
          },
        });
        setIntegrations(response.data.data);
        setPageable(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [
      api,
      filters.account,
      filters.broker,
      filters.client_id,
      filters.integration_id,
      filters.integration_name,
      filters.media_name,
      filters.status,
    ],
  );

  const onSearch = useCallback(
    (
      id: string,
      status: string,
      name: string,
      media_name: string,
      client_id: string,
      broker: string,
      account: string,
    ) => {
      setFilters({
        integration_id: id,
        status,
        integration_name: name,
        media_name,
        client_id,
        broker,
        account,
      });

      loadIntegrations(
        1,
        id,
        status,
        name,
        media_name,
        client_id,
        broker,
        account,
        // true,
      );
      setShowFilters(false);
    },
    [loadIntegrations],
  );

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Cliente',
      dataIndex: 'client_name',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Integração ID',
      dataIndex: 'id',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Nome da Integração',
      dataIndex: 'name',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Canal',
      dataIndex: 'media_name',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Broker',
      dataIndex: 'broker',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Conta/Telefone',
      dataIndex: 'account',
      responsive: false,
      renderColumn: (data: IIntegrationsList) => {
        return data.media_name === 'WHATSAPP' || data.media_name === 'SMS'
          ? data.account.replace(/[\s\W-]/g, '')
          : data.account;
      },
    },
    {
      id: v4(),
      name: 'Status',
      dataIndex: 'status',
      responsive: false,
      renderColumn: (data: IIntegrationsList) => {
        return (
          <Tag color={data.status === 'ENABLED' ? 'green' : 'warning'}>
            {data.status === 'ENABLED' ? 'ATIVO' : 'INATIVO'}
          </Tag>
        );
      },
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        loadIntegrations();
      },
    },
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
    {
      titleAction: 'Nova Integração',
      icon: <FaPlus />,
      action: () => {
        history.push('/integrations/create');
      },
    },
  ];

  useEffect(() => {
    loadIntegrations();
  }, [loadIntegrations]);

  return (
    <Card>
      <Table
        title="Integrações"
        actionsConfig={headerActions}
        data={integrations}
        keyIndex="id"
        tableColumns={tableColumns}
        tableActions={[
          {
            name: 'Editar',
            icon: <EditOutlined />,
            action: (data: IIntegrationsList) => {
              history.push(`/integrations/create/${data.id}`);
            },
            disabled: () => {
              return !['ADMINISTRATOR', 'ROOT'].includes(user.user_profile);
            },
          },
          {
            name: 'Ativar/Desativar',
            icon: <PoweroffOutlined />,
            action: (data: IIntegrationsList) => {
              history.push(
                `/integrations/${
                  data.status === 'ENABLED' ? 'disabled' : 'enabled'
                }/${data.id}`,
              );
            },
            disabled: () => {
              return !['ADMINISTRATOR', 'ROOT'].includes(user.user_profile);
            },
          },
        ]}
        tablePagination={{
          current: pageable.page + 1,
          defaultPageSize: pageable.elementsPerPage,
          totalItems: pageable.totalElements,
        }}
        onChangePage={loadIntegrations}
        loading={loading}
      />
      <IntegrationFilters
        closeFilters={() => setShowFilters(false)}
        showFilters={showFilters}
        search={onSearch}
      />
    </Card>
  );
};

export default Integrations;
