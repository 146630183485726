/* eslint-disable react/jsx-indent */
/* eslint-disable no-restricted-syntax */
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';

import { Card, Spin } from 'antd';
import { FaFilter } from 'react-icons/fa';

import { v4 } from 'uuid';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { useApi } from '../../hooks/api';

import './styles.scss';
import Table from '../../components/Table';
import { IActionHeaderConfig, ITableColumn } from '../../interfaces/table';
import IClientsList from '../../interfaces/clients';
import ClientsConsumptionReportFilters from '../../components/ClientsConsumptionReportFilters';
import { DEFAULT_PAGEABLE, IPageable } from '../../interfaces/pageable';

interface IMediaConsumption {
  amount: number;
  total: number;
}

interface IMediaWhatsApp {
  utilities: IMediaConsumption;
  auth: IMediaConsumption;
  marketing: IMediaConsumption;
}

interface IClientsConsumption {
  client_id: string;
  client_name: string;
  integration_name: string;
  billing_date: string;
  invoice_amount: number;
  sms?: IMediaConsumption;
  active_wpp?: IMediaWhatsApp;
  receptive_wpp?: IMediaConsumption;
}

interface IClientsConsumptionTable {
  client_id: string;
  client_name: string;
  integration_name: string;
  billing_date: string;
  invoice_amount: number;
  sms: IMediaConsumption | undefined;
  active_wpp: IMediaWhatsApp | undefined;
  receptive_wpp: IMediaConsumption | undefined;
}

const ClientsConsumptionReport: React.FC = () => {
  const { api } = useApi();
  const [clients, setClients] = useState<IClientsList[]>([]);
  const [clientsConsumptionTable, setClientsConsumptionTable] = useState<
    IClientsConsumptionTable[]
  >([]);

  const [loadingClientsConsumption, setLoadingClientsConsumption] =
    useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [limitlessClients, setLimitlessClients] = useState<number>(0);
  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);
  const [filters, setFilters] = useState({
    clientId: '',
    channel: '',
    billingDate: '',
    limitlessClients: '',
  });

  const loadClients = useCallback(async () => {
    const response = await api.get('/clients');
    setClients(response.data);
  }, [api]);

  const handleClientsConsumptionTable = (
    consumption: IClientsConsumption[],
  ) => {
    const tableItens = [];

    for (const obj of consumption) {
      const item: IClientsConsumptionTable = {
        client_id: obj.client_id,
        client_name: obj.client_name,
        integration_name: obj.integration_name,
        billing_date: obj.billing_date,
        invoice_amount: obj.invoice_amount || 0,
        active_wpp: obj.active_wpp,
        sms: obj.sms,
        receptive_wpp: obj.receptive_wpp,
      };

      tableItens.push(item);
    }
    setClientsConsumptionTable(tableItens);
  };

  const loadClientsConsumption = useCallback(
    async (
      pageNumber = 1,
      channel = filters.channel,
      billing_date = filters.billingDate,
      client_id = filters.clientId,
      limitlessClients = filters.limitlessClients,
      elementsPerPage = undefined,
    ) => {
      setLoadingClientsConsumption(true);

      const response = await api.get(`/reports/consumption/paginated`, {
        params: {
          page: pageNumber - 1,
          elementsPerPage,
          media_name: channel,
          billing_date,
          limitlessClients,
          client_id,
        },
      });

      const { data } = response;

      setLimitlessClients(data.limitTotal);
      handleClientsConsumptionTable(data.data);
      setPageable(data);
      setLoadingClientsConsumption(false);
    },
    [
      api,
      filters.billingDate,
      filters.channel,
      filters.clientId,
      filters.limitlessClients,
    ],
  );

  const styleColumn = (data: IMediaConsumption | undefined) => {
    const style: CSSProperties = { display: 'block' };
    if (data && data.amount > data.total) {
      style.color = 'red';
    }
    return style;
  };

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Nome',
      dataIndex: 'client_name',
      responsive: true,
      renderColumn(object: IClientsConsumptionTable) {
        return (
          <span>
            {object.client_name}

            {clients.find(client => object.client_id === client.id)
              ?.client_type === 'PARTNER' && (
              <BsFillBuildingsFill size={20} style={{ marginLeft: '12px' }} />
            )}
          </span>
        );
      },
    },
    {
      id: v4(),
      name: 'Nome da Integração',
      dataIndex: 'integration_name',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Data de Faturamento',
      dataIndex: 'billing_date',
      responsive: true,
      renderColumn(object: IClientsConsumption) {
        return object.billing_date ? object.billing_date.toString() : '';
      },
    },
    {
      id: v4(),
      name: 'SMS',
      dataIndex: 'sms',
      responsive: false,
      renderColumn(object: IClientsConsumptionTable) {
        return (
          <span style={styleColumn(object.sms)}>
            {object.sms?.total === 0 ? (
              <span>-</span>
            ) : (
              <span>
                {object.sms?.amount || 0}/{object.sms?.total || 0}
              </span>
            )}
          </span>
        );
      },
    },
    {
      id: v4(),
      name: 'Wpp. Ativo',
      dataIndex: 'active_wpp',
      responsive: false,
      wrap: true,
      renderColumn(object: IClientsConsumptionTable) {
        const { utilities, auth, marketing } = object.active_wpp || {};

        const renderCategory = (categoryName: string, categoryData: any) => {
          const { amount = 0, total = 0 } = categoryData || {};

          return (
            <span style={styleColumn(categoryData)}>
              {categoryName}:{' '}
              {amount === 0 && total === 0 ? (
                <span> - </span>
              ) : (
                <>
                  <span>{amount}</span> /<span>{total}</span>
                </>
              )}
            </span>
          );
        };

        return (!utilities || utilities.total === 0) &&
          (!auth || auth.total === 0) &&
          (!marketing || marketing.total === 0) ? (
          <span>-</span>
          ) : (
          <>
            {renderCategory('Utilidades', utilities)}
            {renderCategory('Autenticação', auth)}
            {renderCategory('Marketing', marketing)}
          </>
          );
      },
    },
    {
      id: v4(),
      name: 'Wpp. Receptivo',
      dataIndex: 'receptive_wpp',
      responsive: false,
      renderColumn(object: IClientsConsumptionTable) {
        return object.receptive_wpp?.total === 0 ? (
          <span> - </span>
        ) : (
          <span style={styleColumn(object.receptive_wpp)}>
            {object.receptive_wpp?.amount || 0}/
            {object.receptive_wpp?.total || 0}
          </span>
        );
      },
    },
  ];

  const onSearch = useCallback(
    (
      client_id: string,
      channel: string,
      billing_date: string,
      limitlessClients: boolean,
    ) => {
      const filtersData = {
        clientId: client_id ?? filters.clientId,
        channel: channel ?? filters.channel, // Ajuste o nome da propriedade conforme necessário
        billingDate: billing_date ?? filters.billingDate,
        limitlessClients:
          limitlessClients.toString() ?? filters.limitlessClients,
      };

      setFilters(filtersData);

      // loadClientsConsumption(
      //   1,
      //   channel,
      //   billing_date,
      //   client_id,
      //   limitlessClients,
      // );
      loadClientsConsumption(
        1,
        filtersData.channel,
        filtersData.billingDate,
        filtersData.clientId,
        filtersData.limitlessClients,
      );
      setShowFilters(false);
    },
    [
      filters.billingDate,
      filters.channel,
      filters.clientId,
      filters.limitlessClients,
      loadClientsConsumption,
    ],
  );

  useEffect(() => {
    try {
      setLoadingClientsConsumption(true);
      loadClients();
      loadClientsConsumption();
      setLoadingClientsConsumption(false);
    } catch (err) {
      setLoadingClientsConsumption(false);
    }
  }, [loadClients, loadClientsConsumption]);

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
  ];

  return (
    <Card className="card-reports">
      <Spin spinning={loadingClientsConsumption} size="large">
        <Table
          title="Gestão de consumo"
          data={clientsConsumptionTable}
          keyIndex="id"
          tableColumns={tableColumns}
          loading={loadingClientsConsumption}
          actionsConfig={headerActions}
          action={false}
          tablePagination={{
            current: pageable.page + 1,
            defaultPageSize: pageable.elementsPerPage,
            totalItems: pageable.totalElements,
          }}
          onChangePage={loadClientsConsumption}
        />
        <ClientsConsumptionReportFilters
          closeFilters={() => setShowFilters(false)}
          showFilters={showFilters}
          search={onSearch}
          clients={clients}
          limitlessClients={limitlessClients}
        />
      </Spin>
    </Card>
  );
};

export default ClientsConsumptionReport;
