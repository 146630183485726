export interface IPageable {
  totalElements: number;
  page: number;
  elements: number;
  elementsPerPage: number;
  totalPages: number;
  firstPage: boolean;
  lastPage: boolean;
}

export const DEFAULT_PAGEABLE = {
  elements: 0,
  elementsPerPage: 20, // Possivel BUG no ANTD nao esta atualizando a paginacao com o dado que vem do BACKEND.
  firstPage: true,
  lastPage: false,
  page: 0,
  totalElements: 0,
  totalPages: 1,
};
