/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';

interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  search(name: string, email: string, status: string, role: string): void;
  roles: string[];
}

const { Option } = Select;

const UsersFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search, roles } = props;
  const [filters, setFilters] = useState({
    id: '',
    name: '',
    email: '',
    status: '',
    role: '',
  });

  const status = [
    { value: 'ENABLED', name: 'ATIVO' },
    { value: 'DISABLED', name: 'INATIVO' },
    { value: '', name: 'Todos' },
  ];

  const [form] = useForm();

  const onSearch = useCallback(() => {
    const { name, email, status, role } = form.getFieldsValue();

    search(
      name === undefined ? '' : name,
      email === undefined ? '' : email,
      status === undefined ? '' : status,
      role === undefined ? '' : role,
    );
  }, [search, form]);

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      visible={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Col span={24}>
          <Form.Item label="Nome:" name="name">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Nome"
              value={filters.name}
              onChange={e => setFilters({ ...filters, name: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="E-mail:" name="email">
            <Input
              className="dcs-input"
              allowClear
              type="email"
              placeholder="ex.: email@exemplo.com"
              value={filters.email}
              onChange={e => setFilters({ ...filters, email: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Status:" name="status">
            <Select
              allowClear
              showSearch
              placeholder="Status"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {status.map(status => {
                return (
                  <Option key={status.value} value={status.value}>
                    {status.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Perfil:" name="role">
            <Select
              allowClear
              showSearch
              placeholder="Perfil"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {roles.map(role => {
                return (
                  <Option key={role} value={role}>
                    {role}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UsersFilters;
