import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { FaReply, FaSave, FaTimes, FaPlus, FaTrash } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import './styles.scss';
import { useForm } from 'antd/lib/form/Form';
import { notificationDCS } from '../../../utils/notificationDcs';
import { useApi } from '../../../hooks/api';

const { Option } = Select;

interface IParams {
  id: string;
}

interface IBrokers {
  id: string;
  broker: string;
}

const medias = [
  {
    name: 'whatsapp',
    label: 'WhatsApp',
  },
  {
    name: 'sms',
    label: 'SMS',
  },
  {
    name: 'fbmessenger',
    label: 'Facebook Messenger',
  },
  {
    name: 'fbcomments',
    label: 'Facebook Comments',
  },
];

const IntegrationConfigForm: React.FC = () => {
  const history = useHistory();
  const [form] = useForm();
  const { api } = useApi();
  const { id } = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [inputsConfig, setInputsConfig] = useState<string[]>([]);
  const [brokers, setBrokers] = useState<IBrokers[]>([]);
  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      const data = {
        media_name: form.getFieldValue('media_name'),
        broker: form.getFieldValue('broker'),
        config: JSON.stringify(inputsConfig),
      };

      if (id === undefined) {
        await api.post('/integrations-config', data);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Configuração de Integração salva com sucesso!',
          secondsDuration: 2,
        });
      } else {
        await api.put(`/integrations-config/${id}`, data);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Configuração de Integração atualizada com sucesso!',
          secondsDuration: 2,
        });
      }
      back();
      setDisableButton(true);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [back, form, inputsConfig, id, api]);

  const addNewItemConfig = useCallback(() => {
    setInputsConfig([...inputsConfig, '']);
  }, [inputsConfig]);

  const removeItemConfig = useCallback(
    (i: number) => {
      const newConfigItems = inputsConfig.filter((item, index) => index !== i);
      setInputsConfig(newConfigItems);
      newConfigItems.forEach((item, index) => {
        form.setFieldsValue({
          [`config_input_${index}`]: item,
        });
      });
    },
    [inputsConfig, form],
  );

  const updateItemConfig = useCallback(
    (item: string, index: number) => {
      const inputConfigFind = inputsConfig.findIndex((input, i) => i === index);

      inputsConfig[inputConfigFind] = item;
    },
    [inputsConfig],
  );

  const loadIntegrationConfig = useCallback(async () => {
    const response = await api.get(`/integrations-config/${id}`);
    form.setFieldsValue({
      media_name: response.data.media_name,
      broker: response.data.broker,
    });
    const inputList = JSON.parse(response.data.config);
    inputList.forEach((item: string, index: number) => {
      form.setFieldsValue({
        [`config_input_${index}`]: item,
      });
    });
    setInputsConfig(inputList);
  }, [id, form, api]);

  const loadBrokers = useCallback(
    async (media_name: string) => {
      form.setFieldsValue({
        [`broker`]: '',
      });
      const response = await api.get(
        `/integrations-config/brokers/${media_name}`,
      );
      setBrokers(response.data);
    },
    [api, form],
  );

  useEffect(() => {
    if (id !== undefined) {
      loadIntegrationConfig();
    }
  }, [id, loadIntegrationConfig]);

  return (
    <Card className="card-integration-config-form">
      <div className="page-header">
        <h2>Nova Configuração de Integração</h2>
        <Button type="primary" shape="round" icon={<FaReply />} onClick={back}>
          Voltar
        </Button>
      </div>
      <Form layout="vertical" form={form} onFinish={onSave}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Canal:"
              name="media_name"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Canal"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
                onChange={value => loadBrokers(String(value))}
              >
                {medias.map(media => (
                  <Option key={media.name} value={media.name}>
                    {media.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Broker:"
              name="broker"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select>
                {brokers.map(broker => (
                  <Option value={broker.broker} key={broker.id}>
                    {broker.broker.toUpperCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider orientation="left">Configuração</Divider>
            {inputsConfig.map((input, index) => (
              <div className="input-list-config" key={`config_input_${index}`}>
                <Form.Item
                  label="Item:"
                  name={`config_input_${index}`}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                  initialValue={input}
                >
                  <Input
                    className="dcs-input"
                    placeholder="ex.: username"
                    onChange={e => updateItemConfig(e.target.value, index)}
                  />
                </Form.Item>
                <Button
                  danger
                  type="primary"
                  shape="circle"
                  onClick={() => removeItemConfig(index)}
                >
                  <FaTrash />
                </Button>
              </div>
            ))}
            <Button type="dashed" block onClick={addNewItemConfig}>
              <FaPlus />
              Adicionar Campo
            </Button>
          </Col>
          <Divider />
          <Col span={24}>
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={<FaSave />}
                htmlType="submit"
                loading={loading}
                disabled={disableButton}
              >
                Salvar
              </Button>
              <Button shape="round" icon={<FaTimes />} onClick={back}>
                Cancelar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default IntegrationConfigForm;
