import React, { useCallback, useState } from 'react';
import {
  Drawer,
  Form,
  Select,
  Col,
  Input,
  Button,
  DatePicker,
  Flex,
} from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import FormItemLabel from 'antd/es/form/FormItemLabel';

interface IHsmClients {
  id: string;
  name: string;
}

interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  clients: IHsmClients[];
  search: (
    status: string,
    client: string,
    start_date: string,
    end_date: string,
    waba_id: string,
    webhook_notify: boolean,
  ) => void;
}

const { Option } = Select;

const HsmFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search, clients } = props;
  const [filters, setFilters] = useState({
    status: '',
    client: '',
    start_date: '',
    end_date: '',
    waba_id: '',
    webhook_notify: undefined,
  });

  const [form] = useForm();

  const onSearch = useCallback(() => {
    const { client, status, start_date, end_date, waba_id, webhook_notify } =
      form.getFieldsValue();
    search(
      status === undefined ? '' : status,
      client === undefined ? '' : client,
      start_date === undefined ? '' : start_date,
      end_date === undefined ? '' : end_date,
      waba_id === undefined ? '' : waba_id,
      webhook_notify === undefined ? '' : webhook_notify,
    );
  }, [search, form]);

  const handleChangeStartDate = useCallback(
    (startDate: string) => {
      const newStartDate = startDate === undefined ? '' : startDate;
      setFilters({ ...filters, start_date: newStartDate });
    },
    [setFilters],
  );
  const handleChangeEndDate = useCallback(
    (endDate: string) => {
      const newEndDate = endDate === undefined ? '' : endDate;
      setFilters({ ...filters, end_date: newEndDate });
    },
    [setFilters],
  );

  const currentDate = new Date().toISOString().split('T')[0];

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      open={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Col span={24}>
          <Form.Item label="Cliente" name="client">
            <Select
              allowClear
              placeholder="Selecione um Cliente"
              className="dcs-select"
              showSearch
              filterOption
              optionFilterProp="children"
            >
              {clients.map(client => {
                return (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Waba-ID" name="waba_id">
            <Input placeholder="Digite um Waba" className="dcs-input" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Status" name="status">
            <Select
              allowClear
              placeholder="Selecione um Status"
              className="dcs-select"
              // onChange={onSearch}
            >
              <Option value="PENDING">Pendente</Option>
              <Option value="IN_ANALYSIS">Em Análise</Option>
              <Option value="APPROVED">Aprovado</Option>
              <Option value="REJECTED">Rejeitado</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Notificação Webhook" name="webhook_notify">
            <Select
              allowClear
              placeholder="Notificação Webhook"
              className="dcs-select"
              showSearch
              filterOption
              optionFilterProp="children"
            >
              <Option value>Sim</Option>
              <Option value={false}>Não</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Flex vertical>
            <FormItemLabel prefixCls="" label="Período" />
            <Flex align="center" gap={5}>
              <Form.Item name="start_date">
                <Input
                  size="small"
                  className="dcs-input"
                  allowClear
                  type="date"
                  value={filters.start_date}
                  max={currentDate}
                  onChange={e => handleChangeStartDate(e.target.value)}
                />
              </Form.Item>
              até
              <Form.Item name="end_date">
                <Input
                  size="small"
                  className="dcs-input"
                  min={useWatch('start_date', form)}
                  allowClear
                  type="date"
                  value={filters.end_date}
                  onChange={e => handleChangeEndDate(e.target.value)}
                  max={currentDate}
                  disabled={!useWatch('start_date', form)}
                />
              </Form.Item>
            </Flex>
          </Flex>
        </Col>

        <Col span={24}>
          <Form.Item>
            <Button type="primary" shape="round" htmlType="submit">
              Filtrar
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Drawer>
  );
};

export default HsmFilters;
