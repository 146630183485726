import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { Button, Col, Divider, Form, Row, Select, Space } from 'antd';
import { FaSave, FaTimes } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import { notificationDCS } from '../../utils/notificationDcs';
import { useApi } from '../../hooks/api';

interface IProps {
  hidden?: boolean;
  phoneMigrationCancel?: (refreshPhones?: boolean) => void;
}

interface IParams {
  id: string;
}

function isAnAxiosError(obj: any): obj is AxiosError {
  return obj.isAxiosError;
}

function isAnFacebookRemoteError(obj: any) {
  return obj?.response?.data?.error?.fbtrace_id;
}

function parseFacebookRemoteErroMessage(obj: any) {
  const fbErrorObj = obj.response.data.error;

  if (fbErrorObj.error_data) {
    return fbErrorObj.error_data.details;
  }
  if (fbErrorObj.error_user_title && fbErrorObj.error_user_msg) {
    return `${fbErrorObj.error_user_title}: ${fbErrorObj.error_user_msg}`;
  }

  return fbErrorObj.message;
}

const WabaDetailPhoneMigration: React.FC<IProps> = props => {
  const { hidden, phoneMigrationCancel } = props;
  const { api } = useApi();
  const [form] = useForm();
  const { id } = useParams<IParams>();
  const [loading, setLoading] = useState(false);

  const phone8Mask = '(99) 9999-9999';
  const phone9Mask = '(99) 99999-9999';
  const [mask, setMask] = useState(phone8Mask);

  const changeMask = (e: { target: { value: string } }) => {
    if (/^\(([0-9]+)\) 9/.test(e.target.value)) {
      if (mask !== phone9Mask) {
        setMask(phone9Mask);
      }
    } else if (mask !== phone8Mask) {
      setMask(phone8Mask);
    }
  };

  const cancelar = useCallback(() => {
    form.resetFields();
    if (phoneMigrationCancel) {
      phoneMigrationCancel();
    }
  }, [form, phoneMigrationCancel]);

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();
      const result = await api.post(`/wabas/${id}/phoneMigration`, data);
      if (result.status === 200) {
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Telefone migrado!',
          secondsDuration: 2,
        });

        // encerrar e atualizar
        if (phoneMigrationCancel) {
          phoneMigrationCancel(true);
        }
      } else {
        notificationDCS({
          type: 'warning',
          message: 'Falha',
          description: JSON.stringify(result.data),
          secondsDuration: 5,
        });
      }
    } catch (err) {
      let message;
      if (isAnAxiosError(err) && isAnFacebookRemoteError(err)) {
        message = parseFacebookRemoteErroMessage(err);
      }
      notificationDCS({
        type: 'error',
        message: 'Falha',
        description: message || JSON.stringify(err),
        secondsDuration: 5,
      });
    } finally {
      setLoading(false);
    }
  }, [form, api, id, phoneMigrationCancel]);
  return (
    <Form layout="vertical" form={form} onFinish={onSave} hidden={hidden}>
      <Row>
        <Col span={8}>
          <Form.Item
            label="DDI:"
            name="cc"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select
              placeholder="Escolha um DDI dentre os autorizados"
              className="dcs-select"
              options={['55'].map(ddi => {
                return { value: ddi, label: `+${ddi}` };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Telefone (com DDD):"
            name="phone_number"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <InputMask
              mask={mask}
              maskChar="_"
              onChange={changeMask}
              className="ant-input dcs-input"
              placeholder="ex.: 83 98704-9999"
            />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Space>
            <Button
              type="primary"
              shape="round"
              icon={<FaSave />}
              htmlType="submit"
              loading={loading}
            >
              Enviar
            </Button>
            <Button shape="round" icon={<FaTimes />} onClick={cancelar}>
              Cancelar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default WabaDetailPhoneMigration;
