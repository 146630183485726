import { Button, Descriptions, Divider, Spin } from 'antd';
import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IWaba } from '../../interfaces/waba';
import { useApi } from '../../hooks/api';
import { useAuth } from '../../hooks/auth';

interface IProps {
  waba?: IWaba;
}

const WabaDetailInfo: React.FC<IProps> = props => {
  const { user } = useAuth();
  const { waba } = props;
  const { api } = useApi();
  const [subscribingToApp, doSubscribeToApp] = useState(false);
  const [assigningSystemUser, doAssign] = useState(false);
  const [creditLineIsShared, doShareCreditLine] = useState(false);
  const isAdmin =
    user.user_profile === 'ADMINISTRATOR' || user.user_profile === 'ROOT';

  const path = useLocation().pathname;

  const showSubscribeButton = path.includes('/wabas/detail');

  const subscribeToApp = useCallback(async () => {
    doSubscribeToApp(true);
    try {
      if (waba) {
        const response = await api.post(`/wabas/${waba.id}/subscribeToApp`);
        if (response.data.success) {
          window.location.reload();
        }
      }
    } finally {
      doSubscribeToApp(false);
    }
  }, [waba, api]);


  const assignSystemUser = useCallback(async () => {
    doAssign(true);
    try {
      if (waba) {
        const response = await api.post(`/wabas/${waba.id}/assignSystemUser`);
        if (response.data.success) {
          window.location.reload();
        }
      }
      // reloadPage
    } finally {
      doAssign(false);
    }
  }, [waba, api]);

  const shareCreditLine = useCallback(async () => {
    doShareCreditLine(true);
    try {
      if (waba) {
        const response = await api.post(`/wabas/${waba.id}/shareCreditLine`);
        if (response.data.success) {
          window.location.reload();
        }
      }
      // reloadPage
    } finally {
      doShareCreditLine(false);
    }
  }, [waba, api]);

  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label="Criado em" span={1}>
          {waba?.createdAt}
        </Descriptions.Item>
        <Descriptions.Item label="ID" span={2}>
          <Link to={`/wabas/detail/${waba?.id}`}>{waba?.id}</Link>
        </Descriptions.Item>
        <Descriptions.Item label="Meta Business Account - ID" span={1}>
          {waba?.business_id}
        </Descriptions.Item>
        <Descriptions.Item label="Meta Business Account - Nome" span={2}>
          {waba?.business_name}
        </Descriptions.Item>
        <Descriptions.Item
          label="WhatsApp Business Account - ID"
          span={1}
        >
          {waba?.waba_id}
          <br />
          {showSubscribeButton && isAdmin && !waba?.is_subscribed_to_app ? (
            <Spin spinning={subscribingToApp} size="large">
              <Button type="primary" onClick={subscribeToApp}>
                Subscrever ao App
              </Button>
            </Spin>
          ) : null}
          
          {isAdmin &&
          waba?.is_subscribed_to_app &&
          !waba?.is_system_user_assigned ? (
            <Spin spinning={assigningSystemUser} size="large">
              <Button type="primary" onClick={assignSystemUser}>
                Associar System User
              </Button>
            </Spin>
            ) : null}
          {isAdmin &&
          waba?.is_subscribed_to_app &&
          waba?.is_system_user_assigned &&
          !waba?.is_credit_line_shared ? (
            <Spin spinning={creditLineIsShared} size="large">
              <Button type="primary" onClick={shareCreditLine}>
                Compartilhar Crédito
              </Button>
            </Spin>
            ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="WhatsApp Business Account - Nome" span={2}>
          {waba?.waba_name}
        </Descriptions.Item>
        <Descriptions.Item label="Message Template Namespace" span={1}>
          {waba?.message_template_namespace}
        </Descriptions.Item>
        {waba?.override_callback_uri ? (
          <Descriptions.Item label="URL de Callback customizada" span={2}>
            {waba?.override_callback_uri}
          </Descriptions.Item>
        ) : null}
      </Descriptions>
      <Divider />
    </>
  );
};

export default WabaDetailInfo;
