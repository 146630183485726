import { Button, Card, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from '../../hooks/api';
import { notificationDCS } from '../../utils/notificationDcs';

import './styles.scss';

const ResetPassword: React.FC = () => {
  const [form] = useForm();
  const { api } = useApi();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const data = {
        email: form.getFieldValue('email'),
      };

      await api.post('/password/reset', data);
      notificationDCS({
        message: 'Sucesso',
        description:
          'Um e-mail será enviado com as instruções para alteração da senha.',
        secondsDuration: 5,
      });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [form, api]);

  return (
    <div className="reset-password-page">
      <Card>
        <h1>Resetar Senha</h1>
        <Form
          layout="vertical"
          className="form-reset-password"
          form={form}
          onFinish={onSubmit}
        >
          <Form.Item
            label="E-mail:"
            name="email"
            rules={[{ required: true, message: 'Preencha seu email!' }]}
          >
            <Input className="dcs-input" type="email" size="large" />
          </Form.Item>
          <Form.Item>
            <Link to="/">Voltar</Link>
          </Form.Item>
          <Form.Item className="reset-password-actions">
            <Button
              type="primary"
              size="large"
              shape="round"
              htmlType="submit"
              loading={loading}
            >
              Resetar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPassword;
