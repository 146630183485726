/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { IWaba } from '../../interfaces/waba';
import { useApi } from '../../hooks/api';
import {
  IClientsForListBillingsReport,
  IIntegrationForListBillingsReport,
} from '../../interfaces/integrationBilling';

interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  clients: IClientsForListBillingsReport[];
  integrations: IIntegrationForListBillingsReport[];
  search(client_id: string, media_name: string, due_date: string): void;
}

const { Option } = Select;

const mediaTypes = [
  {
    value: 'whatsapp',
    name: 'WhatsApp',
  },
  {
    value: 'sms',
    name: 'SMS',
  },
  {
    value: 'facebook',
    name: 'Facebook',
  },
];

const BillingReportFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search, clients, integrations } = props;
  const [allWabas] = useState<IWaba[]>([]);
  const [filters, setFilters] = useState({
    client_id: '',
    media_name: '',
    due_date: '',
  });
  const [integrationsData, setIntegrationsData] = useState(integrations);

  const [form] = useForm();
  const { api } = useApi();

  const onSearch = useCallback(() => {
    const { client_id, media_name, due_date } = form.getFieldsValue();

    search(
      client_id === undefined ? '' : client_id,
      media_name === undefined ? '' : media_name,
      due_date === undefined ? '' : String(due_date),
    );
  }, [search, form]);

  const idArray: { value: string; name: string }[] = [];
  const businessIdArray: { value: string; name: string }[] = [];
  const businessNameArray: { value: string; name: string }[] = [];
  const wabaIdArray: { value: string; name: string }[] = [];
  const wabaNameArray: { value: string; name: string }[] = [];

  allWabas.forEach((item: IWaba) => {
    idArray.push({ value: item.id, name: item.id });
    businessIdArray.push({ value: item.business_id, name: item.business_id });
    businessNameArray.push({
      value: item.business_name,
      name: item.business_name,
    });
    wabaIdArray.push({ value: item.waba_id, name: item.waba_id });
    wabaNameArray.push({ value: item.waba_name, name: item.waba_name });
  });

  useEffect(() => setIntegrationsData(integrations), [integrations]);

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      visible={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Col span={24}>
          <Form.Item label="Cliente" name="client_id">
            <Select
              allowClear
              placeholder="Todos"
              filterOption
              showSearch
              optionFilterProp="children"
              className="dcs-select"
              // onChange={loadIntegrations}
            >
              {clients.map(client => (
                <Option key={client.id} value={client.id}>
                  {client.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Canal:" name="media_name">
            <Select
              allowClear
              placeholder="Todos"
              filterOption
              showSearch
              optionFilterProp="children"
              className="dcs-select"
              notFoundContent="Nenhuma integração para esse cliente"
              onChange={e => setFilters({ ...filters, media_name: String(e) })}
            >
              {mediaTypes.map(media => (
                <Option key={media.value} value={media.value}>
                  {media.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Data de Faturamento" name="due_date">
            <Input
              className="dcs-input"
              allowClear
              type="date"
              placeholder="ex.: Data de Faturaemnto"
              onChange={e =>
                setFilters({ ...filters, due_date: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default BillingReportFilters;
