import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Wabas from '../pages/Wabas';
import WabaDetail from '../pages/Wabas/Detail';
import WabaForm from '../pages/Wabas/Form';
import Clients from '../pages/Clients';
import ClientDetail from '../pages/Clients/Detail';
import ClientForm from '../pages/Clients/Form';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/ForgotPassword';
import GeneralSettings from '../pages/GeneralSettings';
import Hsm from '../pages/Hsm';
import HsmApprovedForm from '../pages/Hsm/ApprovedForm';
import HsmDetail from '../pages/Hsm/Detail';
import HsmRejectedForm from '../pages/Hsm/RejectedForm';
import HsmLogInteraction from '../pages/HsmLogInteraction';
import Integrations from '../pages/Integrations';
import DisabeldFormIntegration from '../pages/Integrations/DisabeldForm';
import IntegrationsForm from '../pages/Integrations/Form';
import IntegrationsConfig from '../pages/IntegrationsConfig';
import IntegrationConfigForm from '../pages/IntegrationsConfig/Form';
import Login from '../pages/Login';
import LogInteractionsStatus from '../pages/LogInteractionsStatus';
import LogInteractionRequest from '../pages/Logs/LogInteractionRequest';
import LogInteractionResponse from '../pages/Logs/LogInteractionResponse';
import Profile from '../pages/Profile';
import BillingReport from '../pages/BillingReport';
import ReportSchedule from '../pages/ReportSchedule';
import ReportScheduleForm from '../pages/ReportSchedule/Form';
import ResetPassword from '../pages/ResetPassword';
import User from '../pages/Users';
import UserForm from '../pages/Users/Form';
import Route from './Route';
import AnaliticReport from '../pages/AnaliticReport';
import ClientsConsumptionReport from '../pages/ClientsConsumptionReport';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/reset-password/" exact component={ResetPassword} />
      <Route path="/forgot-password/:token" exact component={ForgotPassword} />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route
        path="/profile"
        exact
        component={Profile}
        isPrivate
        allowedProfiles={['ALL']}
      />

      <Route
        path="/administration/users"
        exact
        component={User}
        isPrivate
        allowedProfiles={['ADMINISTRATOR']}
      />
      <Route
        path="/administration/users/create"
        exact
        component={UserForm}
        isPrivate
      />
      <Route
        path="/administration/users/create/:id"
        exact
        component={UserForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />

      <Route
        path="/administration/general-settings"
        exact
        component={GeneralSettings}
        isPrivate
        allowedProfiles={['ROOT', 'ADMINISTRATOR']}
      />

      <Route
        path="/accounts"
        exact
        component={Clients}
        isPrivate
        allowedProfiles={['USER']}
      />
      <Route
        path="/accounts/detail/:id"
        exact
        component={ClientDetail}
        isPrivate
        allowedProfiles={['USER']}
      />
      <Route
        path="/wabas"
        exact
        component={Wabas}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT', 'USER']}
      />
      <Route
        path="/wabas/detail/:id"
        exact
        component={WabaDetail}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT', 'USER']}
      />
      <Route
        path="/wabas/create"
        exact
        component={WabaForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT', 'USER']}
      />
      <Route
        path="/wabas/create/:id"
        exact
        component={WabaForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/clients"
        exact
        component={Clients}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/clients/create"
        exact
        component={ClientForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/clients/create/:id"
        exact
        component={ClientForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/clients/detail/:id"
        exact
        component={ClientDetail}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/integrations/config"
        exact
        component={IntegrationsConfig}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/integrations/config/create"
        exact
        component={IntegrationConfigForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/integrations/config/create/:id"
        exact
        component={IntegrationConfigForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/integrations"
        exact
        component={Integrations}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/integrations/create"
        exact
        component={IntegrationsForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/integrations/create/:id"
        exact
        component={IntegrationsForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/integrations/create/:id/licenses/:license_id"
        exact
        component={IntegrationsForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'ROOT']}
      />
      <Route
        path="/integrations/:status/:id"
        exact
        component={DisabeldFormIntegration}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'ROOT']}
      />
      <Route
        path="/logs/interactions-status"
        exact
        component={LogInteractionsStatus}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/billing-reports"
        exact
        component={BillingReport}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'REPORTER']}
      />
      <Route
        path="/analitics-report"
        exact
        component={AnaliticReport}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'REPORTER']}
      />
      <Route
        path="/consumption-report"
        exact
        component={ClientsConsumptionReport}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'REPORTER']}
      />
      <Route
        path="/reports-config/schedules"
        exact
        component={ReportSchedule}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'REPORTER']}
      />
      <Route
        path="/reports-config/schedules/create"
        exact
        component={ReportScheduleForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'REPORTER']}
      />
      <Route
        path="/reports-config/schedules/create/:id"
        exact
        component={ReportScheduleForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'REPORTER']}
      />
      <Route
        path="/logs/interaction-request"
        exact
        component={LogInteractionRequest}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/logs/interaction-response"
        exact
        component={LogInteractionResponse}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />

      <Route
        path="/hsm"
        exact
        component={Hsm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />

      <Route
        path="/hsm/detail/:id"
        exact
        component={HsmDetail}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />

      <Route
        path="/hsm/:id/approved"
        exact
        component={HsmApprovedForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />
      <Route
        path="/hsm/:id/rejected"
        exact
        component={HsmRejectedForm}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />

      <Route
        path="/hsm-logs"
        exact
        component={HsmLogInteraction}
        isPrivate
        allowedProfiles={['ADMINISTRATOR', 'SUPPORT']}
      />

      <Redirect to="/dashboard" />
    </Switch>
  );
};

export default Routes;
