const applyPhoneMask = (phone: string) => {
  const numericValue = phone.replace(/\D/g, '');

  if (numericValue.length > 10) {
    return numericValue.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  return numericValue.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
};

export default applyPhoneMask;
