import React from 'react';

import './styles.scss';

interface IProps {
  title: string;
  type: 'primary' | 'success' | 'danger' | 'warning' | 'default' | 'pending';
}

const TagDcs: React.FC<IProps> = props => {
  const { title, type } = props;
  return <span className={`tag-dcs ${type}`}>{title}</span>;
};

export default TagDcs;
