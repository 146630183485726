import { ApiOutlined, IdcardOutlined } from '@ant-design/icons';
import { Button, Card, Tabs } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaReply } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import IntegrationForm from '../../../components/IntegrationForm';
import LicenseForm from '../../../components/LicenseForm';
import { useApi } from '../../../hooks/api';
import IIntegrationForm from '../../../interfaces/integrationForm';
import ILicenseForm from '../../../interfaces/licenseForm';
import { notificationDCS } from '../../../utils/notificationDcs';

interface IParams {
  id: string;
  license_id: string;
}

const IntegrationsForm: React.FC = () => {
  const { goBack } = useHistory();
  const { api } = useApi();
  const { id, license_id } = useParams<IParams>();
  const [integrationId, setIntegrationId] = useState('');
  const [licenseDisable, setLicenseDisable] = useState(true);
  const [clientIdSelected, setClientIdSelected] = useState<string>('');

  const onSaveIntegration = useCallback(
    async (integrationForm: IIntegrationForm, integration_id?: string) => {
      if (id !== undefined || integrationId !== '') {
        const response = await api.put(
          `/integrations/${id || integration_id}`,
          {
            name: integrationForm.name,
            media_name: integrationForm.media_name,
            broker: integrationForm.broker,
            credentials: integrationForm.credentials,
            account: integrationForm.account,
            client_id: integrationForm.client_id,
            license_id: integrationForm.license_id,
            url_callback: integrationForm.url_callback,
            url_status_whatsapp: integrationForm.url_status_whatsapp,
          },
        );
        setIntegrationId(response.data.id);
        setClientIdSelected(integrationForm.client_id);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Integração atualizada com sucesso!',
          secondsDuration: 2,
        });
      } else {
        const response = await api.post('/integrations', {
          name: integrationForm.name,
          media_name: integrationForm.media_name,
          broker: integrationForm.broker,
          credentials: integrationForm.credentials,
          account: integrationForm.account,
          client_id: integrationForm.client_id,
          license_id: integrationForm.license_id,
          url_callback: integrationForm.url_callback,
          url_status_whatsapp: integrationForm.url_status_whatsapp,
        });
        setClientIdSelected(integrationForm.client_id);
        setIntegrationId(response.data.id);
        setLicenseDisable(!integrationForm.clientIndirect);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Integração salva com sucesso!',
          secondsDuration: 2,
        });
      }
      goBack();
    },
    [api, goBack, id],
  );

  useEffect(() => {
    if (id !== undefined) setIntegrationId(id);
  }, [id]);

  return (
    <Card className="card-integration-form">
      <div className="page-header">
        <h2>Nova Integração</h2>
        <Button
          type="primary"
          shape="round"
          icon={<FaReply />}
          onClick={goBack}
        >
          Voltar
        </Button>
      </div>
      <IntegrationForm saveIntegration={onSaveIntegration} />
    </Card>
  );
};

export default IntegrationsForm;
