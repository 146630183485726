import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Alert,
  Flex,
} from 'antd';
import { FaFileExcel } from 'react-icons/fa';
import locale from 'antd/es/date-picker/locale/pt_BR';

import { useForm } from 'antd/lib/form/Form';
import { differenceInDays, format, set } from 'date-fns';
import dayjs from 'dayjs';
import { useApi } from '../../hooks/api';

import IClientsList from '../../interfaces/clients';

import './styles.scss';
import { notificationDCS } from '../../utils/notificationDcs';

const { Option } = Select;

interface IReportForm {
  client_id: string;
  start_date: string;
  end_date: string;
  media: string;
}

const AnaliticReport: React.FC = () => {
  const { api } = useApi();
  const [form] = useForm();
  const [clients, setClients] = useState<IClientsList[]>([]);
  const [loading, setLoading] = useState(false);
  const [clientIsPartner, setClientIsPartner] = useState<boolean>(false);
  const [optionSelected, setOptionSelected] = useState('');

  const loadClients = useCallback(async () => {
    const response = await api.get('/clients');
    setClients(response.data);
  }, [api]);

  const generateMetabaseReport = useCallback(async () => {
    const data: IReportForm = form.getFieldsValue();

    if (!data.media || !data.start_date || !data.end_date) {
      notificationDCS({
        type: 'warning',
        message: 'Aviso',
        description: 'Preencha todos os campos obrigatórios do filtro.',
      });
      return;
    }

    if (
      differenceInDays(new Date(data.end_date), new Date(data.start_date)) > 45
    ) {
      notificationDCS({
        type: 'warning',
        message: 'Aviso',
        description: 'Limite máximo de 45 dias para gerar relatório!',
      });
      setLoading(false);
      return;
    }

    const showWarningNotification = () => {
      notificationDCS({
        type: 'warning',
        message: 'Atenção',
        description: 'Não há registros referente aos filtros informados!',
      });
    };

    try {
      setLoading(true);
      const response = await api.post(
        `/metabase/analytical-report/export`,
        data,
        {
          responseType: 'blob',
        },
      );

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `analitico-${data.media}.xlsx`); // Nome do arquivo para download
        document.body.appendChild(link);
        link.click();

        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
        window.URL.revokeObjectURL(url);

        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Relatório gerado com sucesso!',
        });
      } else {
        showWarningNotification();
      }
      setLoading(false);
    } catch (error) {
      showWarningNotification();
      setLoading(false);
    }
  }, []);

  const generateReport = useCallback(
    async (type: 'excel' | 'pdf' | 'html') => {
      setLoading(true);
      const data: IReportForm = form.getFieldsValue();
      if (data.media === undefined) {
        data.media = 'sms';
      }
      if (!data.media || !data.start_date || !data.end_date) {
        notificationDCS({
          type: 'warning',
          message: 'Aviso',
          description: 'Preencha todos os campos obrigatórios do filtro.',
        });
      } else {
        if (
          differenceInDays(new Date(data.end_date), new Date(data.start_date)) >
          90
        ) {
          notificationDCS({
            type: 'warning',
            message: 'Aviso',
            description: 'Limite máximo de 90 dias para gerar relatório!',
          });
          setLoading(false);
          return;
        }

        data.start_date = format(new Date(data.start_date), 'yyyy-MM-dd');
        data.end_date = format(new Date(data.end_date), 'yyyy-MM-dd');

        try {
          const response = await api.post(`/reports/excel/analytical`, data);
          if (response.status === 200) {
            window.open(response.data.report_url, '_blank');
          } else {
            notificationDCS({
              type: 'warning',
              message: 'Atenção',
              description: 'Não há registros referente aos filtros informados!',
            });
          }
        } catch (error) {
          notificationDCS({
            type: 'warning',
            message: 'Atenção',
            description: 'Não há registros referente aos filtros informados!',
          });
        }
      }
      setLoading(false);
    },
    [api, form],
  );
  const alertForChooseClientPartner = (value: string) => {
    const selectedClient = clients.find(client => client.id === value);

    if (selectedClient && selectedClient.client_type === 'PARTNER') {
      setClientIsPartner(true);
    } else {
      setClientIsPartner(false);
    }

    setOptionSelected(value);
  };

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  return (
    <Card className="card-reports">
      <div className="page-header">
        <h2>Relatório analítico de consumo</h2>
      </div>
      {clientIsPartner && (
        <Alert
          style={{ marginTop: '5px' }}
          message="O cliente escolhido é do tipo parceiro e poderá ter clientes associados a ele. Para ter acesso ao consumo dos clientes associados, gere um relatório selecionando os mesmos."
          type="warning"
          className="ant-alert-message"
        />
      )}
      <Form layout="vertical" form={form} initialValues={{ media: 'sms' }}>
        <Row>
          <Col lg={6} md={12} sm={12} xs={24}>
            <Form.Item
              label="Canal:"
              name="media"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                showSearch
                placeholder="Canal"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
              >
                <Option key="sms" value="sms">
                  SMS
                </Option>
                <Option key="whatsapp" value="whatsapp">
                  WhatsApp
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            <Form.Item label="Cliente:" name="client_id">
              <Select
                allowClear
                showSearch
                placeholder="Todos"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
                value={optionSelected}
                onChange={alertForChooseClientPartner}
                // onChange={value => handleClient(value)}
              >
                {clients.map(client => (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col lg={6} md={12} sm={12} xs={24}>
            <Form.Item
              label="Data inicial:"
              name="start_date"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <DatePicker
                locale={locale}
                format="DD/MM/YYYY"
                className="dcs-input"
                disabledDate={d => !d.isBefore(Date.now())}
                // defaultValue={dayjs(Date.now(), "DD/MM/YYYY")}
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            <Form.Item
              label="Data final:"
              name="end_date"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <DatePicker
                locale={locale}
                className="dcs-input"
                format="DD/MM/YYYY"
                disabledDate={currentDate => {
                  const startDate = form.getFieldValue('start_date');
                  return (
                    startDate && currentDate && currentDate.isBefore(startDate)
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Flex gap={2}>
          {loading ? (
            <Spin size="large" spinning={loading} />
          ) : (
            <>
              <Col>
                <Button
                  type="primary"
                  shape="round"
                  icon={<FaFileExcel />}
                  onClick={() => generateReport('excel')}
                >
                  Gerar XLSX
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="round"
                  icon={<FaFileExcel />}
                  onClick={() => generateMetabaseReport()}
                >
                  Metabase XLSX
                </Button>
              </Col>
            </>
          )}
        </Flex>
      </Form>
    </Card>
  );
};

export default AnaliticReport;
