/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Tabs,
  Spin,
  Space,
  Col,
  Row,
  Flex,
  Divider,
  Collapse,
} from 'antd';
import { FaPlus, FaReply, FaSyncAlt } from 'react-icons/fa';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ApartmentOutlined,
  ApiOutlined,
  IdcardOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import IClientsList from '../../../interfaces/clients';

import './styles.scss';
import IClientLicenses from '../../../interfaces/clientLicenses';
import { IWaba } from '../../../interfaces/waba';
import { useAuth } from '../../../hooks/auth';
import { useApi } from '../../../hooks/api';
import ClientDetailInfo from '../../../components/ClientDetailInfo';
import ClientDetailIntegrationItem from '../../../components/ClientDetailIntegrationItem';
import LicenseDetailInfo from '../../../components/LicenseDetailInfo';
import WabaDetailInfo from '../../../components/WabaDetailInfo';
import { launchWhatsAppSignup } from '../../../utils/facebookInit';
import ClientDetailIndirectsItem from '../../../components/ClientDetailIndirectsItem';
import IIntegrationsList from '../../../interfaces/integrationsList';

const { TabPane } = Tabs;

interface IParams {
  id: string;
  license_id: string;
}

const ClientDetail: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('1');
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingLicenses, setLoadingLicenses] = useState(false);
  const [loadingWabas, setLoadingWabas] = useState(false);
  const [client, setClient] = useState<IClientsList>();
  const [licenses, setLicenses] = useState<IClientLicenses[]>([]);
  const [integrations, setIntegrations] = useState<IIntegrationsList[]>([]);
  const [indirects, setIndirects] = useState<IClientsList[]>([]);
  const [wabas, setWabas] = useState<IWaba[]>([]);
  const { id, license_id } = useParams<IParams>();
  const { api } = useApi();
  const isNormalUser = user.user_profile === 'USER';

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const path = useLocation().pathname;

  const showRefreshButton = path.includes('/wabas/detail');

  const loadClientDetail = useCallback(async () => {
    setLoadingClient(true);
    try {
      const response = await api.get(`/clients/${id}`);
      setClient(response.data);
      if (
        response.data.client_type === 'PARTNER' &&
        response.data.indirects.length > 0
      ) {
        setIndirects(response.data.indirects);
      }
    } finally {
      setLoadingClient(false);
      setCurrentTab('1');
    }
  }, [id, api]);

  const loadClientLicenses = useCallback(async () => {
    setLoadingLicenses(true);
    try {
      const response = await api.get(`/clients/${id}/licenses`);
      setLicenses(response.data);
    } finally {
      setLoadingLicenses(false);
    }
  }, [id, api]);

  const loadClientIntegrations = useCallback(async () => {
    setLoadingLicenses(true);
    try {
      const response = await api.get(`/clients/${id}/integrations`);
      setIntegrations(response.data);
    } finally {
      setLoadingLicenses(false);
    }
  }, [id, api]);

  const loadClientWabas = useCallback(async () => {
    setLoadingWabas(true);
    try {
      const response = await api.get(`/clients/${id}/wabas`);
      setWabas(response.data);
    } finally {
      setLoadingWabas(false);
    }
  }, [id, api]);

  const activeWaba = useCallback(async (waba: any) => {
    const response = await api.post(`/wabas/${waba.id}/subscribeToApp`);;
    if (response.data.success) {
      const response = await api.post(`/wabas/${waba.id}/assignSystemUser`);
      if (response.data.success) {
        const response = await api.post(`/wabas/${waba.id}/shareCreditLine`);
        if (response.data.success) {
          loadClientWabas();
          loadClientLicenses();
          loadClientIntegrations();
        } 
      }
    }
  }, [id]);

  const newClientWaba = useCallback(async () => {
    setLoadingWabas(true);
    await launchWhatsAppSignup()
      .then(auth_response => {
        api
          .post(`/meta/authenticate`, {
            accessToken: auth_response.accessToken,
            client_id: id,
          })
          .then(response => {
            activeWaba(response.data[0]);
          })
          .catch(err => {
            console.log('Error delegating to DCS api', err);
            setLoadingWabas(false);
          });
      })
      .catch(err => {
        console.log('Error processing Meta api', err);
        setLoadingWabas(false);
      });
  }, [id, api, loadClientWabas, loadClientLicenses, loadClientIntegrations]);


  useEffect(() => {
    if (id !== undefined) {
      loadClientDetail();
      loadClientIntegrations();
      loadClientLicenses();
      loadClientWabas();
    }
  }, [
    id,
    loadClientDetail,
    loadClientLicenses,
    loadClientIntegrations,
    loadClientWabas,
  ]);

  const licensesGroupedByMediaName = licenses.reduce(
    (groups: any, license: IClientLicenses) => {
      const mediaName = license.media;
      if (!groups[mediaName]) {
        groups[mediaName] = [];
      }
      groups[mediaName].push(license);
      return groups;
    },
    {},
  );

  return (
    <Card className="card-client-detail">
      <div className="page-header">
        <h2>Dados {isNormalUser ? 'da Conta' : 'do Cliente'}</h2>
        <Button type="primary" shape="round" icon={<FaReply />} onClick={back}>
          Voltar
        </Button>
      </div>
      <Tabs
        defaultActiveKey={currentTab}
        activeKey={currentTab}
        onChange={key => setCurrentTab(key)}
      >
        <TabPane
          tab={
            <span>
              <UserOutlined />
              Dados {isNormalUser ? 'da Conta' : 'do Cliente'}
            </span>
          }
          key="1"
        >
          <Spin spinning={loadingClient} size="large">
            <ClientDetailInfo client={client} />
          </Spin>
        </TabPane>
        <TabPane
          tab={
            <span>
              <ApiOutlined />
              Integrações
            </span>
          }
          key="2"
        >
          <Spin spinning={loadingLicenses} size="large">
            {integrations.map(integration => (
              <ClientDetailIntegrationItem
                key={integration.id}
                integration={integration}
              />
            ))}
          </Spin>
        </TabPane>
        <TabPane
          tab={
            <span>
              <IdcardOutlined />
              Licenças
            </span>
          }
          key="3"
        >
          <Spin spinning={loadingLicenses} size="large">
            <Collapse defaultActiveKey={['1']}>
              {Object.entries(licensesGroupedByMediaName).map(
                ([mediaName, licenses]: any) => (
                  <CollapsePanel
                    header={mediaName.toUpperCase()}
                    key={mediaName}
                    showArrow
                    style={{ fontStyle: 'bold' }}
                  >
                    {licenses.map((license: IClientLicenses) => (
                      <Flex vertical key={license.id}>
                        <LicenseDetailInfo licenses={license} />
                        <Divider />
                      </Flex>
                    ))}
                  </CollapsePanel>
                ),
              )}
            </Collapse>
          </Spin>
        </TabPane>
        <TabPane
          tab={
            <span>
              <WhatsAppOutlined />
              WABAs
            </span>
          }
          key="4"
        >
          {isNormalUser ? (<Row>
              <Col span={24}>
              <Space>
                <Button
                  type="primary"
                  shape="round"
                  icon={<FaPlus />}
                  onClick={() => newClientWaba()}
                >
                  Cadastrar Novo WABA
                </Button>
              </Space>
            </Col>
            {showRefreshButton && (
              <Col span={24}>
                <Button
                  style={{ marginTop: '8px' }}
                  type="primary"
                  shape="round"
                  icon={<FaSyncAlt />}
                  onClick={() => loadClientWabas()}
                >
                  Atualizar
                </Button>
              
            </Col>
            )}
            </Row>): <div/>}
          <Spin spinning={loadingWabas} size="large">
            {wabas.map(waba => (
              <WabaDetailInfo key={waba.id} waba={waba} />
            ))}
          </Spin>
        </TabPane>
        {client?.client_type === 'PARTNER' ? (
          <TabPane
            tab={
              <span>
                <ApartmentOutlined />
                Indiretos Associados
              </span>
            }
            key="5"
          >
            <Spin spinning={loadingLicenses} size="large">
              <ClientDetailIndirectsItem clients={indirects} />
            </Spin>
          </TabPane>
        ) : undefined}
      </Tabs>
      {/* 
      <Spin spinning={loadingClient} size="large">
        <ClientDetailInfo client={client} />
      </Spin>
      <br />
      <Divider orientation="left">Licenças</Divider>
      <Spin spinning={loadingLicenses} size="large">
        {licenses.map(license => (
          <ClientDetailIntegrationItem key={license.id} license={license} />
        ))}
      </Spin> */}
    </Card>
  );
};

export default ClientDetail;
