/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import IClientsList from '../../interfaces/clients';


interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  clients: IClientsList[];
  search(client_id: string, media_name: string): void;
}

const { Option } = Select;

const ReportScheduleFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search, clients } = props;
  const [form] = useForm();
  const onSearch = useCallback(() => {
    const { client_id, media_name } = form.getFieldsValue();
    search(
      client_id === undefined ? '' : client_id,
      media_name === undefined ? '' : media_name,
    );
  }, [search, form]);

  const medias = [
    { value: 'whatsapp', name: 'WhatsApp' },
    { value: 'sms', name: 'SMS' },
    // { value: 'fbmessenger', name: 'Facebook Messenger' },
    // { value: 'fbcomments', name: 'Facebook Comments' },
  ];

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      open={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Col span={24}>
          <Form.Item label="Cliente" name="client_id">
            <Select
              allowClear
              placeholder="Todos"
              filterOption
              showSearch
              optionFilterProp="children"
              className="dcs-select"
            >
              {clients.map(client => (
                <Option key={client.id} value={client.id}>
                  {client.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      
        <Col span={24}>
          <Form.Item label="Canal:" name="media_name">
            <Select
              allowClear
              showSearch
              placeholder="Canal"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {medias.map(media => {
                return (
                  <Option key={media.value} value={media.value}>
                    {media.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ReportScheduleFilters;
