import React from 'react';

const LineForEnv = (): React.JSX.Element | null => {
  const showChangesForEnv = () => {
    const environment = process.env.REACT_APP_NODE_ENV;
    let backgroundColor = '';

    switch (environment) {
      case 'qa':
        backgroundColor = '#FF0000';
        break;
      case 'staging':
        backgroundColor = '#FF8C00';

        break;
      default:
        return null;
    }
    return { backgroundColor };
  };

  const result = showChangesForEnv();

  if (result === null) {
    return null;
  }

  const { backgroundColor } = result;
  return <div className="line-header-env " style={{ backgroundColor }} />;
};
export default LineForEnv;
