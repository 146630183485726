import { Card, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaSyncAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import Table from '../../components/Table';
import { IActionHeaderConfig, ITableColumn } from '../../interfaces/table';

import { IUserList } from '../../interfaces/user';
import { useAuth } from '../../hooks/auth';
import ILogInteractionsStatusList from '../../interfaces/logInteractionsStatusList';
import { IPageable, DEFAULT_PAGEABLE } from '../../interfaces/pageable';
import { useApi } from '../../hooks/api';

const LogInteractionsStatus: React.FC = (): any => {
  const { user } = useAuth();
  const { api } = useApi();
  const history = useHistory();
  const [logInteractionsStatus, setLogInteractionsStatus] = useState<
    ILogInteractionsStatusList[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);

  const loadLogInteractionsStatus = useCallback(
    async (pageNumber = 1) => {
      try {
        setLoading(true);
        const response = await api.get('/log-interactions-status', {
          params: { page: pageNumber - 1 },
        });
        setLogInteractionsStatus(response.data.data);
        setPageable(response.data); // response.data ~= IPageable
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [api],
  );

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Status',
      dataIndex: 'status',
      responsive: true,
      renderColumn: ({ status }: ILogInteractionsStatusList) => {
        switch (status) {
          case 'read':
            return <Tag color="blue">Lida</Tag>;
          case 'received':
            return <Tag color="green">Recebida</Tag>;
          case 'failed':
            return <Tag color="red">Falha</Tag>;
          case 'delivered':
            return <Tag color="purple">Entregue</Tag>;
          case 'sent':
            return <Tag color="cyan">Enviada</Tag>;
          case 'response':
            return <Tag color="volcano">Resposta</Tag>;
          default:
            return <Tag color="default">{status}</Tag>;
        }
      },
    },
    {
      id: v4(),
      name: 'Resposta',
      dataIndex: 'response',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Url de Callback',
      dataIndex: 'callback_url',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Resposta do Callback',
      dataIndex: 'callback_response',
      responsive: false,
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        loadLogInteractionsStatus();
      },
    },
  ];

  useEffect(() => {
    loadLogInteractionsStatus();
  }, [loadLogInteractionsStatus]);

  return (
    <Card>
      <Table
        title="Status das Interações"
        actionsConfig={headerActions}
        data={logInteractionsStatus}
        keyIndex="id"
        tableColumns={tableColumns}
        tableActions={[
          {
            name: 'Editar',
            icon: <EditOutlined />,
            action: (data: IUserList) => {
              history.push(`/integrations/config/create/${data.id}`);
            },
            disabled: () => {
              return !user.root;
            },
          },
        ]}
        tablePagination={{
          current: pageable.page + 1,
          defaultPageSize: pageable.elementsPerPage,
          totalItems: pageable.totalElements,
        }}
        onChangePage={loadLogInteractionsStatus}
        loading={loading}
      />
    </Card>
  );
};

export default LogInteractionsStatus;
