import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

const GeneralSettingsMenu: React.FC = () => {
  return (
    <div className="general-settings-menu">
      <ul style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <li className="general-settings-menu-tree">Sistema</li>
        <li className="general-settings-menu-item">
          <Link to="#general">Configurações Gerais</Link>
        </li>
        <li className="general-settings-menu-item">
          <Link to="#email">Configurações de Email</Link>
        </li>
        <li className="general-settings-menu-item">
          <Link to="#facebook">Configurações de Facebook</Link>
        </li>
        <li className="general-settings-menu-item">
          <Link to="#instagram">Configurações de Instagram</Link>
        </li>
        <li className="general-settings-menu-item">
          <Link to="#reports">Configurações de Relatórios</Link>
        </li>
      </ul>
    </div>
  );
};

export default GeneralSettingsMenu;
