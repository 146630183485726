import React, { useEffect } from 'react';
import './styles/index.scss';
import './styles/global.scss';
import { HashRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import AppContainer from './container/AppContainer';
import words from './lang';
import PublicContainer from './container/PublicContainer';
import { useAuth } from './hooks/auth';
import { useApplication } from './hooks/application';
import LineForEnv from './helpers/LineForEnv';

const locale = 'pt';

const App: React.FC = () => {
  const { user, signOut } = useAuth();
  const { logged } = useApplication();

  useEffect(() => {
    if (!logged) {
      signOut();
    }
  }, [logged, signOut]);
  return (
    <HashRouter hashType="slash">
      <IntlProvider locale={locale} messages={words.pt}>
        <LineForEnv />
        {user ? <AppContainer /> : <PublicContainer />}
      </IntlProvider>
    </HashRouter>
  );
};

export default App;
