import { Divider } from 'antd';
import React from 'react';

import './styles.scss';

interface IProps {
  title: string;
  children: React.ReactNode;
}

const GeneralSettingsBody: React.FC<IProps> = props => {
  const { title, children } = props;
  return (
    <div className="general-settings-body">
      <span className="title">{title}</span>
      <Divider className="general-settings-divider" />
      <div className="body">{children}</div>
    </div>
  );
};

export default GeneralSettingsBody;
