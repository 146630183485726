/* eslint-disable no-restricted-syntax */
import { Card, Descriptions, Table, Tag, TableColumnsType } from 'antd';
import React from 'react';
import IClientLicenses from '../../interfaces/clientLicenses';
import { formatCurrency } from '../../utils/formatCurrency';

import './styles.scss';

interface IProps {
  licenses: IClientLicenses;
}

const LicenseDetailInfo: React.FC<IProps> = props => {
  const { licenses } = props;

  const otherCategories = licenses.categories.filter(
    category => category.type !== 'Serviço',
  );
  const sortededCategories: any[] = [];
  for (const cat of otherCategories) {
    if (cat.type === 'Marketing') {
      sortededCategories.splice(0, 0, cat);
    }
    if (cat.type === 'Utilidades') {
      sortededCategories.splice(1, 0, cat);
    }
    if (cat.type === 'Autenticação') {
      sortededCategories.splice(2, 0, cat);
    }
  }

  const serviceCategory = licenses.categories.find(
    category => category.type === 'Serviço',
  );
  if (serviceCategory) {
    serviceCategory.type = 'Serviço (Receptivo)';
  }

  sortededCategories.push(serviceCategory);

  const hasCategories = licenses.categories.length > 0;
  const columns: TableColumnsType<any> = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Conversas disponíveis',
      dataIndex: 'available_quantity',
      key: 'available_quantity',
      align: 'center',
    },
    {
      title: 'Valor do pacote',
      dataIndex: 'package_value_notification',
      key: 'package_value_notification',
      align: 'center',
      render: (value: number) => formatCurrency(value),
    },
    {
      title: 'Valor adicional por conversa',
      dataIndex: 'additional_message_value_notification',
      key: 'additional_message_value_notification',
      align: 'center',
      render: (value: number) => formatCurrency(value),
    },
  ];

  return (
    <div style={{ margin: '20px 0', width: '100%' }}>
      <Card
        // actions={[<EllipsisOutlined key="ellipsis" onClick={toggleExpand} />]}
        // title={licenses.integration.media_name.toUpperCase()}
        key={licenses.id}
        style={{ width: '100%' }}
      >
        <Descriptions
          colon
          size="small"
          bordered
          layout="horizontal"
          column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        >
          <Descriptions.Item label="Nome da licença" span={5}>
          {licenses.name}
          </Descriptions.Item>
          <Descriptions.Item label="Status da licença" span={5}>
            {licenses.enabled ? (
              <Tag color="success">Habilitada</Tag>
            ) : (
              <Tag color="warning">Desabilitada</Tag>
            )}
          </Descriptions.Item>
          {licenses.media !== 'whatsapp' ? (
            <>
              <Descriptions.Item
                label="Quantidade disponível de mensagem"
                span={0}
              >
                {licenses.available_quantity}
              </Descriptions.Item>
              <Descriptions.Item
                label="Valor do pacote"
                contentStyle={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                span={0}
              >
                {formatCurrency(licenses.package_value)}
              </Descriptions.Item>

              <Descriptions.Item label="Valor de mensagem extra" 
                span={0}>
                {formatCurrency(licenses.extra_message_value)}
              </Descriptions.Item>
            </>
          ) : <>
          </>}

          {licenses.media === 'whatsapp' &&
            (hasCategories ? (
                <Descriptions.Item
                  label="Categorias"
                  span={12}
                  contentStyle={{ margin: 0, padding: 0 }}
                >
                  <Table
                    className="category-custom-table"
                    columns={columns}
                    dataSource={sortededCategories}
                    pagination={false}
                    bordered
                  />
                </Descriptions.Item>
            ) : (
              <>
                <Descriptions.Item
                  label="Quantidade de mensagens de notificações"
                  span={3}
                >
                  {licenses.quantity_notification_messages}
                </Descriptions.Item>
                <Descriptions.Item label="Quantidade disponível" span={3}>
                  {licenses.available_quantity ?? 0}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Valor do pacote de notificações"
                  span={3}
                  contentStyle={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {formatCurrency(licenses.package_value_notification)}
                </Descriptions.Item>
                <Descriptions.Item label="Valor do Pacote" span={3}>
                  {formatCurrency(licenses.package_value ?? 0)}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Valor por notificação adicional"
                  span={3}
                >
                  {formatCurrency(licenses.additional_message_value_notification)}
                </Descriptions.Item>
                <Descriptions.Item label="Valor da Mensagem Extra" span={3}>
                  {formatCurrency(licenses.extra_message_value ?? 0)}
                </Descriptions.Item>
              </>
            ))}
          {/*  Lógica para quando houver um array de integrações */}
          {/* {isExpanded
            ? integracao.map(integration => (
                <>
                  <Descriptions.Item label="Nome da Integração" span={1.5}>
                    {integration?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="ID da Integração" span={2}>
                    {integration?.id}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status da Integração" span={1.5}>
                    {integration?.status === 'ENABLED' ? (
                      <Badge status="success" text="Ativa" />
                    ) : (
                      <Badge status="error" text="Inativa" />
                    )}
                  </Descriptions.Item>
                </>
              ))
            : null} */}
        </Descriptions>
      </Card>
    </div>
  );
};

export default LicenseDetailInfo;
