import React, { ComponentType, useCallback } from 'react';
import { Card, Typography } from 'antd';
import './styles.scss';
import { IconBaseProps } from 'react-icons';
import { Link } from 'react-router-dom';

interface IProps {
  icon: ComponentType<IconBaseProps>;
  name: string;
  statistc:
    | {
        active: number;
        inactive: number;
        total: number;
      }
    | number;
  link?: string;
}

const { Text } = Typography;

const CardStatistics: React.FC<IProps> = props => {
  const { icon: Icon, name, statistc, link } = props;

  const formatNumber = useCallback((value: number) => {
    return String(value).padStart(2, '0');
  }, []);

  const CardContent = (
    <Card className="card-statistics">
      <Icon size={75} color="#C9E265" />
      <div className="info">
        {typeof statistc === 'object' ? (
          <span className="statistc">
            {formatNumber(statistc.active)}
            <Text>/{formatNumber(statistc.total)}</Text>
          </span>
        ) : (
          <span className="statistc">{formatNumber(statistc)}</span>
        )}
        <span>{name}</span>
      </div>
    </Card>
  );

  return link ? <Link to={link}>{CardContent}</Link> : CardContent;
};

export default CardStatistics;
