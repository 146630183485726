import { isSameDay, isValid, parseISO, format } from 'date-fns';
import { pt } from 'date-fns/locale';
/**
 * Return true if the dates are in the same day (and year and month).
 */
const dateIsSameDay = (dateString1: string, dateString2: string): boolean => {
  const date1 = parseISO(dateString1);
  const date2 = parseISO(dateString2);

  if (!isValid(date1) || !isValid(date2)) return false;

  const result = isSameDay(date1, date2);

  return result;
};

const formatDate = (date: Date | undefined) => {
  const dateIso = parseISO(
    format(new Date(date || Date.now()), 'yyyy-MM-dd HH:mm'),
  );
  return format(dateIso, 'dd/MM/yyyy HH:mm');
};

export { dateIsSameDay, formatDate };
