import React, { useCallback, useState } from 'react';
import { Button, Card, Tabs } from 'antd';
import { FaReply } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import './styles.scss';
import { IdcardOutlined, UserOutlined } from '@ant-design/icons';
import ClientForm from '../../../components/ClientForm';
import { useApi } from '../../../hooks/api';
import ILicenseForm from '../../../interfaces/licenseForm';
import { notificationDCS } from '../../../utils/notificationDcs';
import LicenseForm from '../../../components/LicenseForm';
import { LicensesClient } from '../LicensesClient';

interface IParams {
  id: string;
}

const { TabPane } = Tabs;

const ClientsForm: React.FC = () => {
  const { goBack } = useHistory();
  const { api } = useApi();
  const { id } = useParams<IParams>();
  const [currentTab, setCurrentTab] = useState('1');
  const [clientId, setClientId] = useState(id || '');
  const [licenseDisable, setLicenseDisable] = useState(true);
  const [licenseSelected, setLicenseSelected] = useState<string>('');
  const [showLicenseForm, setShowLicenseForm] = useState(false);

  const onSaveClient = useCallback(
    async (dataToSend: any, client_id?: string) => {
      const notifySuccess = (message: string) => {
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: message,
          secondsDuration: 2,
        });
      };

      let response;
      try {

        if (id !== undefined || client_id !== '') {
          response = await api.put(`/clients/${id || client_id}`,dataToSend);
          notifySuccess('Cliente atualizado com sucesso!');
        } else {
          response = await api.post('/clients', dataToSend);
          notifySuccess('Cliente cadastrado com sucesso!');
        }

        setClientId(response.data.id);

        if (dataToSend.client_type === 'INDIRECT') {
          goBack();
        } else {
          setCurrentTab('2');
        }

      } catch (error: any) {
        notificationDCS({
          type: 'warning',
          message: 'Alerta',
          description: error.response.data.validation.body.message,
          secondsDuration: 2,
        });
      }
    },
    [api, goBack, id, setClientId, setCurrentTab],
  );  

  const onSaveLicense = useCallback(
    async (licenseForm: ILicenseForm) => {
      if (licenseForm.id === undefined) {
        await api.post('/licenses/', licenseForm);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Licença salva com sucesso!',
          secondsDuration: 2,
        });
      } else {
        await api.put(`/licenses/${licenseForm.id}`, licenseForm);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Licença atualizada com sucesso!',
          secondsDuration: 2,
        });
      }
      setCurrentTab('2');
    },
    [api],
  );

  const handleSelectLicense = useCallback((license_id?: string) => {
    setLicenseSelected(license_id || '');
    setCurrentTab('2');
    setShowLicenseForm(true);
  }, []);

  const handleShowLicenseForm = useCallback((showLicenseForm: boolean) => {
    if (!showLicenseForm) {
      setLicenseSelected('');
    }
    setShowLicenseForm(showLicenseForm);
  }, []);

  return (
    <Card className="card-client-form">
      <div className="page-header">
        <h2>{id !== undefined ? 'Atualizando Cliente' : 'Novo Cliente'}</h2>
        <Button
          type="primary"
          shape="round"
          icon={<FaReply />}
          onClick={goBack}
        >
          Voltar
        </Button>
      </div>
      <Tabs
        defaultActiveKey={currentTab}
        activeKey={currentTab}
        onChange={key => setCurrentTab(key)}
      >
        <TabPane
          tab={
            <span>
              <UserOutlined /> Cliente
            </span>
          }
          key="1"
        >
          <ClientForm
            clientId={clientId}
            saveClient={onSaveClient}
            setLicenseDisable={setLicenseDisable}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <IdcardOutlined /> Licença
            </span>
          }
          key="2"
          disabled={licenseDisable}
        >
          {showLicenseForm ? (
            <LicenseForm
              saveLicense={onSaveLicense}
              licenseId={licenseSelected}
              clientId={clientId}
              setShowLicenseForm={handleShowLicenseForm}
            />
          ) : (
            <LicensesClient
              clientId={clientId}
              setLicenseSelected={handleSelectLicense}
            />
          )}
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default ClientsForm;
