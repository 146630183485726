import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useLocation } from 'react-router-dom';

import './styles.scss';
import { FaSave } from 'react-icons/fa';
import { useApi } from '../../hooks/api';
import { notificationDCS } from '../../utils/notificationDcs';

interface GeneralSettingsProps {
  id?: string;
  key: string;
  value?: string;
  label?: string;
  placeholder?: string;
  group?: string;
}

interface GeneralSettingComponentProps {
  group: string;
}

const GeneralSettingComponent: React.FC<GeneralSettingComponentProps> = ({
  group,
}) => {
  const { api } = useApi();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<GeneralSettingsProps[]>([]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();

      await api.put('/general-settings/', data);
      notificationDCS({
        type: 'success',
        message: 'Sucesso',
        description: 'Configuração salva com sucesso!',
        secondsDuration: 2,
      });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [form, api]);

  const loadSettings = useCallback(async () => {
    if(!group) return;
    const response = await api.get(`/general-settings/group/${group}`);
    setSettings(response.data);
    const data = response.data.reduce(
      (obj: GeneralSettingsProps[], item: GeneralSettingsProps) => {
        return { ...obj, [item.key]: item.value };
      },
      {},
    );

    form.setFieldsValue(data);
  }, [form, api, group]);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  return (
    <Form
      layout="vertical"
      form={form}
      className="general-setting-attachments"
      onFinish={onSubmit}
    >
      {settings.map(setting => (
        <Form.Item
          key={setting.key}
          label={setting.label}
          name={setting.key}
          rules={[{ required: true, message: 'Campo Obrigratório!' }]}
        >
          <Input className="dcs-input" placeholder={setting.placeholder} />
        </Form.Item>
      ))}
      <Form.Item>
        <Button
          type="primary"
          shape="round"
          htmlType="submit"
          icon={<FaSave />}
          loading={loading}
        >
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GeneralSettingComponent;
