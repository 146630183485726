import React, { useCallback, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, Row, Space } from 'antd';
import { FaReply, FaSave, FaTimes } from 'react-icons/fa';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import { useApi } from '../../../hooks/api';
import { notificationDCS } from '../../../utils/notificationDcs';
import ILogIntegrationRequest from '../../../interfaces/logIntegration';

interface IParams {
  status: string;
  id: string;
}

const DisabeldFormIntegration: React.FC = () => {
  const { id, status } = useParams<IParams>();
  const { goBack } = useHistory();
  const { api } = useApi();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const data: ILogIntegrationRequest = {
        integration_id: id,
        reason: form.getFieldValue('reason'),
      };

      await api.post('/log-integrations', data);
      setLoading(false);

      notificationDCS({
        type: 'success',
        message: 'Sucesso',
        description: `Integração ${
          status === 'disabled' ? 'desabilitada' : 'habilitada'
        } com sucesso!`,
        secondsDuration: 2,
      });
      goBack();
    } catch {
      setLoading(false);
    }
  }, [api, form, id, status, goBack]);

  return (
    <Card className="card-client-form">
      <div className="page-header">
        <h2>
          {status === 'disabled'
            ? 'Desabilitar Integração'
            : 'Habilitar Integração'}
        </h2>
        <Button
          type="primary"
          shape="round"
          icon={<FaReply />}
          onClick={goBack}
        >
          Voltar
        </Button>
      </div>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Motivo:"
              name="reason"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input.TextArea
                rows={4}
                className="dcs-input"
                placeholder="ex.: Motivo"
              />
            </Form.Item>
          </Col>

          <Divider />
          <Col span={24}>
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={<FaSave />}
                htmlType="submit"
                loading={loading}
              >
                Salvar
              </Button>
              <Button shape="round" icon={<FaTimes />} onClick={goBack}>
                Cancelar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default DisabeldFormIntegration;
