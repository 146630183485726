import React, { useCallback, useState } from 'react';
import { Button, Card, Form, Input, Radio } from 'antd';

import './styles.scss';
import { useForm } from 'antd/lib/form/Form';
import { Link } from 'react-router-dom';
import { Store } from 'antd/lib/form/interface';
import { useAuth } from '../../hooks/auth';
import { IAuth } from '../../interfaces/auth';
import packageJson from '../../../package.json';

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const [form] = useForm();
  const environment = process.env.REACT_APP_NODE_ENV || '';
  let envLabel = '';

  if (environment === 'qa') {
    envLabel = 'QA';
  } else if (environment === 'staging') {
    envLabel = 'HML';
  }

  const [loading, setLoading] = useState(false);

  const onSession = useCallback(
    async (formValues: Store) => {
      setLoading(true);
      try {
        const auth = formValues as IAuth;
        await signIn(auth);

        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [signIn],
  );

  return (
    <div className="login-page">
      <Card>
        <h1>DCS Administrativo</h1>
        <Form
          layout="vertical"
          className="form-login"
          form={form}
          onFinish={onSession}
        >
          <Form.Item
            label="E-mail:"
            name="email"
            rules={[{ required: true, message: 'Preencha seu email!' }]}
          >
            <Input className="dcs-input" type="email" size="large" />
          </Form.Item>

          <Form.Item
            label="Senha:"
            name="password"
            rules={[{ required: true, message: 'Preencha sua senha!' }]}
          >
            <Input.Password className="dcs-input dcs-password" size="large" />
          </Form.Item>
          <Form.Item>
            <Radio className="dcs-radio">Lembrar dados</Radio>
            <Link to="/reset-password">Esqueci minha senha</Link>
          </Form.Item>
          <Form.Item className="login-actions">
            <Button
              type="primary"
              size="large"
              shape="round"
              htmlType="submit"
              loading={loading}
            >
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <div className="sidebar-footer">
        <small>
          {envLabel
            ? `${envLabel}-${packageJson.version}`
            : `V.${packageJson.version}`}
        </small>
      </div>
    </div>
  );
};

export default Login;
