import React, { useCallback, useEffect, useState } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  DoubleRightOutlined,
  EditOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Badge, Button, Card, Dropdown, Menu, MenuProps, Tag } from 'antd';
import { FaEllipsisV, FaFilter, FaSyncAlt } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { TablePaginationConfig, TableProps } from 'antd/lib';
import { HsmStatus } from '../../enums/enums';
import { useApi } from '../../hooks/api';
import { IHsmClients, IHsmList, IHsmWabas } from '../../interfaces/hsm';
import { IActionHeaderConfig } from '../../interfaces/table';
import { notificationDCS } from '../../utils/notificationDcs';
import HsmFilters from '../../components/HsmFilters';
import TableA from '../../components/AntTable';

import './styles.scss';

type EventProcessType = 'in-analysis' | 'rejected' | 'approved' | 'resend';

interface Filters {
  status: string;
  client: string;
  start_date: string;
  end_date: string;
  waba_id: string;
  webhook_notify?: boolean;
}

const Hsm: React.FC = () => {
  const { api } = useApi();
  const history = useHistory();
  const [hsms, setHsms] = useState<IHsmList[]>([]);
  const [hsmClients, setHsmClients] = useState<IHsmClients[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<Filters>({
    status: '',
    client: '',
    start_date: '',
    end_date: '',
    waba_id: '',
    webhook_notify: undefined,
  });
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {} as TablePaginationConfig,
  );

  const loadClients = useCallback(async () => {
    try {
      const response = await api.get('/hsm/clients');
      setHsmClients(response.data);
    } catch (err) {
      notificationDCS({
        type: 'error',
        message: 'Erro',
        description: 'Erro ao buscar clientes',
        secondsDuration: 2,
      });
    }
  }, [api]);

  const loadHsms = useCallback(
    async (
      pageNumber = 1,
      status = filters.status,
      client = filters.client,
      start_date = filters.start_date,
      end_date = filters.end_date,
      waba_id = filters.waba_id,
      webhook_notify = filters.webhook_notify,
    ) => {
      try {
        setLoading(true);
        const response = await api.get('/hsm/paginated', {
          params: {
            page: pageNumber - 1,
            status,
            client,
            start_date,
            end_date,
            waba_id,
            webhook_notify,
          },
        });

        setHsms(response.data.data);
        setTablePagination({
          current: response.data.page + 1,
          total: response.data.totalElements,
          pageSize: response.data.elementsPerPage,
          showSizeChanger: false,
          position: ['bottomCenter'],
          defaultPageSize: 20,
          onChange: page => {
            loadHsms(page);
          },
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [
      api,
      filters.client,
      filters.start_date,
      filters.end_date,
      filters.status,
      filters.waba_id,
      filters.webhook_notify,
    ],
  );

  const changeStatus = useCallback(
    async (id: string, event: EventProcessType) => {
      await api.get(`/hsm-process/${id}/${event}`);

      if (event !== 'resend') {
        notificationDCS({
          type: 'info',
          message: 'Info',
          description: 'Status do HSM atualizado com sucesso!',
          secondsDuration: 2,
        });
      } else {
        notificationDCS({
          type: 'info',
          message: 'Info',
          description: 'Uma nova solicitação para este HSM foi enviada!',
          secondsDuration: 2,
        });
      }
      loadHsms();
    },
    [api, loadHsms],
  );

  const tableActions = [
    {
      title: 'Reenviar',
      icon: <SendOutlined />,
      action: ({ id }: IHsmList) => {
        changeStatus(id, 'resend');
      },
      disabled: (data: IHsmList) => {
        return data.webhook_notify === true || data.status === 'PENDING';
      },
    },
    {
      title: 'Em Análise',
      icon: <DoubleRightOutlined />,
      action: ({ id }: IHsmList) => {
        changeStatus(id, 'in-analysis');
      },
      disabled: (data: IHsmList) => {
        const oldVersion =
          !data.category && !data.meta_id && data.broker !== 'whatsapp';

        const status = data.status as HsmStatus;
        return status !== HsmStatus.PENDING;
      },
    },
    {
      title: 'Rejeitar',
      icon: <CloseOutlined />,
      action: ({ id }: IHsmList) => {
        history.push(`/hsm/${id}/rejected`);
      },
      disabled: (data: IHsmList) => {
        const oldVersion =
          !data.category && !data.meta_id && data.broker !== 'whatsapp';
        const status = data.status as HsmStatus;
        return !oldVersion || status !== HsmStatus.IN_ANALYSIS;
      },
    },
    {
      title: 'Aprovar',
      icon: <CheckOutlined />,
      action: ({ id }: IHsmList) => {
        history.push(`/hsm/${id}/approved`);
      },
      disabled: (data: IHsmList) => {
        const oldVersion =
          !data.category && !data.meta_id && data.broker !== 'whatsapp';
        const status = data.status as HsmStatus;
        return !oldVersion || status !== HsmStatus.IN_ANALYSIS;
      },
    },
  ];

  const tableColumns: TableProps<IHsmList>['columns'] = [
    {
      key: v4(),
      title: 'Nome',
      dataIndex: 'name',
      render: (_, { id, name }: IHsmList) => {
        return <Link to={`/hsm/detail/${id}`}>{name}</Link>;
      },
      align: 'center',
      width: '30%',
      className: 'column-word-wrap',
    },
    {
      key: v4(),
      title: 'Cliente',
      dataIndex: 'client',
      align: 'center',
    },
    {
      key: v4(),
      title: 'Meta-ID',
      dataIndex: 'meta_id',
      align: 'center',
    },
    {
      key: v4(),
      title: 'Waba-ID',
      dataIndex: 'waba',
      align: 'center',
    },
    {
      key: v4(),
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (_, { status }) => {
        switch (status) {
          case HsmStatus.PENDING:
            return (
              <Tag
                color="cyan"
                style={{ width: '100%', textAlign: 'center', margin: 0 }}
              >
                Pendente
              </Tag>
            );
          case HsmStatus.IN_ANALYSIS:
            return (
              <Tag
                color="processing"
                style={{ width: '100%', textAlign: 'center', margin: 0 }}
              >
                Em Análise
              </Tag>
            );
          case HsmStatus.APPROVED:
            return (
              <Tag
                color="success"
                style={{ width: '100%', textAlign: 'center', margin: 0 }}
              >
                Aprovado
              </Tag>
            );
          case HsmStatus.REJECTED:
            return (
              <Tag
                color="error"
                style={{ width: '100%', textAlign: 'center', margin: 0 }}
              >
                Rejeitado
              </Tag>
            );
          case HsmStatus.DISABLED:
            return (
              <Tag
                color="default"
                style={{ width: '100%', textAlign: 'center', margin: 0 }}
              >
                Desabilitado
              </Tag>
            );
          default:
            return (
              <Tag
                color="default"
                style={{ width: '100%', textAlign: 'center', margin: 0 }}
              >
                {status}
              </Tag>
            );
        }
      },
    },
    {
      key: v4(),
      title: 'Qualidade',
      dataIndex: 'quality',
      align: 'center',
      width: '12%',
      render: (_, { quality }) => {
        const qualityLowercase = quality?.toLowerCase();
        switch (qualityLowercase) {
          case 'yellow':
            return <Badge status="warning" text={<span>Média</span>} />;
          case 'red':
            return <Badge status="error" text={<span>Baixa</span>} />;
          case 'green':
            return (
              <Badge
                size="small"
                style={{ display: 'flex' }}
                status="success"
                text={<span>Alta</span>}
              />
            );
          default:
            return <Badge status="success" text={<span>Pendente</span>} />;
        }
      },
    },
    {
      key: v4(),
      title: 'Notficação Webhook',
      dataIndex: 'webhook_notify',
      align: 'center',
      render: (_, { webhook_notify }) => {
        // if (data.status !== 'PENDING') {
        switch (webhook_notify) {
          case true:
            return <Tag color="success">Sim</Tag>;
          default:
            return <Tag color="red">Não</Tag>;
        }
        // }
        // return '-';
      },
    },
    {
      key: v4(),
      title: 'Atualizado em',
      dataIndex: 'date',
      align: 'center',
    },
    {
      key: v4(),
      title: 'Controle',
      dataIndex: 'control',
      render: (_, data) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                {tableActions.map((action: any) => (
                  <Menu.Item
                    key={v4()}
                    icon={action?.icon}
                    onClick={() => {
                      if (!action.disabled || !action.disabled(data)) {
                        action.action(data);
                      }
                    }}
                    disabled={action.disabled ? action.disabled(data) : false}
                  >
                    {action?.title}
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={['click']}
          >
            <Button shape="circle" size="large" type="text">
              <FaEllipsisV />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        loadHsms();
      },
    },
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
  ];

  const onSearch = useCallback(
    (
      status: string,
      client: string,
      start_date: string,
      end_date: string,
      waba_id: string,
      webhook_notify: boolean | undefined,
    ) => {
      setFilters({
        status,
        client,
        start_date,
        end_date,
        waba_id,
        webhook_notify,
      });
      loadHsms(
        1,
        status,
        client,
        start_date,
        end_date,
        waba_id,
        webhook_notify,
      );
      setShowFilters(false);
    },
    [loadHsms],
  );
  useEffect(() => {
    loadHsms();
    loadClients();
  }, [loadHsms]);

  return (
    <Card>
      <TableA
        title="HSMs"
        tableColumns={tableColumns}
        data={hsms}
        loading={loading}
        actionsConfig={headerActions}
        pagination={tablePagination}
      />
      <HsmFilters
        closeFilters={() => setShowFilters(false)}
        showFilters={showFilters}
        search={onSearch}
        clients={hsmClients}
      />
    </Card>
  );
};

export default Hsm;
