import React, { useCallback, useState } from 'react';
import {
  Button,
  Menu,
  Dropdown,
  Pagination,
  Spin,
  Space,
  Typography,
} from 'antd';
import { FaEllipsisV, FaFileContract } from 'react-icons/fa';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import { v4 } from 'uuid';
import TableItem from '../TableItem';

import './styles.scss';
import {
  IActionHeaderConfig,
  ITableColumn,
  TableActions,
  TablePaginationConfig,
} from '../../../interfaces/table';

interface IProps {
  title?: string;
  actionsConfig?: IActionHeaderConfig[];
  tableColumns: ITableColumn[];
  tableActions?: TableActions[];
  isPaginated?: boolean;
  tablePagination?: TablePaginationConfig;
  data: any[];
  keyIndex: any;
  loading?: boolean;
  report?: boolean;
  action?: boolean;
  sort?: string;
  onChangePage?(page: number): Promise<void>;
  handleSorter?: () => void;
}

const { Text } = Typography;

const Table: React.FC<IProps> = props => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const maxContactsToShow = 3;

  const {
    title,
    actionsConfig = [],
    tableColumns = [],
    data,
    keyIndex,
    tableActions,
    isPaginated = true,
    tablePagination,
    loading = false,
    onChangePage,
    report,
    action = true,
    sort,
    handleSorter,
  } = props;

  const renderActions = useCallback(() => {
    return (
      actionsConfig.length > 0 && (
        <Space>
          {actionsConfig.map(actionConfig => (
            <Button
              key={actionConfig.titleAction}
              type="primary"
              shape="round"
              icon={actionConfig.icon}
              onClick={actionConfig.action}
            >
              {actionConfig.titleAction}
            </Button>
          ))}
        </Space>
      )
    );
  }, [actionsConfig]);

  function toggleRowExpansion(rowIndex: number) {
    if (expandedRowIndex === rowIndex) {
      // Se a linha já estiver expandida, feche-a
      setExpandedRowIndex(null);
    } else {
      // Caso contrário, abra a linha clicada
      setExpandedRowIndex(rowIndex);
    }
  }

  const tableMenu = useCallback(
    (object: any) => {
      return (
        <Menu>
          {tableActions !== undefined &&
            tableActions.map(tableAction => (
              <Menu.Item
                key={tableAction.name}
                icon={tableAction.icon}
                disabled={tableAction.disabled(object)}
                onClick={() => tableAction.action(object)}
              >
                {tableAction.name}
              </Menu.Item>
            ))}
        </Menu>
      );
    },
    [tableActions],
  );

  return (
    <div className="table-dcs">
      <header>
        <h2>{title}</h2>
        {renderActions()}
      </header>
      <Spin size="large" spinning={loading}>
        <table>
          <thead>
            <tr>
              {tableColumns.map(column => (
                <th
                  key={`th_${column.name}`}
                  className={`table-column ${
                    column.responsive ? 'responsive' : 'not-responsive'
                  }`}
                >
                  {column.iconSorter ? (
                    <div className="column-iconSort">
                      {column.name}

                      {column.iconSorter &&
                        (sort === 'ascend' ? (
                          <ArrowDownOutlined onClick={handleSorter} />
                        ) : (
                          <ArrowUpOutlined onClick={handleSorter} />
                        ))}
                    </div>
                  ) : (
                    column.name
                  )}
                </th>
              ))}
              {action &&
                (report ? (
                  <th className="table-column responsive">Ação</th>
                ) : (
                  <th className="table-column responsive">Controle</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <Text type="secondary">Nenhum registro encontrado.</Text>
            )}
            {data.map((object, rowIndex) => {
              let contactsToDisplay: any[] = [];

              if (object.contacts.length > 0) {
                if (expandedRowIndex === rowIndex) {
                  contactsToDisplay = object.contacts;
                } else {
                  contactsToDisplay = Array.isArray(object.contacts) ? object.contacts.slice(0, maxContactsToShow) : object.contacts;
                }
              }

              return (
                <>
                <tr 
                  key={`tr_${v4()}`} 
                  style={{ textAlign: 'center', cursor: 'pointer'}} 
                  // onClick={() => toggleRowExpansion(rowIndex)}
                >
                  { tableColumns.map((columnData, index) => {
                    if (columnData.dataIndex === 'contacts') {
                      if(contactsToDisplay.length === 0 ) return <td className="table-column" >-</td>
                      return (
                        <td
                          key={`${object[keyIndex]}_${index}`}
                          className={`table-column ${
                            columnData.responsive ? 'responsive' : 'not-responsive'
                          }`}
                        >
                          {Array.isArray(contactsToDisplay) ? contactsToDisplay.map((contact: string[], contactIndex: number) => (
                            <div key={`contact_${contactIndex}`}>{contact}</div>
                          )):<div key="contact">{contactsToDisplay}</div>}
                          {Array.isArray(contactsToDisplay) && object.contacts.length > maxContactsToShow && (
                            <Button onClick={() => toggleRowExpansion(rowIndex)}>Mostrar Mais</Button>
                          )}
                        </td>
                      );
                    } 
                    return tableColumns[index].renderColumn !== undefined ? (
                        <TableItem
                          key={`${object[keyIndex]}_${index}`}
                          responsive={columnData.responsive}
                          dataRender={tableColumns[index].renderColumn?.(object)}
                        />
                    ) : (
                        <TableItem
                          key={`${object[keyIndex]}_${index}`}
                          responsive={columnData.responsive}
                          dataRender={object[tableColumns[index].dataIndex]}
                        />
                    );
                    
                  })}
                  {action && (
                    <td className="table-column">
                      <Dropdown
                        overlay={() => tableMenu(object)}
                        trigger={['click']}
                      >
                        {report ? (
                          <Button shape="circle" type="primary" size="large">
                            <FaFileContract />
                          </Button>
                        ) : (
                          <Button shape="circle" size="large" type="text">
                            <FaEllipsisV />
                          </Button>
                        )}
                      </Dropdown>
                      {/* {tableActions && tableActions?.map(tableAction => (
                        <Button
                          style={{ borderRadius: '5px'}}
                          key={tableAction.name}
                          icon={tableAction.icon}
                          disabled={tableAction.disabled(object)}
                          onClick={() => tableAction.action(object)}
                         >{tableAction.name}</Button>
                      ))} */}
                    </td>
                  )}
                </tr>
                {/* {expandedRowIndex === rowIndex && (
                  <tr>
                    <td colSpan={tableColumns.length + 1}>
                      {object.contacts.slice(maxContactsToShow).map((contact: string, contactIndex: number) => (
                        <div key={`contact_${contactIndex}`}>{contact}</div>
                      ))}
                    </td>
                  </tr>
                )} */}
                </>
              );
            })}
          </tbody>
        </table>
        {isPaginated && (
          <div className="table-actions">
            <Pagination
              current={tablePagination?.current}
              total={tablePagination?.totalItems}
              defaultPageSize={tablePagination?.defaultPageSize}
              showSizeChanger={false}
              onChange={(pageNumber: number) => {
                if (
                  pageNumber !== undefined &&
                  typeof onChangePage === 'function'
                ) {
                  onChangePage(pageNumber);
                }
              }}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default Table;
