import React from 'react';
import Routes from '../../routes';

import './styles.scss';

const PublicContainer: React.FC = () => {
  return (
    <div className="public-container">
      <Routes />
    </div>
  );
};

export default PublicContainer;
