/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Select,
  Switch,
  Spin,
  Checkbox,
} from 'antd';
import { FaReply, FaSave, FaTimes } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import './styles.scss';
import { useForm } from 'antd/lib/form/Form';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { notificationDCS } from '../../../utils/notificationDcs';
import { useApi } from '../../../hooks/api';
import { useAuth } from '../../../hooks/auth';

const { Option } = Select;

interface IParams {
  id: string;
}

interface IClients {
  id: string;
  name: string;
  enabled: boolean;
}

const UserForm: React.FC = () => {
  const history = useHistory();
  const [form] = useForm();
  const { api } = useApi();
  const { id } = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);
  const [clients, setClients] = useState<IClients[]>([]);
  const [disableButton, setDisableButton] = useState(false);
  const [typeUser, setTypeUser] = useState<boolean>(false);

  const [disabled, setDisabled] = useState(true);
  const [resetPassword, setResetPassword] = useState(true);
  const [password, setPassword] = useState('');
  const [validations, setValidations] = useState({
    minLength: false,
    hasNumber: false,
    hasUppercase: false,
    hasLowercase: false,
    hasSpecialChar: false,
  });

  const { user } = useAuth();

  const handleCheckboxPass = (checked: boolean) => {
    setResetPassword(checked);
  };

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      notificationDCS({
        type: 'warning',
        message: 'Alerta',
        description: 'Por favor, preencha todos os campos obrigatórios!',
        secondsDuration: 2,
      });
    }
  }


  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const loadAllRoles = useCallback(async () => {
    const { data } = await api.get('/profiles');

    const profiles =
      user.user_profile !== 'ROOT'
        ? data.profiles.filter((e: string) => e !== 'ROOT')
        : data.profiles;

    setRoles(profiles);
  }, [api, user.user_profile]);

  const loadUserDetail = useCallback(async () => {
    const { data } = await api.get(`/users/${id}`);
    setClients([{ id: data.client.id, name: data.client.name, enabled: data.client.enabled }])
    form.setFieldsValue(data);
    setTypeUser(data.user_profile === 'USER');
  }, [id, form, api]);

  const loadClients = useCallback(async () => {
    const { data } = await api.get(`/clients/actives`);
    setClients(data);
  }, [api]);

  useEffect(() => {
    setLoadingProfile(true);
    loadAllRoles();
    setLoadingProfile(false);
  }, [loadAllRoles]);
  
  useEffect(() => {
    if (id !== undefined) {
      loadUserDetail();
    }
  }, [id, loadUserDetail]);

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();
      if (id !== undefined) {
        delete data.reset_password;
        await api.put(`/users/${id}`, data);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Usuário atualizado com sucesso!',
          secondsDuration: 2,
          onClose: back,
        });
      } else {
        await api.post('/users', data);
        setLoading(false);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Usuário cadastrado com sucesso!',
          secondsDuration: 2,
          onClose: back,
        });
      }
      setDisableButton(true);
    } catch {
      setLoading(false);
    }
  }, [id, back, form, api, resetPassword]);

  const handleChangeTypeUser = (e: string) => {
    setTypeUser(e === 'USER');
    if(e==='USER' && clients.length === 0){
      loadClients();
    }
  };

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Validações
    const validationsUpdate = {
      minLength: newPassword.length >= 8,
      hasNumber: /\d/.test(newPassword),
      hasUppercase: /[A-Z]/.test(newPassword),
      hasLowercase: /[a-z]/.test(newPassword),
      hasSpecialChar: /[!@#$%^&*]/.test(newPassword),
    };

    setValidations(validationsUpdate);
  };

  const validationMessages = {
    minLength: 'Mínimo de 8 caracteres',
    hasNumber: 'Incluir ao menos um número',
    hasUppercase: 'Incluir ao menos uma letra maiúscula',
    hasLowercase: 'Incluir ao menos uma letra minúscula',
    hasSpecialChar: 'Incluir ao menos um caractere especial',
  };

  const areAllValidationsPassing = () => {
    const isMinLengthValid = password.length >= 8;
    const hasNumberValid = /[0-9]/.test(password);
    const hasUppercaseValid = /[A-Z]/.test(password);

    return isMinLengthValid && hasNumberValid && hasUppercaseValid;
  };

  return loadingProfile ? (
    <div className="dcs-spin">
      <Spin />
    </div>
  ) : (
    <Card className="card-user-form">
      <div className="page-header">
        <h2>{id !== undefined ? 'Atualizando Usuário' : 'Novo Usuário'}</h2>
        <Button type="primary" shape="round" icon={<FaReply />} onClick={back}>
          Voltar
        </Button>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSave}
        initialValues={{
          reset_password: true,
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Nome completo:"
              name="name"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input
                className="dcs-input"
                placeholder="ex.: Nome do usuário"
                maxLength={150}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="E-mail:"
              name="email"
              rules={[
                { type: 'email', message: 'E-mail inválido' },
                { required: true, message: 'Campo obrigatório' },
              ]}
            >
              <Input
                className="dcs-input"
                type="email"
                placeholder="ex.: emaildousuario@teste.com"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Perfil:"
              name="user_profile"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Perfil"
                filterOption
                optionFilterProp="children"
                onChange={handleChangeTypeUser}
                className="dcs-select"
              >
                {roles.map(role => (
                  <Option key={role} value={role}>
                    {role}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {typeUser && (
            <Col span={24}>
              <Form.Item
                label="Cliente:"
                name="client_id"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Selecione o cliente que o usuário faz parte"
                  filterOption
                  optionFilterProp="children"
                  className="dcs-select"
                  disabled={id !== undefined}
                >
                  {clients.map(client => (
                    <Option key={client.id} value={client.id}>
                      {client.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {id === undefined && (
            <>
            <Col span={24}>
              <Form.Item
                label="Senha:"
                name="password"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input.Password
                  className="dcs-input"
                  placeholder="ex.: Senha#Forte"
                  onChange={handlePasswordChange}
                />
              </Form.Item>
              {password.length > 0 &&
                (
                  Object.keys(validations) as Array<keyof typeof validations>
                ).map(key => (
                  <div key={key}>
                    {validations[key] ? (
                      <span style={{ color: 'green', marginLeft: '20px' }}>
                        <CheckCircleOutlined
                          style={{ color: 'green', marginRight: '5px' }}
                        />
                        {validationMessages[key]}
                      </span>
                    ) : (
                      <span style={{ color: 'red', marginLeft: '20px' }}>
                        <CloseCircleOutlined
                          style={{ color: 'red', marginRight: '5px' }}
                        />
                        {validationMessages[key]}
                      </span>
                    )}
                  </div>
                ))}
            </Col>
          <Col span={24} style={{ marginTop: '20px' }}>
          <Form.Item name="reset_password">
            <Checkbox
              className="checkbox-dcs"
              checked={resetPassword}
              onChange={event => handleCheckboxPass(event.target.checked)}
            >
              No próximo acesso o usuário deverá alterar a senha.
            </Checkbox>
          </Form.Item>
        </Col>
      </>)}
          <Col span={24}>
            <Form.Item
              name="smartspace"
              label="SmartSpace"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24}>
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={<FaSave />}
                htmlType="submit"
                loading={loading}
                onClick={validateForm}
                disabled={
                  disableButton || (!disabled && !areAllValidationsPassing())
                }
              >
                Salvar
              </Button>
              <Button shape="round" icon={<FaTimes />} onClick={back}>
                Cancelar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default UserForm;
