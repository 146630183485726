/* eslint-disable react/jsx-curly-newline */
import React, { useState } from 'react';
import { Col, Form, InputNumber, Row } from 'antd';

import CurrencyInputField from '../CurrencyInput';

interface IProps {
  category: string;
}

const CategoryForm: React.FC<IProps> = ({ category }) => {
  const typeFieldId = `type_${category}`;

  // Estado para rastrear o valor do campo
  const [packageValue, setPackageValue] = useState<string>('');

  const handleChangePackageValue = (value: string): void => {
    setPackageValue(value);
  };

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col sm={24} md={24} xl={3}>
            <Form.Item key={typeFieldId} label=" " name={typeFieldId}>
              <span style={{ fontSize: 16 }}>{category}</span>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={7}>
            <Form.Item
              key={`hsm_quantity_${category}`}
              label={category === 'Marketing' || category === 'Serviço' ? "Quantidade de conversas:": <span />}
              name={`available_quantity_${category}`}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <InputNumber
                className="dcs-input"
                placeholder="Ex.: 1000"
                disabled={false}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={7}>
            <Form.Item
              key={`package_value_notification${category}`}
              label={category === 'Marketing' || category === 'Serviço' ? "Valor do pacote:": <span />}
              name={`package_value_notification_${category}`}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <CurrencyInputField
                value={packageValue.toString()}
                onChange={handleChangePackageValue}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={7}>
            <Form.Item
              key={`additional_message_value_notification${category}`}
              label={category === 'Marketing' || category === 'Serviço' ? "Valor por conversa adicional:": <span />}
              name={`additional_message_value_notification_${category}`}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <CurrencyInputField
                value={packageValue.toString()}
                onChange={handleChangePackageValue}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CategoryForm;
