import React from 'react';
import { ApiProvider } from './api';
import { ApplicationProvider } from './application';
import { AuthProvider } from './auth';

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <ApplicationProvider>
      <ApiProvider>
        <AuthProvider>{children}</AuthProvider>
      </ApiProvider>
    </ApplicationProvider>
  );
};

export default AppProvider;
