import React, { useCallback, useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import { FaReply, FaSyncAlt, FaPlus, FaFilter } from 'react-icons/fa';
import { EditOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import Table from '../../components/Table';
import {
  IActionHeaderConfig,
  ITableColumn,
  TableActions,
} from '../../interfaces/table';
import { IPageable, DEFAULT_PAGEABLE } from '../../interfaces/pageable';

import { IWaba } from '../../interfaces/waba';
import { useAuth } from '../../hooks/auth';
import { useApi } from '../../hooks/api';
import WabasFilters from '../../components/WabasFilters';

const ClientDetail: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [wabas, setWabas] = useState<IWaba[]>([]);
  // const [wabasFiltered, setWabasFiltered] = useState<IWaba[]>([]);
  const [loadingWabas, setLoadingWabas] = useState(false);
  const { api } = useApi();
  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);
  const [showFilters, setShowFilters] = useState(false);
  const isNormalUser = user.user_profile === 'USER';
  const loadWabas = useCallback(
    async (
      pageNumber = 1,
      id = '',
      client = '',
      business_id = '',
      business_name = '',
      waba_id = '',
      waba_name = '',
    ) => {
      try {
        setLoadingWabas(true);
        const response = await api.get('/wabas/paginated', {
          params: {
            page: pageNumber - 1,
            id,
            client,
            business_id,
            business_name,
            waba_id,
            waba_name,
          },
        });

        setWabas(response.data.data);
        setPageable(response.data); // response.data ~= IPageable

        setLoadingWabas(false);
      } catch (err) {
        setLoadingWabas(false);
      }
    },
    [api],
  );

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const disableWaba = useCallback(
    async (id: string) => {
      try {
        await api.patch(`/wabas/${id}/enable`);
        loadWabas();
      } catch (err) {
        setLoadingWabas(false);
      }
    },
    [loadWabas, api],
  );

  const onSearch = useCallback(
    (
      id: string,
      client_id: string,
      business_id: string,
      business_name: string,
      waba_id: string,
      waba_name: string,
    ) => {
      loadWabas(
        1,
        id,
        client_id,
        business_id,
        business_name,
        waba_id,
        waba_name,
      );
      setShowFilters(false);
    },
    [loadWabas],
  );

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Cliente',
      dataIndex: 'client',
      responsive: false,
      renderColumn: (data: IWaba) => {
        return data.client.name;
      },
    },
    {
      id: v4(),
      name: 'ID',
      dataIndex: 'id',
      responsive: false,
      renderColumn: (data: IWaba) => {
        return <Link to={`/wabas/detail/${data.id}`}>{data.id}</Link>;
      },
    },
    {
      id: v4(),
      name: 'Business - ID',
      dataIndex: 'business_id',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Business - Nome',
      dataIndex: 'business_name',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Waba - ID',
      dataIndex: 'waba_id',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Waba - Nome',
      dataIndex: 'waba_name',
      responsive: false,
    },
    /*
    {
      id: v4(),
      name: 'Nome',
      dataIndex: 'verified_name',
      responsive: false,
      //renderColumn: (data: IClientsList) => {
      //  return <Link to={`/${isNormalUser ? 'accounts' : 'clients'}/detail/${data.id}`}>{data.name}</Link>;
      //},
    },
    {
      id: v4(),
      name: 'Número',
      dataIndex: 'display_phone_number',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Status',
      dataIndex: 'code_verification_status',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Grau de Qualidade',
      dataIndex: 'quality_rating',
      responsive: false,
    },
    */
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Voltar',
      icon: <FaReply />,
      action: () => {
        back();
      },
    },
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        loadWabas();
      },
    },
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
    {
      titleAction: 'Novo Waba',
      icon: <FaPlus />,
      action: () => {
        history.push('/wabas/create');
      },
    },
  ];

  const tableActions: TableActions[] = [
    {
      name: 'Editar',
      icon: <EditOutlined />,
      action: (data: IWaba) => {
        history.push(`/wabas/create/${data.id}`);
      },
      disabled: () => {
        return false;
      },
    },
    {
      name: 'Ativar/Desativar',
      icon: <PoweroffOutlined />,
      action: (data: IWaba) => {
        disableWaba(data.id);
      },
      disabled: () => {
        return false;
      },
    },
  ];

  useEffect(() => {
    if (user.user_profile === 'USER' && user.client && user.client.name) {
      loadWabas(1, '', user.client.name);
    } else {
      loadWabas();
    }
  }, [loadWabas, user]);

  return (
    <Card className="card-client-detail">
      <Spin spinning={loadingWabas} size="large">
        <Table
          title={
            isNormalUser
              ? 'WhatsApp Business Account - Minhas contas'
              : 'WhatsApp Business Account - Contas de cliente'
          }
          actionsConfig={isNormalUser ? [] : headerActions}
          data={wabas}
          keyIndex="id"
          tableColumns={tableColumns}
          tableActions={isNormalUser ? [] : tableActions}
          loading={loadingWabas}
          tablePagination={{
            current: pageable.page + 1,
            defaultPageSize: pageable.elementsPerPage,
            totalItems: pageable.totalElements,
          }}
          onChangePage={loadWabas}
        />
      </Spin>
      <WabasFilters
        closeFilters={() => setShowFilters(false)}
        showFilters={showFilters}
        search={onSearch}
      />
    </Card>
  );
};

export default ClientDetail;
