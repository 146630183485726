import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Space,
} from 'antd';
import { FaReply, FaSave, FaTimes } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { notificationDCS } from '../../../utils/notificationDcs';
import { useApi } from '../../../hooks/api';

import './styles.scss';

interface IParams {
  id: string;
}

const HsmRejectedForm: React.FC = () => {
  const history = useHistory();
  const { api } = useApi();
  const { id } = useParams<IParams>();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();
      await api.put(`/hsm-process/${id}/rejected`, data);
      notificationDCS({
        type: 'success',
        message: 'Sucesso',
        description: 'HSM rejeitado com sucesso!',
        secondsDuration: 2,
        onClose: back,
      });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [back, form, id, api]);

  return (
    <Card className="card-hsm-approved-form">
      <div className="page-header">
        <h2>Rejeitar HSM</h2>
        <Button type="primary" shape="round" icon={<FaReply />} onClick={back}>
          Voltar
        </Button>
      </div>
      <Form layout="vertical" form={form} onFinish={onSave}>
        <Row>
          <Col xl={6} sm={24} md={24} xs={24}>
            <Form.Item
              label="Data de Resposta do Broker:"
              name="response_date"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <DatePicker className="dcs-input" locale={locale} />
            </Form.Item>
          </Col>
          <Col xl={18} sm={24} md={24} xs={24}>
            <Form.Item
              label="Descrição do Broker:"
              name="broker_description"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input.TextArea
                rows={5}
                className="dcs-input"
                placeholder="ex.: Pedido rejeitado por ..."
              />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24}>
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={<FaSave />}
                htmlType="submit"
                loading={loading}
              >
                Salvar
              </Button>
              <Button shape="round" icon={<FaTimes />} onClick={back}>
                Cancelar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default HsmRejectedForm;
