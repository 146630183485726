import React, { useCallback } from 'react';
import {
  Button,
  Space,
  Table as AntTable,
  TableColumnsType,
  TablePaginationConfig,
} from 'antd';
import { IActionHeaderConfig, TableActions } from '../../interfaces/table';

import './styles.scss';

interface IProps {
  tableColumns: TableColumnsType<any>;
  data: any[];
  title?: string;
  loading?: boolean;
  actionsConfig?: IActionHeaderConfig[];
  tableActions?: TableActions[];
  report?: boolean;
  pagination: TablePaginationConfig;
}

const TableA: React.FC<IProps> = props => {
  const {
    tableColumns = [],
    data,
    title,
    loading,
    actionsConfig,
    tableActions,
    report,
    pagination,
  } = props;

  const renderActions = useCallback(() => {
    return (
      actionsConfig &&
      actionsConfig.length > 0 && (
        <Space>
          {actionsConfig.map(actionConfig => (
            <Button
              key={actionConfig.titleAction}
              type="primary"
              shape="round"
              icon={actionConfig.icon}
              onClick={actionConfig.action}
            >
              {actionConfig.titleAction}
            </Button>
          ))}
        </Space>
      )
    );
  }, [actionsConfig]);

  return (
    <div className="table-dcs">
      {title ? (
        <header>
          <h2>{title}</h2>
          {renderActions()}
        </header>
      ) : undefined}
      <AntTable
        className="dcs-table"
        columns={tableColumns}
        dataSource={data}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

export default TableA;
