/* eslint-disable react/jsx-one-expression-per-line */
import { Card, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaFilter, FaPlus, FaSyncAlt } from 'react-icons/fa';

import { v4 } from 'uuid';
import { useHistory, Link } from 'react-router-dom';
import {
  KeyOutlined,
  EditOutlined,
  PoweroffOutlined,
  EnterOutlined,
} from '@ant-design/icons';
import Table from '../../components/Table';
import {
  IActionHeaderConfig,
  ITableColumn,
  TableActions,
} from '../../interfaces/table';

import IClientsList from '../../interfaces/clients';
import { useAuth } from '../../hooks/auth';
import { notificationDCS } from '../../utils/notificationDcs';
import { useApi } from '../../hooks/api';
import { IPageable, DEFAULT_PAGEABLE } from '../../interfaces/pageable';
import { ClientsFilters } from '../../components/ClientsFilters';

interface IPartners {
  id: string;
  name: string;
}

const Clients: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { api } = useApi();
  const [clients, setClients] = useState<IClientsList[]>([]);
  const [partners, setPartners] = useState<IPartners[]>([]);

  const [loading, setLoading] = useState(false);
  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    status: '',
    phone: '',
    client_type: '',
    partner_id: '',
    token: '',
  });

  const isNormalUser = user.user_profile === 'USER';

  const loadClients = useCallback(
    async (
      pageNumber = 1,
      name = filters.name,
      email = filters.email,
      status = filters.status,
      phone = filters.phone,
      client_type = filters.client_type,
      partner_id = filters.partner_id,
      token = filters.token,
    ) => {
      try {
        setLoading(true);
        const response = await api.get('/clients/paginated', {
          params: {
            page: pageNumber - 1,
            name,
            email,
            enabled: status ? status === 'ENABLED' : undefined,
            phone,
            client_type,
            partner_id,
            token,
          },
        });

        setClients(response.data.data);
        setPageable(response.data);

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [
      api,
      filters.client_type,
      filters.email,
      filters.name,
      filters.partner_id,
      filters.phone,
      filters.status,
      filters.token,
    ],
  );

  const generateToken = useCallback(
    async (id: string) => {
      try {
        await api.patch(`/clients/${id}/generate-token`);
        notificationDCS({
          type: 'primary',
          message: 'Aviso',
          description: 'Token gerado!',
          secondsDuration: 2,
        });
        loadClients();
      } catch (err) {
        setLoading(false);
      }
    },
    [loadClients, api],
  );

  const onSearch = useCallback(
    (
      name: string,
      email: string,
      status: string,
      phone: string,
      client_type: string,
      partner_id: string,
      token: string,
    ) => {
      setFilters({
        name,
        email,
        status,
        phone,
        client_type,
        partner_id,
        token,
      });
      loadClients(
        1,
        name,
        email,
        status,
        phone,
        client_type,
        partner_id,
        token,
      );
      setShowFilters(false);
    },
    [loadClients],
  );

  const disabledClient = useCallback(
    async (id: string, status: boolean) => {
      if(status) {
        if (!window.confirm('Ao desativar o cliente, estará desativando também as integrações associadas. Tem certeza que deseja prosseguir?')) {
          return;
        }
      }
      
      try {
        await api.patch(`/clients/${id}/enable`);
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Cliente ativado/desativado com sucesso!',
          secondsDuration: 2,
        });
        loadClients();

      } catch (err) {
        setLoading(false);
      }
    },
    [loadClients, api],
  );

  const getPartners = useCallback(async () => {
    try {
      const response = await api.get('clients/partners');
      setPartners(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [api]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  const getNamePartner = (client: string | undefined) => {
    if (client) {
      const clientPartner = partners.find(item => item.id === client);
      return clientPartner?.name;
    }

    return '';
  };

  const tagColorTypeClient = (value: string) => {
    switch (value) {
      case 'DIRECT':
        return 'processing';
      case 'INDIRECT':
        return 'pink';
      case 'PARTNER':
        return 'orange';
      default:
        return '';
    }
  };

  const writeTypeClient = (value: string) => {
    switch (value) {
      case 'DIRECT':
        return 'DIRETO';
      case 'INDIRECT':
        return 'INDIRETO';
      case 'PARTNER':
        return 'PARCEIRO';
      default:
        return '';
    }
  };

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'Nome',
      dataIndex: 'name',
      responsive: true,
      renderColumn: (data: IClientsList) => {
        return (
          <Link
            style={{ display: 'inline-block', alignItems: 'center' }}
            to={`/${isNormalUser ? 'accounts' : 'clients'}/detail/${data.id}`}
          >
            {data.name}
            <span
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                marginLeft: '4px',
              }}
            >
              {data.partner_id && (
                <EnterOutlined
                  style={{ transform: 'rotateY(180deg)', margin: ' 0 2px 0 0' }}
                />
              )}
              {getNamePartner(data.partner_id)}
            </span>
          </Link>
        );
      },
    },
    {
      id: v4(),
      name: 'E-mail',
      dataIndex: 'email',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Status',
      dataIndex: 'enabled',
      responsive: false,
      renderColumn: (data: IClientsList) => {
        return (
          <Tag color={data.enabled ? 'success' : 'error'}>
            {data.enabled ? 'ATIVO' : 'INATIVO'}
          </Tag>
        );
      },
    },
    {
      id: v4(),
      name: 'Tipo de Cliente',
      dataIndex: 'client_type',
      responsive: false,
      renderColumn: (data: IClientsList) => {
        return (
          <Tag color={tagColorTypeClient(data.client_type)}>
            {writeTypeClient(data.client_type)}
          </Tag>
        );
      },
    },
    {
      id: v4(),
      name: 'Telefone',
      dataIndex: 'phone',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Token',
      dataIndex: 'token',
      responsive: false,
      renderColumn: (data: IClientsList) => {
        if (data.token) {
          return <Tag color="purple">{data.token}</Tag>;
        }
        return '';
      },
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        loadClients();
      },
    },
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
    {
      titleAction: 'Novo Cliente',
      icon: <FaPlus />,
      action: () => {
        history.push('/clients/create');
      },
    },
  ];

  const tableActions: TableActions[] = [
    {
      name: 'Gerar Token',
      icon: <KeyOutlined />,
      action: (data: IClientsList) => {
        generateToken(data.id);
      },
      disabled: () => {
        return false;
      },
    },
    {
      name: 'Editar',
      icon: <EditOutlined />,
      action: (data: IClientsList) => {
        history.push(`/clients/create/${data.id}`);
      },
      disabled: () => {
        return false;
      },
    },
    {
      name: 'Ativar/Desativar',
      icon: <PoweroffOutlined />,
      action: (data: IClientsList) => {
        disabledClient(data.id, data.enabled);
      },
      disabled: () => {
        return false;
      },
    },
  ];

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  return (
    <>
      <Card>
        <Table
          title={isNormalUser ? 'Contas' : 'Clientes'}
          actionsConfig={isNormalUser ? [] : headerActions}
          data={clients}
          keyIndex="id"
          tableColumns={tableColumns}
          tableActions={isNormalUser ? [] : tableActions}
          loading={loading}
          tablePagination={{
            current: pageable.page + 1,
            defaultPageSize: pageable.elementsPerPage,
            totalItems: pageable.totalElements,
          }}
          onChangePage={loadClients}
        />
      </Card>
      <ClientsFilters
        closeFilters={() => setShowFilters(false)}
        showFilters={showFilters}
        search={onSearch}
      />
    </>
  );
};

export default Clients;
