import React from 'react';
import CurrencyInput from 'react-currency-input-field';

interface CurrencyInputProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

const CurrencyInputField: React.FC<CurrencyInputProps> = ({
  value = '',
  onChange,
  placeholder = 'R$ 0,00',
  disabled = false,
}) => {
  return (
    <CurrencyInput
      prefix="R$ "
      value={value}
      onValueChange={(value = '') => {
        if (typeof value === 'string') {
          onChange(value);
        }
      }}
      // allowDecimals
      decimalsLimit={5}
      placeholder={placeholder}
      disabled={disabled}
      className="dcs-input"
      style={{ paddingLeft: '10px' }}
    />
  );
};

export default CurrencyInputField;
