import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, Row } from 'antd';

import './styles.scss';
import { useLocation } from 'react-router-dom';
import GeneralSettingsMenu from '../../components/GeneralSettingsMenu';
import GeneralSettingsBody from '../../components/GeneralSettingsBody';
import GeneralSettingAttachments from '../../components/GeneralSettingAttachments';
import GeneralSettingComponent from '../../components/GeneralSettingComponent';

const GeneralSettings: React.FC = () => {
  const [settingGroup, setSettingGroup] = useState<string>('general');
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.substring(1) || 'general';
    setSettingGroup(hash);
  }, [location]);

  const settingGroupArr: { [key: string]: string } = {
    email: 'E-mail',
    attachments: 'Anexos',
    general: 'Gerais',
    sms: 'SMS',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    facebook: 'Facebook',
    reports: 'Relatórios',
    integrations: 'Integrações',
    hsm: 'HSM',
  };

  return (
    <div className="general-settings-page">
      <Card className="card-general-settings">
        <h2>Configurações do Sistema</h2>
        <Divider />
        <Row>
          <Col span={6}>
            <GeneralSettingsMenu />
          </Col>
          <Col span={18}>
            <GeneralSettingsBody
              title={`Configurações ${settingGroup === 'general' ? '' : 'de'} ${settingGroupArr[settingGroup] || 'Sistema'}`}
            >
              <GeneralSettingComponent group={settingGroup} />
            </GeneralSettingsBody>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default GeneralSettings;
