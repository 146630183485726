import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useState } from 'react';
import { Button, Col, Divider, Form, Row, Select, Space } from 'antd';
import { FaSave, FaTimes } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import { IWabaPhone, IWabaTemplate } from '../../interfaces/waba';
import { notificationDCS } from '../../utils/notificationDcs';
import { useApi } from '../../hooks/api';

interface IProps {
  phones: IWabaPhone[];
  templates: IWabaTemplate[];
}

interface IParams {
  id: string;
}

const WabaDetailTestMessage: React.FC<IProps> = props => {
  const { phones, templates } = props;
  const { api } = useApi();
  const [form] = useForm();
  const { id } = useParams<IParams>();
  const [loading, setLoading] = useState(false);

  const phone8Mask = '+55 (99) 9999-9999';
  const phone9Mask = '+55 (99) 99999-9999';
  const [mask, setMask] = useState(phone8Mask);

  const changeMask = (e: { target: { value: string } }) => {
    if (/^\+([0-9]+) \(([0-9]+)\) 9/.test(e.target.value)) {
      if (mask !== phone9Mask) {
        setMask(phone9Mask);
      }
    } else if (mask !== phone8Mask) {
      setMask(phone8Mask);
    }
  };

  const cancelar = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();
      const result = await api.post(`/wabas/${id}/validateTemplate`, data);
      if (result.status === 200) {
        notificationDCS({
          type: 'success',
          message: 'Sucesso',
          description: 'Mensagem de teste enviada!',
          secondsDuration: 2,
        });
      } else {
        notificationDCS({
          type: 'warning',
          message: 'Falha',
          description: JSON.stringify(result.data),
          secondsDuration: 2,
        });
      }
    } catch (err) {
      notificationDCS({
        type: 'error',
        message: 'Falha',
        description: JSON.stringify(err),
        secondsDuration: 2,
      });
    } finally {
      setLoading(false);
    }
  }, [form, api, id]);
  return (
    <Form layout="vertical" form={form} onFinish={onSave}>
      <Row>
        <Col span={8}>
          <Form.Item
            label="Telefone origem:"
            name="phone"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select
              placeholder="Escolha um telefone dentre os autorizados"
              className="dcs-select"
              options={phones.map(phone => {
                return {
                  value: phone.id,
                  label: `${phone.display_phone_number} | ${phone.verified_name}`,
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Template:"
            name="template"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select
              placeholder="Escolha o template"
              className="dcs-select"
              options={templates.map(template => {
                return {
                  value: template.id,
                  label: `${template.category} | ${template.name} | ${template.language}`,
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Telefone destino:"
            name="target_phone"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <InputMask
              mask={mask}
              maskChar="_"
              onChange={changeMask}
              className="ant-input dcs-input"
              placeholder="ex.: +55 83 98704-9999"
            />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Space>
            <Button
              type="primary"
              shape="round"
              icon={<FaSave />}
              htmlType="submit"
              loading={loading}
            >
              Enviar
            </Button>
            <Button shape="round" icon={<FaTimes />} onClick={cancelar}>
              Cancelar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default WabaDetailTestMessage;
