import { Card, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FaFilter, FaSyncAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import Table from '../../../components/Table';
import { useApi } from '../../../hooks/api';
import { ILogInteractionResponse } from '../../../interfaces/logInteractionResponse';
import { IPageable, DEFAULT_PAGEABLE } from '../../../interfaces/pageable';
import { IActionHeaderConfig, ITableColumn } from '../../../interfaces/table';
import LogIntegrationResponseFilters from '../../../components/LogIntegrationResponseFilters';

const LogInteractionResponse: React.FC = () => {
  const { api } = useApi();
  const [loading, setLoading] = useState(false);
  const [interactionsResponse, setInteractionsResponse] = useState<
    ILogInteractionResponse[]
  >([]);
  const [pageable, setPageable] = useState<IPageable>(DEFAULT_PAGEABLE);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    id: '',
    status: '',
    response_received_at: '',
    callback_status: '',
  });

  const loadLogs = useCallback(
    async (
      pageNumber = 1,
      id = filters.id,
      status = filters.status,
      response_received_at = filters.response_received_at,
      callback_status = filters.callback_status,
    ) => {
      setLoading(true);
      try {
        const response = await api.get('/logs/interaction-response/paginated', {
          params: {
            page: pageNumber - 1,
            id,
            status,
            response_received_at,
            callback_status,
          },
        });

        setInteractionsResponse(response.data.data);
        setPageable(response.data); // response.data ~= IPageable

        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [
      api,
      filters.id,
      filters.status,
      filters.response_received_at,
      filters.callback_status
    ],
  );

  const tableColumns: ITableColumn[] = [
    {
      id: v4(),
      name: 'ID da Requisição',
      dataIndex: 'request_id',
      responsive: true,
    },
    {
      id: v4(),
      name: 'Status',
      dataIndex: 'status',
      responsive: true,
      renderColumn: ({ status }: ILogInteractionResponse) => {
        switch (status) {
          case 'WAITING_WEBHOOK':
            return <Tag color="processing">Aguardando Webhook</Tag>;
          case 'WAITING_STATUS':
            return <Tag color="purple">Aguardando Status</Tag>;
          case 'FINISHED':
            return <Tag color="green">Finalizado</Tag>;
          case 'UNRECOGNIZED':
            return <Tag color="error">Não Reconhecido</Tag>;
          default:
            return <Tag color="yellow">{status}</Tag>;
        }
      },
    },
    {
      id: v4(),
      name: 'Resposta recebida em',
      dataIndex: 'response_received_at',
      responsive: false,
    },
    {
      id: v4(),
      name: 'Status do Callback',
      dataIndex: 'callback_status',
      responsive: false,
      renderColumn: ({ callback_status }: ILogInteractionResponse) => {
        switch (callback_status) {
          case 'QUEUED':
            return <Tag color="yellow">Na Fila</Tag>;
          case 'FAILED':
            return <Tag color="error">Falha</Tag>;
          case 'SENT':
            return <Tag color="green">Enviada</Tag>;
          case 'DELIVERED':
            return <Tag color="error">Entregue</Tag>;
          case 'ANSWERED':
            return <Tag color="blue">Respondida</Tag>;
          case 'UNRECOGNIZED':
            return <Tag color="error">Não Reconhecido</Tag>;
          default:
            return <Tag color="yellow">{callback_status}</Tag>;
        }
      },
    },
  ];

  const headerActions: IActionHeaderConfig[] = [
    {
      titleAction: 'Filtros',
      icon: <FaFilter />,
      action: () => {
        setShowFilters(true);
      },
    },
    {
      titleAction: 'Atualizar',
      icon: <FaSyncAlt />,
      action: () => {
        loadLogs();
      },
    },
  ];

  const onSearch = useCallback(
    (
      id: string,
      status: string,
      response_received_at: string,
      callback_status: string,
    ) => {
      setFilters({
        id, status, response_received_at, callback_status
      })
      loadLogs(1, id, status, response_received_at, callback_status);
      setShowFilters(false);
    },
    [loadLogs],
  );

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  return (
    <Card>
      <Table
        title="Log de Interações de Respostas"
        actionsConfig={headerActions}
        data={interactionsResponse}
        keyIndex="id"
        tableColumns={tableColumns}
        tableActions={
          [
            // {
            //   name: 'Conteudo',
            //   icon: <AlignLeftOutlined />,
            //   action: (data: ILogInteractionResponse) => {
            //     showContentModal(data.content);
            //   },
            //   disabled: () => {
            //     return false;
            //   },
            // },
          ]
        }
        tablePagination={{
          current: pageable.page + 1,
          defaultPageSize: pageable.elementsPerPage,
          totalItems: pageable.totalElements,
        }}
        onChangePage={loadLogs}
        loading={loading}
      />
      <LogIntegrationResponseFilters
        closeFilters={() => setShowFilters(false)}
        showFilters={showFilters}
        search={onSearch}
      />
    </Card>
  );
};

export default LogInteractionResponse;
