import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.less';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import App from './App';
import AppProvider from './hooks';

const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED === 'true' || false;
const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'production';

Sentry.init({
  enabled: SENTRY_ENABLED,
  dsn: 'https://d88cd47b4186f7762737af300e09b6d2@o4506875251326976.ingest.us.sentry.io/4507143056195584',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: SENTRY_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'dcs-qa.digivox.dev', 'dcs-hml.digivox.dev', 'dcs.digivox.dev', 'dcs.digivox.dev'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const domNode = document.getElementById('root');

if (domNode) {
  const root = createRoot(domNode);

  root.render(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#283593',
        },
      }}
    >
      <AppProvider>
        <App />
      </AppProvider>
    </ConfigProvider>,
  );
} else {
  console.error("Elemento com ID 'root' não encontrado no DOM.");
}
