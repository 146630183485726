/* eslint-disable no-restricted-globals */
import React, { memo } from 'react';

interface IProps {
  responsive: boolean;
  dataRender: string | React.ReactNode | undefined | React.JSX.Element;
}

const TableItem: React.FC<IProps> = props => {
  const { dataRender, responsive = false } = props;

  return (
    <td
      className={`table-column ${responsive ? 'responsive' : 'not-responsive'}`}
    >
      {dataRender === undefined ? '' : dataRender}
    </td>
  );
};

export default memo(TableItem);
