/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */

import { Button, Col, Drawer, Form, Input, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useState } from 'react';

import { format } from 'date-fns';
import IClientsList from '../../interfaces/clients';

interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  clients: IClientsList[];
  limitlessClients?: number;
  search(
    client_id: string,
    channel: string,
    due_date: string,
    limitlessStatus: boolean,
  ): void;
}

const { Option } = Select;

const channels = [
  {
    label: 'Whatsapp',
    value: 'whatsapp',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'Facebook Messenger',
    value: 'fbmessenger',
  },
  {
    label: 'Facebook Comments',
    value: 'fbcomments',
  },
];

const ClientsConsumptionReportFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search, clients, limitlessClients } =
    props;
  const [filters, setFilters] = useState({
    client_id: '',
    integration_id: '',
    billing_date: '',
  });
  const [limitlessStatus, setLimitlessStatus] = useState(false);

  const [form] = useForm();

  const onSearch = useCallback(() => {
    const { client_id, channel, billing_date } = form.getFieldsValue();

    const billingDateFormatted = billing_date
      ? format(billing_date, 'dd/MM/yyyy')
      : '';

    search(
      client_id === undefined ? '' : client_id,
      channel === undefined ? '' : channel,
      billingDateFormatted,
      limitlessStatus,
    );
  }, [form, search, limitlessStatus]);

  const handleSetLimitless = (checked: boolean) => {
    setLimitlessStatus(checked);
  };

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      visible={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Col span={24}>
          <Form.Item label="Cliente" name="client_id">
            <Select
              allowClear
              placeholder="Todos"
              filterOption
              showSearch
              optionFilterProp="children"
              className="dcs-select"
              onChange={e => setFilters({ ...filters, client_id: String(e) })}
            >
              {clients.map(client => (
                <Option key={client.id} value={client.id}>
                  {client.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Canal:" name="channel">
            <Select
              allowClear
              placeholder="Todos"
              filterOption
              showSearch
              // onClear={() => loadBillings()}
              // disabled={disable}
              optionFilterProp="children"
              className="dcs-select"
              onChange={e =>
                setFilters({ ...filters, integration_id: String(e) })
              }
            >
              {channels.map(ch => (
                <Option key={ch.value} value={ch.value}>
                  {ch.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Data de Faturamento" name="billing_date">
            <Input
              className="dcs-input"
              allowClear
              type="date"
              placeholder="ex.: Data de Faturamento"
              onChange={e =>
                setFilters({ ...filters, billing_date: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item style={{ display: 'flex' }}>
            <Switch onChange={handleSetLimitless} />
            <span style={{ fontWeight: 'bold', marginLeft: 3, color: 'red' }}>
              {limitlessClients}
            </span>{' '}
            clientes sem limite
          </Form.Item>
        </Col>
        <Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ClientsConsumptionReportFilters;
