import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import InputMask from 'react-input-mask';

import './styles.scss';
import { useForm } from 'antd/lib/form/Form';
import { useApi } from '../../hooks/api';
import { notificationDCS } from '../../utils/notificationDcs';
import applyPhoneMask from '../../utils/formatPhoneMask';

interface IParams {
  id: string;
}
interface IPartners {
  id: string;
  name: string;
}
const { Option } = Select;

const uuidMask = '********-****-****-****-************';

interface IProps {
  clientId?: string;
  saveClient(data: any, clientId?: string): Promise<void>;
  setLicenseDisable(isIndirect: boolean): void;
}

const ClientForm: React.FC<IProps> = props => {
  const { saveClient, setLicenseDisable, clientId } = props;
  const history = useHistory();
  const { api } = useApi();
  const { id } = useParams<IParams>();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [showSelectedPartners, setShowSelectedPartners] =
    useState<boolean>(false);
  const [selectedPartners, setSelectedPartners] = useState<string>('');
  const [selectedTypeClient, setSelectedTypeClient] = useState<string>('');
  const [partners, setPartners] = useState<IPartners[]>([]);
  const [formClientId, setFormClientId] = useState<string>('');
  const [disableButton, setDisableButton] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [mascara, setMascara] = useState('(99) 99999-9999');

  const back = useCallback(() => {
    history.push('/clients');
  }, [history]);

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      notificationDCS({
        type: 'warning',
        message: 'Alerta',
        description: 'Por favor, preencha todos os campos obrigatórios!',
        secondsDuration: 2,
      });
    }
  };

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      const data = form.getFieldsValue();
      const dataToSend = { ...data };
      if (dataToSend.phone) {
        dataToSend.phone = applyPhoneMask(dataToSend.phone);
      }
      if (selectedTypeClient === 'DIRECT' && !dataToSend.partner_id) {
        delete dataToSend.partner_id;
      }

      await saveClient(dataToSend, formClientId);
      setDisableButton(true);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [back, form, id, api, selectedTypeClient, formClientId]);

  const dataPartners = useCallback(async () => {
    const response = await api.get('clients/partners');
    setPartners(response.data);
  }, [api]);

  useEffect(() => {
    dataPartners();
  }, [dataPartners]);

  const loadClientDetail = useCallback(
    async (clientId?: string) => {
      const response = await api.get(`/clients/${id || clientId}`);

      if (clientId) {
        setFormClientId(clientId);
      }

      if (response.data.phone) {
        if (response.data.phone.replace(/\D/g, '').length > 10) {
          setMascara('(99) 99999-9999');
        } else {
          setMascara('(99) 9999-99999');
        }
      }

      form.setFieldsValue(response.data);

      setLicenseDisable(response.data.client_type === 'INDIRECT');

      if (response.data.client_type !== 'PARTNER') {
        setShowSelectedPartners(true);
      }
    },
    [id, form, api, setLicenseDisable, clientId],
  );

  const clientSelected = useCallback(
    (value: string) => {
      setSelectedTypeClient(value);
      setShowSelectedPartners(value === 'DIRECT' || value === 'INDIRECT');
      form.setFieldsValue({
        [`partner_id`]: '',
      });
    },
    [form],
  );

  const partnerSelected = useCallback((value: string) => {
    setSelectedPartners(value);
  }, []);

  const handleTelefoneChange = useCallback(
    (e: any) => {
      setTelefone(e.target.value);
      const numericValue = e.target.value.replace(/\D/g, '');

      if (numericValue.length > 10) {
        setMascara('(99) 99999-9999');
      } else {
        setMascara('(99) 9999-99999');
      }
      setTelefone(numericValue);
    },
    [setTelefone, setMascara],
  );

  useEffect(() => {
    if (id || clientId) loadClientDetail(clientId);
  }, [id, loadClientDetail, clientId]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSave}
      onValuesChange={() => {
        setDisableButton(false);
      }}
    >
      <Row>
        <Col span={showSelectedPartners ? 12 : 24}>
          <Form.Item
            label="Tipo de cliente:"
            name="client_type"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select
              placeholder="Tipo de cliente"
              filterOption
              allowClear
              optionFilterProp="children"
              className="dcs-select"
              value={selectedTypeClient}
              onChange={(e: any) => clientSelected(e)}
              disabled={id !== undefined}
            >
              <Option key="direct" value="DIRECT">
                Direto
              </Option>
              <Option key="partner" value="PARTNER">
                Parceiro
              </Option>
              <Option key="indirect" value="INDIRECT">
                Indireto
              </Option>
            </Select>
          </Form.Item>
        </Col>
        {showSelectedPartners && (
          <Col span={showSelectedPartners ? 12 : 0}>
            <Form.Item
              label="Parceiro:"
              name="partner_id"
              rules={[
                {
                  required: selectedTypeClient === 'INDIRECT',
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Select
                placeholder="Parceiro"
                value={selectedPartners}
                allowClear
                showSearch
                filterOption
                optionFilterProp="children"
                onChange={partnerSelected}
                disabled={id !== undefined}
              >
                {partners.map(item => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={24}>
          <Form.Item label="CNPJ:" name="cnpj">
            <InputMask
              mask="99.999.999/9999-99"
              className="ant-input dcs-input"
              placeholder="ex.: 99.999.999/9999-99"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Nome completo:"
            name="name"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input className="dcs-input" placeholder="ex.: Nome do cliente" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="E-mail:"
            name="email"
            rules={[
              { required: true, message: 'Campo obrigatório' },
              { type: 'email', message: 'E-mail inválido' },
            ]}
          >
            <Input
              className="dcs-input"
              type="email"
              placeholder="ex.: emaildocliente@teste.com"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Telefone:"
            name="phone"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            {/* <InputMask
              mask="(99) 99999-9999"
              className="ant-input dcs-input"
              placeholder="ex.: (99) 99999-9999"
              maskChar={null}
            /> */}
            <InputMask
              mask={mascara}
              className="ant-input dcs-input"
              value={telefone}
              maskChar={null}
              onChange={handleTelefoneChange}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Dia de Renovação da Licença:"
            name="renewal_day"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select placeholder="Dia de Renovação" className="dcs-select">
              <Option value={30}>Mensal</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <span>
                Domain UUID:&nbsp;
                <Tooltip title="Identificador único de domínio.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="domain_uuid"
          >
            <InputMask
              mask={uuidMask}
              className="dcs-input"
              placeholder="ex.: 1b0b2836-dc04-4bfd-8502-911df2ca0dc5"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          {/* <Form.Item name="smartspace" label="SmartSpace">
      <Switch />
    </Form.Item> */}
          <Divider orientation="left">Configuração de URL de Callback</Divider>
          <Form.Item
            label={
              <span>
                URL de Callback de Mensagem:&nbsp;
                <Tooltip title="Está é a url de callback padrão para recebimento das mensagens do DCS.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="url_callback"
          >
            <Input
              className="dcs-input"
              placeholder="ex.: www.urlcallback.com/callback"
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                URL de Status de Entrega de Mensagem WhatsApp:&nbsp;
                <Tooltip title="Esta é a URL de callback para o recebimento do status de entrega das mensagens enviadas para os contatos do cliente.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="url_status_whatsapp"
          >
            <Input
              className="dcs-input"
              placeholder="ex.: www.urlcallback.com/url_status_whatsapp"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={
              <span>
                URL de Callback de Confirmação do Status HSM:&nbsp;
                <Tooltip title="Está é a url de callback para o DCS enviar os status das mensagens HSM criadas para este cliente.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="url_callback_hsm_status"
          >
            <Input
              className="dcs-input"
              placeholder="ex.: www.urlcallback.com/callback-hsm-status"
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                URL Callback Confirmação de Cadastro:&nbsp;
                <Tooltip title="Está é a url de callback para confirmação de conta do Facebook Messenger.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="citruscx_callback_created"
          >
            <Input
              className="dcs-input"
              placeholder="ex.: www.urlcallback.com/callback-confirm-account"
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                URL de Callback de Atualização da Qualidade do HSM:&nbsp;
                <Tooltip title="Está é a url de callback para o DCS enviar a qualidade das mensagens HSM criadas para este cliente.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="url_callback_hsm_quality"
          >
            <Input
              className="dcs-input"
              placeholder="ex.: www.urlcallback.com/callback-hsm-quality"
            />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Space>
            <Button
              type="primary"
              shape="round"
              icon={<FaSave />}
              htmlType="submit"
              loading={loading}
              disabled={disableButton}
              onClick={validateForm}
            >
              {id || formClientId ? 'Salvar' : 'Salvar e continuar'}
            </Button>
            <Button shape="round" icon={<FaTimes />} onClick={back}>
              Cancelar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientForm;
