import { Card, Collapse, Steps } from 'antd';
import Tag from 'antd/es/tag';
import React, { useCallback } from 'react';
import IHsmHistory from '../../interfaces/hsm';

const { Step } = Steps;
const { Panel } = Collapse;

interface IProps {
  hsmHistory: IHsmHistory[];
  formatDate(date: Date | undefined): void;
}

const HsmHistoryDetail: React.FC<IProps> = ({ hsmHistory, formatDate }) => {
  const renderHistoryDescription = useCallback(
    (history: IHsmHistory) => {
      return (
        <div>
          <p>{`Ação: ${history.action}`}</p>
          <p>{`Usuário: ${history.user}`}</p>
          {history.action !== 'HSM_CREATED' && (
            <p>
              Notificação Webhook Cliente:
              {history.webhook_notify === true ? (
                <Tag color="success">Notificado</Tag>
              ) : (
                <>
                  <Tag color="error">Não Notificado</Tag>
                  <Collapse className="collapse-dcs" defaultActiveKey={[]}>
                    <Panel
                      className="collapse-panel-dcs"
                      header="Detalhes: "
                      key="1"
                    >
                      <p>{history.err}</p>
                    </Panel>
                  </Collapse>
                </>
              )}
            </p>
          )}
          <p>{`Data: ${formatDate(history.history_date)}`}</p>
        </div>
      );
    },
    [formatDate],
  );

  return (
    <Card className="card-hsm-history">
      <div className="page-header">
        <h2>Histórico</h2>
      </div>
      <Steps progressDot direction="vertical">
        {hsmHistory.map(history => (
          <Step
            key={history.id}
            title={history.status}
            description={renderHistoryDescription(history)}
          />
        ))}
      </Steps>
    </Card>
  );
};

export default HsmHistoryDetail;
